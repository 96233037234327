import React from "react";
import "../../../assets/TGG_V1_OLD/css/newTGG.scss"

const ComplaintsPolicy = () => {
  return (
    <>
    <div className="doc_sections">
      <section className="doc_sections">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 py-5 offset-lg-1">
              <div className="doc-head">
                <h2 className="text-center animate__animated animate__fadeInUp animate__delay-.5s">
                  Complaints Policy
                </h2>
                <h1 className="text-center doc-head mb-lg-5 mb-md-5 mb-sm-4 mb-4 animate__animated animate__fadeInUp animate__delay-.5s">
                  Complaints Policy
                </h1>
              </div>
              <div className="elementor-widget-container">
                <p>
                  We are committed to providing high quality legal advice and
                  client care.
                </p>
                <p>
                  However, if at any point you become unhappy or concerned about
                  the service provided, please inform us immediately so that we
                  can do our best to resolve the problem.
                </p>
                <p>
                  In the first instance it may be helpful to contact the person
                  who is handling your matter. If you still have queries or
                  concerns, please contact Darren Smith who is the Client Care
                  Partner to whom any final issues can be reported.
                </p>
                <h4>Formal complaints procedure</h4>
                <p>
                  The following outlines our recommended procedure for making a
                  formal complaint. Please note that making a complaint will not
                  affect how we handle your case.
                </p>
                <ul>
                  <li>
                    A formal complaint should be addressed to our head of
                    practice who can be contacted in writing at Courmacs Legal
                    Ltd. Alexander House, Beehive Trading Park, Haslingden Rd,
                    Blackburn, BB1 2EE or by{" "}
                    <a href="mailto:ds@courmacslegal.co.uk">
                      email FAO Darren Smith
                    </a>
                    . Please set out the details of the complaint.{" "}
                  </li>
                </ul>
                <ul>
                  <li>
                    We will acknowledge your complaint within seven working days
                    of receipt.
                  </li>
                </ul>
                <ul>
                  <li>
                    Our head of practice will show your complaint to the person
                    responsible for your matter and ask them to conduct a
                    complete review of the file and to respond in writing. They
                    will also interview any members of staff involved in your
                    matter.
                  </li>
                </ul>
                <ul>
                  <li>
                    You will receive a written response to your complaint within
                    14 days from the date the complaint is first received. This
                    response will set out the result of our investigation, any
                    proposed resolution and, if relevant, any procedural changes
                    we will make to ensure the situation does not arise again.
                  </li>
                </ul>
                <ul>
                  <li>
                    In the event that you are not satisfied with the outcome of
                    the complaint we will refer you to the appropriate authority
                    should you wish to take the matter further.
                  </li>
                </ul>
                <h4>Taking complaints further</h4>
                <p>
                  We are regulated by the Solicitors Regulation Authority and
                  complaints and redress mechanisms are provided through them
                  and the Legal Ombudsman.
                </p>
                <p>
                  If we are unable to resolve any such concerns to your
                  satisfaction you are entitled to make a complaint to the&nbsp;{" "}
                  <a
                    href="http://www.legalombudsman.org.uk/"
                    target="_blank"
                    rel="noopener"
                  >
                    Legal Ombudsman
                  </a>
                  &nbsp;P.O. Box 6806 Wolverhampton WV1 9WJ.{" "}
                </p>
                <p>
                  The Legal Ombudsman investigates complaints about service
                  issues with lawyers. Normally, you will need to bring a
                  complaint to the Legal Ombudsman within six months of
                  receiving a final written response from us about your
                  complaint.
                </p>
                <p>
                  Complaints about a client’s rights under the General Data
                  Protection Regulation must be submitted to the&nbsp;{" "}
                  <a
                    href="http://www.ico.org.uk/"
                    target="_blank"
                    rel="noopener"
                  >
                    Information Commissioner’s Office
                  </a>
                  .{" "}
                </p>
                <p>
                  Any disputes or legal issue arising from our Client Care and
                  Terms of Business will be determined by the law of England
                  &amp; Wales and considered exclusively by the English &amp;
                  Welsh courts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default ComplaintsPolicy;

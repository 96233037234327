import React from "react";
import { Salutation } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
const PersonalDetails = ({
    slideChange,
    className,
    backClick,
    setLoader1,
    validation,
    validationMsg,
    clearErrors,
    setError,
    trigger, }) => {

    const basicDetailsValidation = async (e) => {
        let errorFlag = 0;
        var lstSalutationResult = await trigger("lstSalutation");
        var txtFNameResult;
        var txtLNameResult;
        if (lstSalutationResult) {
            txtFNameResult = await trigger("txtFName");
        } else {
            document.getElementById("lstSalutation").focus();
            return false;
        }
        if (txtFNameResult) {
            txtLNameResult = await trigger("txtLName");
        } else {
            document.getElementById("txtFName").focus();
            return false;
        }
        if (!lstSalutationResult || !txtFNameResult || !txtLNameResult) {
            errorFlag = 1;
        }
        if (errorFlag === 0) {
            window.scrollTo(0, 0);
            GtmDataLayer({
                question: "personal details",
              });
            slideChange(e);

        } else {
            return false;
        }
    };

    const namevalidate = async (e) => {
        if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
            var name = (e.target.name == 'txtFName') ? 'First Name' : 'Last Name';
            setError(e.target.name, {
                type: "manual",
                message: "Your " + name + " is Invalid. Please Recheck",
            });
            if (e.target.value.trim().length == 0) {
                e.target.value = e.target.value.trim();
            }
        }
        return false;
    };

    return (

        <>
            <div className={`modal ${className}`} id="persDetails"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content animate__animated animate__zoomIn animate__delay-.5s ">

                        <div className="modal-body">
                            <div className="text-center mb-3">
                                {/* <img height="40" src="/assets/TGG_V2/img/logo.png" alt="" /> */}
                                <LogoBasedOnDomain height={40}/>

                            </div>

                            <div className="form_drop col-lg-12 col-12">
                                <p className="sty_pop">To better assist you and personalize your experience, may we know your name?</p>
                                <label htmlFor="format2">Title</label>
                                <div className="select_title animate__animated animate__fadeInUp animate__delay-.5s">
                                    <SelectBox
                                        className="form-select form control"
                                        OptionValue={Salutation}
                                        name="lstSalutation"
                                        id="lstSalutation"
                                        autocomplete="off"
                                        onChange={() => clearErrors("lstSalutation")}
                                        clearErrors={clearErrors}
                                        myRef={validation({ required: "Please Select Title" })}
                                    // validationMsg={
                                    //     validationMsg.lstSalutation &&
                                    //     validationMsg.lstSalutation.message
                                    // }
                                    >
                                    </SelectBox>
                                </div>
                                {validationMsg.lstSalutation && (
                                    <span className="error_msg " id="email_err">
                                        {validationMsg.lstSalutation.message}
                                    </span>
                                )}
                                <div className="row name_detail animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="col-lg-6 col-md-6 col-12 f_name">
                                        <label htmlFor="topmention1">First Name</label>

                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            name="txtFName"
                                            id="txtFName"
                                            autocomplete="off"
                                            onBlur={namevalidate}
                                            validation={validation({
                                                required: "Please Enter First Name",
                                                minLength: {
                                                    value: 3,
                                                    message: "Please Enter Valid First Name",
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z\s]+$/,
                                                    message: "Your Name is Invalid. Please Recheck",
                                                },
                                            })}
                                        ></TextField>
                                        {validationMsg.txtFName && (
                                            <span className="error_msg " id="email_err">
                                                {validationMsg.txtFName.message}
                                            </span>
                                        )}

                                    </div>
                                    {/* <div className="col-lg-4 col-md-4 col-12 m_name">
                                        <label htmlFor="topmention3">Middle Name <span>(optional)</span></label>

                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="Middle name"
                                            name="txtMName"
                                            id="txtMName"
                                            autocomplete="off"
                                        ></TextField>
                                    </div> */}
                                    <div className="col-lg-6 col-md-6 col-12 l_name">
                                        <label htmlFor="topmention2">Last Name</label>
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            name="txtLName"
                                            id="txtLName"
                                            autocomplete="off"
                                            onBlur={namevalidate}
                                            validation={validation({
                                                required: "Please Enter Last Name",
                                                minLength: {
                                                    value: 3,
                                                    message: "Please Enter Valid Last Name",
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z\s]+$/,
                                                    message: "Your Name is Invalid. Please Recheck",
                                                },
                                            })}
                                        ></TextField>
                                        {validationMsg.txtLName && (
                                            <span className="error_msg " id="email_err">
                                                {validationMsg.txtLName.message}
                                            </span>
                                        )}
                                    </div>
                                    <p className="tiny_note">
                                        <img src="/assets/TGG_V2/img/info.png" className="pe-1" alt="" />What you are legally known as
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                            <div className="bg-1">
                                <input type="button" className="button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                                    id="nextPlate_004"
                                    value="Next"
                                    name="personalDetails"
                                    onClick={basicDetailsValidation}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};

export default PersonalDetails;
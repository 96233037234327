import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import { useSignature } from "../../../../Hooks/useSignature";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { queryString } from "../../../../Utility/QueryString";
import InputButton from "../../../UI/InputButton";
import { useFormContext } from "react-hook-form";
import CheckBox2 from "./Checkbox2";


const Sign = ({ formSubmit, pageChange, basicDetailsValidation, openPrevPopup, setSignatureDataURL, titleQuest, setAnalyseModel }) => {
  const initialState = {
    isChecked: false,
    isAgreed: false,
    isCanvasEmpty: false,
    isValid: false,
  };
  const signatureReducer = (state, action) => {
    switch (action.type) {
      case "signPadChange": {
        return {
          ...state,
          isCanvasEmpty: action.payload.value,
        };
      }
      case "privacyAgreed": {
        return {
          ...state,
          isAgreed: action.payload.value,
        };
      }
      case "validSignature": {
        return {
          ...state,
          isValid: action.payload.value,
        };
      }
      default:
        return state;
    }
  };
  const { trigger, errors } = useFormContext();
  // const { isCheck } = CheckUUID();
  // isCheck();
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const [isDisable, setIsDisable] = useState(false)
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);



  const handleClick = async (e) => {
    const validcheck = await basicDetailsValidation();
    if (validcheck) {
      try {
        e.preventDefault();
        const signPad = signPadRef.current;
        if (signPad.isEmpty()) {
          return dispatch({ type: "signPadChange", payload: { value: true } });
        }
        if (!isChecked) {
          return dispatch({ type: "privacyAgreed", payload: { value: true } });
        }
        const rpdata = signPad.toData();
        var signLength = 0;
        for (var i = 0; i < rpdata.length; i++) {
          signLength += Object.keys(rpdata[i]).length;
        }
        if (signLength > 13) {

          //openPrevPopup();

          const canvasDataURL = signPadRef.current.getCanvas().toDataURL("image/png");
          setSignatureDataURL(canvasDataURL);
          setAnalyseModel('show');
          // formSubmit();
        } else {
          return dispatch({ type: "validSignature", payload: { value: true } });
        }
      } catch (e) {
        console.warn(e);
      }


    }
  };

  useEffect(() => {
    if (!isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <div>
      <div className="sign-sec" id="sign-div">
        <h5 className=" mb-3 mt-4">
          Please sign your signature in the box below to confirm you understand the above
        </h5>
        <div className="signbox" ref={signatureWrapRef}>
          <SignatureCanvas
            canvasProps={{
              id: "myID",
              width: signatureWrapWidth,
              height: 200,
            }}
            ref={signPadRef}
            onEnd={() => {
              if (!signPadRef.current.isEmpty()) {
                dispatch({
                  type: "signPadChange",
                  payload: { value: false },
                });
                dispatch({
                  type: "validSignature",
                  payload: { value: false },
                });
                // const canvasDataURL = signPadRef.current.getCanvas().toDataURL("image/png");
                // setSignatureDataURL(canvasDataURL);
              }

            }}
          />
        </div>
        {state.isCanvasEmpty && (
          <div
            className="sign_msg col-12 text-center sign_msg mb-3 error_msg"
            id="signatures_required"
          >
            Signature is required!
          </div>
        )}
        {state.isValid && (
          <div
            className="sign_msg col-12 text-center sign_msg error_msg"
            id="signatures_valid"
          >
            Draw valid signature!
          </div>
        )}
        <input
          type="button"
          id=""
          name=""
          className="clearbutton mt-2"
          value="Clear"
          onClick={clearSign}
        />
        <div className="sign-blw-text mt-3">
          <p className="fw-click-here fw-bold mb-1">View Courmacs Legal <a target="_blank" href="/terms_and_conditions">Terms & Conditions</a></p>
          <p className="mb-1">By clicking <strong>Submit Claim</strong> you agree to Courmacs Legal Privacy policy & re-engagement to complete your application</p>
          <p className="mb-1">I agree that I have read, understand and accept the Courmacs Legal Limited terms of business and that the check is free. I also agree to instruct Courmacs Legal Limited to send a data subject access request (DSAR) to find out if my PCP or HP agreements were mis-sold</p>
          <p className=" ">By clicking <strong>Submit Claim</strong> you agree that we will be conducting a soft credit search to verify your claim. These searches won't impact your credit score but will support your claim (powered by Valid8 ip ltd). By signing this document, you agree to let us do this. For each claim, we will create a new damages-based agreement for you to review</p>
        </div>
      </div>
      <div>
        <button
          className="blue-btn p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 fs-5 fw-bold" id="formSubmitBtn" name="formSubmitBtn" type="button" onClick={handleClick} disabled={isDisable}><span>Submit Claim</span> </button>
      </div>
    </div>
  );
};

export default Sign;

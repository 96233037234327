import React, { useEffect, useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import FormTGG_V1 from "../../Forms/FormTGG_V1.js";
import Testimonial from "../../Includes/Layouts/TGG_V1/Testimonial.js";
import Footer from "../../Includes/Layouts/TGG_V1/Footer.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import "../../../assets/TGG_V1/scss/TGG_V1.scss";
import AccordSection from "../../Includes/Layouts/TGG_V1/AccordSection.js";
import Loader from "../../Includes/Layouts/LenderTGGV1/Loader.js";

const TGG_V1 = () => {
  const [divShow, setDivShow] = useState("");
  const [analyseModel, setAnalyseModel] = useState("hide");
  const [showCookie, setShowCookie] = useState("hide");

  const cookieHide = () =>{
    setShowCookie("hide")
  }

  useEffect(() => {
    setTimeout(() => {
      setShowCookie("show")
    }, 3000);
  }, [])
  
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="TGG_V1" />
      <FbViewPixel />
      <div className="TGG_V1">
        <FormTGG_V1
          setDivShow={setDivShow}
          divShow={divShow}
          setAnalyseModel={setAnalyseModel}
        />
        <Loader LoaderLender={analyseModel} />
        <div className={`${divShow == "is-open" ? "d-none d-md-block" : ""}`}>
          <Testimonial />
          <AccordSection />
          <Footer />
          <div
            className={`sticky-footer p-lg-4 p-md-4 p-sm-2 p-2 ${showCookie}`}
          >
            <div className="d-flex">
              <div>
                <p className="text-start mb-0">
                  By continuing to use this site, I agree to the{" "}
                  <a href="/cookie-policy" className="link" target="_blank">
                    cookie policy.
                  </a>{" "}
                  Necessary cookies are used to ensure the site functions
                  properly. When applying with us or our clients, interacting
                  with this page indicates acceptance of our cookie policy. If
                  you do not wish to use cookies, please exit the page.
                </p>
              </div>
              <div>
                <div>
                  <button
                    type="button"
                    className=" cookieclose border-0 rounded-1"
                    onClick={() => cookieHide("hide")}
                  >
                    <i className="bi bi-x-lg"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TGG_V1;

import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Header = () => (
  <header id="header" className="sticky-header">
    <div className="container">
      <a href="">
        <LogoBasedOnDomain />
      </a>
    </div>
  </header>
);

export default Header;

import React, {
    useRef,
    useState,
    useContext,
    useEffect,
    useReducer
} from "react"; 
import "../../assets/TGG_V2/CSS/TGG_V2.scss"
import "../../assets/TGG_V2/CSS/signature.scss"
import { useHistory } from "react-router-dom";
import Header from '../Includes/Layouts/TGG_V2/Header'
import Footer from '../Includes/Layouts/TGG_V2/Footer'
import SignatureCanvas from "react-signature-canvas";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import { UseLeadApi } from "../../Hooks/UseLeadApi";
import { useSignature } from "../../Hooks/useSignature";
import RedirectModule from "../../Utility/RedirectModule";
import { useRedirectProbability } from "../../Hooks/useRedirectProbability.js";
import GtmDataLayer from "../../Utility/GtmDataLayer";
const initialState = {
    isCanvasEmpty: false,
    isValid: false,
};

const signatureReducer = (state, action) => {
    switch (action.type) {
        case "signPadChange": {
            return {
                ...state,
                isCanvasEmpty: action.payload.value,
            };
        }
        case "validSignature": {
            return {
                ...state,
                isValid: action.payload.value,
            };
        }
        default:
            return state;
    }
};

const SignatureTGGV2 = () => {
    const [state, dispatch] = useReducer(signatureReducer, initialState);
    const { saveSignature, isLoading } = useSignature();
    const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
    const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const signatureWrapRef = useRef(null);
    const message_type = "signature_store";
    const { visitorParameters } = useContext(VisitorParamsContext);
    const signPadRef = useRef();
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    const history = useHistory();
    const domainName = window.location.hostname;
    const { LeadApiIn } = UseLeadApi();
    const [submitLoader,SetSubmitLoader]=useState("hide");
    const { probablityPage } = useRedirectProbability();

    const handleClick = async (e) => {
        try {
            const signPad = signPadRef.current;
            if (signPad.isEmpty()) {
                return dispatch({ type: "signPadChange", payload: { value: true } });
            }
            const rpdata = signPad.toData();
            var signLength = 0;
            for (var i = 0; i < rpdata.length; i++) {
                signLength += Object.keys(rpdata[i]).length;
            }
            if (signLength > 13) {
                localStorage.setItem("userSign",signPad.getCanvas().toDataURL("image/png"),);
                SetSubmitLoader("showform");
                setDisabled(true);
                const responsesign = await saveSignature(
                    signPad.getCanvas().toDataURL("image/png"),
                    visitorParameters.visitor_parameters,
                    formParameters,
                    visitorParameters.data,
                    queryString,
                    message_type
                );

                GtmDataLayer({
                    question: "signature",
                  });

                if (responsesign.data.status === "Success") {
                    const probabilityCheck = await probablityPage(visitorParameters.visitor_parameters.uuid);  
                    if(probabilityCheck.data.status === "Success"){
                    let pageAProbability = probabilityCheck.data.percentage1;
                    const redirect = RedirectModule({
                        splitName: "TGGV2_sign",
                        uuid: visitorParameters.visitor_parameters.uuid,
                        pageAProbability: pageAProbability,
                      });

                        const formdata = JSON.parse(localStorage.getItem("formData"));
                        if (formdata === undefined || formdata === null || formdata == "") {
                            localStorage.setItem("formData", JSON.stringify(formdata));
                        } else {
                            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
                            localStorage.setItem("formData", JSON.stringify(formdata));
                        }
                        let splitName = localStorage.getItem("split_name")
                            ? localStorage.getItem("split_name")
                            : null;
                        if (splitName === null) {
                            const urlParams = new URLSearchParams(window.location.search);
                            splitName = urlParams.get("split_name");
                        }
                        history.push(redirect);
                    }
                }
            } else {
                return dispatch({ type: "validSignature", payload: { value: true } });
            }
        } catch (e) {
            console.warn(e);
        }
    };
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
        function handleResize() {
            setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
            setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
        }
        setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
        setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const clearSign = () => {
        if (signPadRef.current) signPadRef.current.clear();
    };
    return (
        <>
            <GetVisitorsParams />
            <div className='TGG_V2'>
                <div className="__PCPtcg">
                    <div className="bg_lol">
                        <Header />
                        <section className="cs_hero cs_style_1 sec_design section_design1">
                            <div className="cs_hero_wrap cs_bg_filed">
                                <div className="container-md">
                                    <form id="form">
                                        <div className="home_plate_002 signature-section animate__animated animate__fadeInLeft ">
                                            <div className="row ">
                                                <div className="col-lg-8 offset-lg-2 ">
                                                    <div className="text-center">
                                                        <h1 className="fw-bold ">Your signature</h1>
                                                        <p>Keep it fully contained within the box below</p>
                                                    </div>
                                                    <div className="sign-sec p-3">
                                                        <div className="signbox" id="signBox">
                                                            <div
                                                                className="signature-wrap"
                                                                ref={signatureWrapRef}
                                                            >
                                                                {/* <canvas className> </canvas> */}
                                                                <SignatureCanvas
                                                                    canvasProps={{
                                                                        id: "myID",
                                                                        width: signatureWrapWidth,
                                                                        height: signatureWrapHeight,
                                                                    }}
                                                                    ref={signPadRef}
                                                                    onEnd={() => {
                                                                        if (!signPadRef.current.isEmpty()) {
                                                                            dispatch({
                                                                                type: "signPadChange",
                                                                                payload: { value: false },
                                                                            });
                                                                            dispatch({
                                                                                type: "validSignature",
                                                                                payload: { value: false },
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {(state.isCanvasEmpty && state.isValid) && (
                                                            <div
                                                                className="text-center error_msg mb-3"
                                                                id="signatures_required"
                                                            >
                                                                Signature is Required!
                                                            </div>
                                                        )}
                                                        {(state.isCanvasEmpty && !(state.isValid)) && (
                                                            <div
                                                                className="text-center error_msg mb-3"
                                                                id="signatures_required"
                                                            >
                                                                Signature is Required!
                                                            </div>
                                                        )}
                                                        {(!(state.isCanvasEmpty) && state.isValid) && (
                                                            <div
                                                                className="text-center error_msg mb-3"
                                                                id="signatures_valid"
                                                            >
                                                                Draw Valid Signature!
                                                            </div>
                                                        )}
                                                        <button
                                                            type="button"
                                                            id=""
                                                            name=""
                                                            className="clearbutton is_sticky btop"
                                                            onClick={clearSign}
                                                        >Clear</button>
                                                        <div className="text-start signature-footer p-2">
                                                        <p className="fw-click-here fw-bold mb-1">View Courmacs Legal <a target="_blank" href="/terms_and_conditions">Terms & Conditions</a></p>
                                                            <p className="mb-2">By clicking <strong>Submit Claim</strong> you agree to Courmacs Legal Privacy policy & re-engagement to complete your application
                                                            </p>
                                                            <p>I agree that I have read, understand and accept the Courmacs Legal Limited terms of business and that the check is free. I also agree to instruct Courmacs Legal Limited to send a data subject access request (DSAR) to find out if my PCP or HP agreements were mis-sold</p>
                                                            <p>By clicking Submit Claim you agree that we will be conducting a soft credit search to verify your claim. These searches won't impact your credit score but will support your claim (powered by Valid8 ip ltd). By signing this document, you agree to let us do this. For each claim, we will create a new damages-based agreement for you to review

</p>
                                                        </div>
                                                    </div>
                                                    <div className=" is_sticky box_button mb-5 sign_Btn_paGe pt-4 animate__animated animate__fadeInUp animate__delay-1s animated-element wht_iFNot" id="subRegNo1">
                                                        <div className="bg-1 hme_btn text-center">
                                                            {/* <input type="button" className="button button--moema button--text-thick button--text-upper button--size-s blick_notice" id="nextSign" defaultValue="Submit To Claim" aria-label="submitid" name="subRegNo1" /> */}
                                                            <button
                                                                className="button button--moema button--text-thick button--text-upper button--size-s blick_notice"
                                                                type="button"
                                                                disabled={disabled}
                                                                onClick={handleClick}
                                                            >
                                                                Submit Claim
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
            <div className={`loader-div loaderresult loader-fix ${submitLoader}`}>
                    <div className="text-center">
                        <img height="110" src="/assets/TGG_V2/img/loader03.svg" alt="" />
                    </div>
            </div>
        </>
    )
}

export default SignatureTGGV2
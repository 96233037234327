import React, { useState } from "react";
import RadioButton2 from "../../../UI/RadioButton2";
import confetti from "canvas-confetti";
import Modal from "react-bootstrap/Modal";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Commision = ({
  className,
  setLoader2,
  slideChange,
  backClick,
  validation,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const optionSelected = async (e) => {
    window.scrollTo(0, 0);
    if (e.target.value === "3") {
      setModalShow(true);
    } else if (e.target.value === "4") {
      setLoader2("show");
      const timer = setTimeout(() => {
        setLoader2("hide");
        slideChange(e);
        confetti({
          particleCount: 200,
          spread: 80,
          origin: { y: 0.2 },
        });
      }, 1000);
    }
  };
  const closeModal = () => {
    setModalShow(false);
  };
  return (
    <>
      <div className={`step ${className}`} id="step_3">
        <div className="form-right bounce-y top-up d-block d-md-none">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-5 text-weight-600 d-flex text-start">
                <img
                  src="/assets/TGG_V1/img/information-button.png"
                  className="w-auto mt-1"
                />
                &nbsp; Additional information required to <br /> determine your
                potential refund amount.
              </p>
            </div>
          </div>
        </div>
        <div className="form-left">
          <div className="field padded">
            <label className="h5 margin-5">
              Did the lender(s) tell you about the commission they received?
            </label>
            <p className="text-size-smaller margin-15">
              Make a selection below
            </p>
            <ul className="options two">
              <li>
                <RadioButton2
                  value="3"
                  name="commission"
                  id="lender_commission_received_1"
                  className="custom-checkbox"
                  labelName="Yes"
                  onClick={optionSelected}
                  validation={validation()}
                />
              </li>
              <li>
                <RadioButton2
                  value="4"
                  name="commission"
                  id="lender_commission_received_2"
                  className="custom-checkbox"
                  labelName="No"
                  labelClassName={`animation-green-flash-infinite`}
                  onClick={optionSelected}
                  validation={validation()}
                />
              </li>
            </ul>
          </div>
          <div className="form-navigation">
            <ul className="justify-content-end">
              <li>
                <button
                  type="button"
                  name="lenders"
                  className="button back"
                  onClick={backClick}
                  id="BackInto2"
                >
                  <img
                    src="/assets/TGG_V1/img/arrow-left.png"
                    name="lenders"
                    className="px-2 "
                  />
                  Back
                </button>
              </li>
            </ul>
          </div>
          <div className="form-right bounce-y top-up d-none d-md-block">
            <div className="badge">
              <div className="content">
                <p className="h6 margin-5 text-weight-600 d-flex text-start">
                  <img
                    src="/assets/TGG_V1/img/information-button.png"
                    className="w-auto mt-1"
                  />
                  &nbsp; Additional information required to <br /> determine
                  your potential refund amount.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-chk_answr"
        show={modalShow}
        onHide={closeModal}
       
      >
        <div className="modal-chk_answr1">
          <div>
            <LogoBasedOnDomain style={{ width: 152, textAlign: "center" }} />
          </div>
          <h2 className="mt-4">Please check your answers</h2>
          <p>
            Unfortunately, this means we are unable to pursue a claim against
            your lenders.
          </p>
          <p>If you would like to change your answer, please click back.</p>
          <button onClick={closeModal}>Back</button>
        </div>
      </Modal>
    </>
  );
};

export default Commision;

import React, { useContext, useEffect, useRef, useState } from 'react'
import "../../assets/TGG_V2/CarRegistrationV2/CSS/car_registration.scss";
import "../../assets/TGG_V2/CarRegistrationV2/CSS/car_reg_custom.scss";
import { UseSaveCarDetails } from '../../Hooks/UseSaveCarDetails';
import { useHistory } from "react-router-dom";
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useSkippedLeads } from '../../Hooks/useSkippedLeads';
import { useCarRegisterValidation } from '../../Hooks/useCarRegisterValidation';
import Header from '../Includes/Layouts/TGG_V2/Header';
import Footer from '../Includes/Layouts/TGG_V2/Footer';
import Modal from "react-bootstrap/Modal";
import tick from "../../assets/TGG_V1_OLD/img/tick.png";
import GtmDataLayer from '../../Utility/GtmDataLayer';

const CarRegistrationTGG_V2 = () => {

    const [VeichleModal, SetVeichleAddModal] = useState(false);
    const [textInputValues, setTextInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});
    const [lenderData, setLenderData] = useState([]);
    const inputRefs = useRef({});
    const [buttonClickedStatus, setButtonClickedStatus] = useState({});
    const [ErrorMessageBtn, setErrorMsgBtn] = useState("");
    const { skippedLeads } = useSkippedLeads();
    const history = useHistory();
    const { registrationvalidation } = useCarRegisterValidation();
    const { SaveCarDetails } = UseSaveCarDetails();
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const query_string = localStorage.getItem("querystring");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const split_name = localStorage.getItem("split_name");
    const [LoaderLender, SetLenderLodar] = useState("hide");
    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    }, []);
    // const [modal, setModal] = useState("hide");

    // useEffect(() => {
    //     if (modal === 'show') {
    //         document.body.classList.add('modal-open');
    //     } else {
    //         document.body.classList.remove('modal-open');
    //     }
    // }, [modal]);



    const getUuid = () => {
        const visitorData = visitorParameters.visitor_parameters;
        if (visitorData != "") {
            var uuid = visitorData.uuid;
        } else {
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem("uuid");
            var uuid = query_uuid ? query_uuid : local_storage_uuid;
        }
        return uuid;
    };
    const uuid = getUuid();


    useEffect(() => {
        const storedLenders = JSON.parse(localStorage.getItem('Lenders')) || [];
        const initialTextValues = {};
        const initialValidationMessages = {};
        const initialButtonVisibility = {};
        const initialLenderData = [];

        console.log("storedLenders", storedLenders);

        storedLenders.forEach(({ id, lenderName, lender_value }) => {
            initialTextValues[lenderName] = '';
            initialValidationMessages[lenderName] = '';
            initialButtonVisibility[lenderName] = true;
            initialLenderData.push({ id, lenderName, lender_value });
            inputRefs.current[lenderName] = React.createRef();

        });
        setTextInputValues(initialTextValues);
        setValidationMessages(initialValidationMessages);
        setButtonClickedStatus(initialButtonVisibility);
        setLenderData(initialLenderData);
    }, []);

    const handleTextInputChange = (event) => {
        const { name, value } = event.target;
        setTextInputValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
        // console.log("textInputValues (stringified): ", JSON.stringify(textInputValues, null, 2));
    };

    const validateField = (name, value) => {
        const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/;
        if (value.trim() === '') {
            return 'Please Enter Vehicle Registration';
        } else if (value.trim().length < 3) {
            return 'Please Enter Valid Vehicle Registration';
        } else if (!pattern.test(value)) {
            return 'Your Vehicle Registration is Invalid. Please Recheck';
        }

        setValidationMessages(prevMessages => ({
            ...prevMessages,
            [name]: false
        }));
        return;
    };

    const handleAddButtonClick = async (name) => {

        console.log("inside handleAddButtonClick :" + textInputValues[name]);
        const validationMessage = validateField(name, textInputValues[name]);
        if (validationMessage) {
            setValidationMessages(prevMessages => ({
                ...prevMessages,
                [name]: validationMessage
            }));
        } else {
            try {
                console.log(`Adding ${textInputValues[name]} for ${name}`);

                const getRegisterValidation = await registrationvalidation(textInputValues[name], uuid, split_name);

                console.log("getRegisterValidation.data.validity", getRegisterValidation.data.validity);

                if (getRegisterValidation.data.validity == "1") {
                    SetVeichleAddModal(true);
                    // let vehicle = getRegisterValidation.data.vehicleData;
                    // vehicle.reg_no = textInputValues[name];

                    setButtonClickedStatus(prevVisibility => ({
                        ...prevVisibility,
                        [name]: false
                    }));

                    const response = await SaveCarDetails(
                        visitorParameters.visitor_parameters,
                        textInputValues[name],
                        name,
                        'save_car_details',
                        visitorParameters.data,
                        query_string,
                        formdata
                    );

                    setTimeout(() => {
                        SetVeichleAddModal(false);
                    }, 2000);
                    // console.log("textInputValues (stringified): ", JSON.stringify(textInputValues, null, 2));


                    console.log(`API call successful for ${name}`);
                } else {
                    setValidationMessages(prevMessages => ({
                        ...prevMessages,
                        [name]: 'Vehicle registration is invalid. Please recheck.'
                    }));
                    SetVeichleAddModal(false);
                }
            } catch (error) {
                console.warn('Error occurred:', error);
            }
        }
    };

    const validateAllFields = () => {
        const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/;
        const newValidationMessages = {};
        let firstInvalidField = null;

        Object.keys(textInputValues).forEach(name => {
            const value = textInputValues[name];
            if (value.trim() === '') {
                newValidationMessages[name] = 'Please Enter Vehicle Registration';
                if (!firstInvalidField) firstInvalidField = name;
            } else if (!pattern.test(value)) {
                newValidationMessages[name] = 'Your Vehicle Registration is Invalid. Please Recheck';
                if (!firstInvalidField) firstInvalidField = name;
            } else if (value.length < 3) {
                newValidationMessages[name] = 'Your Vehicle Registration is Invalid. Please Recheck';
                if (!firstInvalidField) firstInvalidField = name;
            } else {
                newValidationMessages[name] = '';
            }
        });

        setValidationMessages(newValidationMessages);

        const allValid = Object.values(newValidationMessages).every(message => message === '');
        const allFieldsValidated = Object.keys(textInputValues).every(name => {
            return buttonClickedStatus[name] === false; // Check if all buttons have been clicked
        });
        if (firstInvalidField) {
            inputRefs.current[firstInvalidField].current.focus();
        } else {
            if (allFieldsValidated) {
                GtmDataLayer({
                    question: "car reg. number",
                });
                setErrorMsgBtn("");
                SetLenderLodar("showLoader")
                const collectedData = lenderData.map(lender => ({
                    lender_id: lender.id,
                    lender_name: lender.lender_name,
                    lender_value: lender.lender_value,
                    registration: textInputValues[lender.lender_name]
                }));
                console.log("collectedData", collectedData);

                const timer = setTimeout(() => {
                    window.scrollTo(0, 0);
                    history.push("/pcp/thankyou?uuid=" + uuid);
                }, 2000);

            } else {
                setErrorMsgBtn("Please Verify the Registration Number By Clicking Add Button");
            }
        }
    };

    const SkipRegistration = async (e) => {
        GtmDataLayer({
            question: "skip registration",
        });
        SetLenderLodar("showLoader");
        const skippedLeadsResponse = await skippedLeads("skipRegistration", uuid, split_name);
        const timer = setTimeout(() => {
            if (skippedLeadsResponse.data.status == "Success") {
                window.scrollTo(0, 0);
                history.push("/pcp/thankyou?uuid=" + uuid);
            }
        }, 2000);
    }

    // const handleRadioChange = (event) => {
    //     setSelectedKeeperDate(event.target.value);
    //     setModal("hide");
    // };

    return (
        <>
            <GetVisitorsParams />
            <div className="CarRegistration" >
                <div className="__PCPtcg">
                    <div className="bg_lol">
                        {/*TOP NAVIGATION*/}

                        <Header />
                        <section className="cs_hero cs_style_1 sec_design section_design1">
                            <div className="cs_hero_wrap cs_bg_filed">
                                {/*Gradient*/}
                                <div className="container-md">
                                    {/*  Car Registration */}
                                    <div className="car-section ">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-8 col-md-12  8 car-regi-middle mb-5">
                                                {/*  Car Number */}
                                                <div id="CardSect">
                                                    <div className="animate__animated animate__fadeInLeft ">
                                                        <h1 className="title2 fw-bold">Car Registration</h1>
                                                        <p className="sub-text2">
                                                            Providing your car registration number will help us
                                                            expedite your claim process.{" "}
                                                        </p>
                                                    </div>
                                                    <h2 className="mt-4 mb-3 animate__animated animate__fadeInLeft ">
                                                        Enter the vehicle registration number associated with your
                                                        lender(s)
                                                    </h2>

                                                    {Object.keys(textInputValues).map((name, index) => (
                                                        <div key={index} className="car-box p-3 mb-3 animate__animated animate__fadeInLeft  ">
                                                            <div className="bank-name mb-2">
                                                                {" "}
                                                                <i className="bi bi-bank2" /> {name}
                                                            </div>
                                                            <div className="  numb-plate-wrap justify-content-between ">
                                                                <div className="d-flex number-plate">
                                                                    <div className="flag"> </div>
                                                                    <div className="input-wrap w-100">

                                                                        <input
                                                                            type="text"
                                                                            className="form-control w-100"
                                                                            placeholder="Enter Vehicle Number "

                                                                            name={name}
                                                                            onChange={handleTextInputChange}
                                                                            ref={inputRefs.current[name]}
                                                                            value={textInputValues[name]}
                                                                        />

                                                                    </div>
                                                                </div>

                                                                {buttonClickedStatus[name] ? (
                                                                    <button
                                                                        type="button"
                                                                        className={`add-more addNUmber ${buttonClickedStatus[name] ? "animation-green-flash-infinite" : ""}`}
                                                                        name={name}
                                                                        onClick={() => handleAddButtonClick(name)}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                ) : (<div className="tick-img">
                                                                    <img src={tick} alt="Success" className="tick-image" />
                                                                </div>
                                                                )}

                                                            </div>
                                                            {/* <div
                                                                className="text-success text1 mt-1 purchasedate"
                                                                style={{ display: "none" }}
                                                            >
                                                                Purchased On 12 September 2020
                                                            </div> */}
                                                            {/* {selectedKeeperDate && buttonClickedStatus[name] == false &&(
                                                                <div
                                                                    className="text-success text1 mt-1 purchasedate"
                                                                >
                                                                    Purchased On {selectedKeeperDate}
                                                                </div>
                                                            )} */}

                                                            {validationMessages[name] && (
                                                                <label className="error mt-2">{validationMessages[name]}</label>
                                                            )}
                                                        </div>
                                                    ))}

                                                    {
                                                        ErrorMessageBtn && (
                                                            <label id="refund_previously-error" className="mt-2 mb-2 error_msg text-center" htmlFor="refund_previously">
                                                                {ErrorMessageBtn} </label>
                                                        )
                                                    }


                                                    <div className="row my-lg-4 my-md-4 my-sm-4 my-5 animate__animated animate__fadeInLeft">
                                                        <div className="col-4 pe-0">
                                                            <button
                                                                id="next3"
                                                                className=" com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn "
                                                                onClick={SkipRegistration}
                                                            >
                                                                Skip
                                                            </button>
                                                        </div>
                                                        <div className="col-8">
                                                            <button
                                                                id="carRegistNext"
                                                                type="button"
                                                                className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2"
                                                                onClick={validateAllFields}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-4 col-md-8 d-lg-block d-md-none d-sm-none d-none">
                                                <div className="mt-lg-4 ">
                                                    <img
                                                        src="/assets/TGG_V2/img/bg-float2.png"
                                                        alt="Hero"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <Footer />
                </div>

                <div className={`loader-div loaderresult loader-fix ${LoaderLender}`}>
                    <div className="text-center">
                        <img height="110" src="/assets/TGG_V2/img/loader03.svg" alt="" />
                    </div>
                </div>
                {/* Modal */}

                <Modal
                    show={VeichleModal}
                    onHide={VeichleModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                    contentClassName="modalcontentcar carmodal modal-dialog-centered"
                >
                    <Modal.Body>
                        <div className={`Second_slide1`}>
                            <div className="main-container pt-3">
                                <div className="check-container">
                                    <div className="check-background">
                                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth={13} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="check-shadow" />
                                </div>
                            </div>
                            <label className="h5 margin-5 text-center">Vehicle Added Successfully</label>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* <div
                    className={`modal fade-scale ${modal === 'show' ? 'show' : ''}`}
                    id="carLender"
               
                >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-body ">
                                <h2 className="  title pt-2">
                                    When did you purchase this vehicle?
                                </h2>
                                <div className="wraper-option banklist mb-4 mt-4 "> */}

                {/* {keeperDates.map((date, index) => (
                                        <div key={index} className="option-btn optionBtn mb-2 selLender">
                                            <span className="check"></span>
                                            {date}
                                            <label htmlFor={`cardate${index}`}>
                                                <input
                                                    type="radio"
                                                    name="keeper_date"
                                                    id={`cardate${index}`}
                                                    value={date}
                                                    onChange={handleRadioChange}
                                                />
                                            </label>
                                        </div>
                                    ))} */}
                {/* <div className="option-btn optionBtn mb-2 selLender">
                                        {" "}
                                        <span className="check"> </span>
                                        Other
                                        <label htmlFor="cardate4">
                                            <input type="radio" name="keeper_date" id="cardate4" />
                                        </label>
                                    </div> */}
                {/* <div
                                        className="text-center loader-div loaderLender"
                                        style={{ display: "none" }}
                                    >
                                        <img height={50} src="/assets/TGG_V2/img/loader03.svg" alt="" />{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* Modal */}



            </div>
        </>
    )
}

export default CarRegistrationTGG_V2
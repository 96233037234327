import React, { useRef, useState, useContext, useEffect } from "react";
import "../../assets/TGG_V1_B/scss/Questionnaire.scss";
import LenderHeader from '../Includes/Layouts/TGG_V1_B/LenderHeader';
import Footer from '../Includes/Layouts/TGG_V1_B/Footer';
import AccordSection from '../Includes/Layouts/TGG_V1_B/AccordSection';
import HomeTestimonials from '../Includes/Layouts/TGG_V1_B/Testimonial';
import Loader from '../Includes/Layouts/TGG_V1_B/Loader';
import { useForm } from "react-hook-form";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import VisitorParamsContext from "../../Contexts/VisitorParams"
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { useHistory } from "react-router-dom";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import RedirectModule from "../../Utility/RedirectModule";
import { useRedirectProbability } from "../../Hooks/useRedirectProbability.js";

const QuestionnaireTGGV1B = () => {

    const [loaderLender, setLenderLodar] = useState("hide");
    const query_string = localStorage.getItem("querystring");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    const history = useHistory();
    const splitForm = useRef(null);
    const { probablityPage } = useRedirectProbability();

    const optionSelected = async (e) => {
        setLenderLodar("show");
        let question_data = {};
        question_data = {
            question_id: 4,
            option_id: e.target.value,
            answer_text: null,
            input_answer: null,
        };
        storeQuestionnire(question_data);
    };

    const storeQuestionnire = async (question_data) => {
        GtmDataLayer({
            question: "filed claim",
          });
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            query_string,
            formdata
        );
        if (response.data.status == "Success") {
            const probabilityCheck = await probablityPage(visitorParameters.visitor_parameters.uuid);  
            if(probabilityCheck.data.status === "Success"){
            let pageAProbability = probabilityCheck.data.percentage1;
            const redirect = RedirectModule({
                splitName: "TGGV1_question",
                uuid: visitorParameters.visitor_parameters.uuid,
                pageAProbability: pageAProbability,
              });
              history.push(redirect);
            }
            // history.push(
            //     "/pcp/analyze?uuid=" + visitorParameters.visitor_parameters.uuid
            // );
        }
    };

    const getUuid = () => {
        const visitorData = visitorParameters.visitor_parameters;
        if (visitorData != "") {
            var uuid = visitorData.uuid;
        } else {
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem("uuid");
            var uuid = query_uuid ? query_uuid : local_storage_uuid;
        }
        return uuid;
    };

    const uuid = getUuid();

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });
    return (
        <>
            <div className='questionnaireTGG'>
                <GetVisitorsParams />
                <LenderHeader />
                <section className="form">
                    <form
                        ref={splitForm}
                        name="split_form"
                        id="user_form"
                        autoComplete="off"
                        className="main_form"
                    >
                        <div className="wrap larger">
                            <div className="container">
                                <div className="step start" id="step_lender">
                                    <div className="form-right bounce-y top-up d-block d-md-none">
                                        <div className="badge">
                                            <div className="content">
                                                <p className="fs-6 mb-0 text-weight-600 d-flex justify-content-center text-start">
                                                    <img src="/assets/TGG_V1/img/information-button.png"
                                                        className="w-auto mt-1" />&nbsp; Additional information required to
                                                </p>
                                                <p className="fs-6 margin-5 text-weight-600 d-flex justify-content-center  text-start">
                                                    <strong>expedite</strong> &nbsp;your claim process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-left">
                                        <div className="field">
                                            <label className="h5 margin-5">Have you, (or a company on your behalf), ever filed a PCP claim?</label>
                                            <ul className="options two lender mt-4">
                                                <li>
                                                    <input type="radio" className="custom-checkbox" name="claim" id="yes1" value="5" onClick={optionSelected} />
                                                    <label htmlFor="yes1">
                                                        Yes
                                                    </label>
                                                </li>
                                                <li>
                                                    <input type="radio" className="custom-checkbox" name="claim" id="no1" value="6" onClick={optionSelected} />
                                                    <label htmlFor="no1">
                                                        No
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-right bounce-y top-up d-none d-md-block">
                                            <div className="badge">
                                                <div className="content">
                                                    <p className="fs-6 mb-0 text-weight-600 d-flex justify-content-center text-start">
                                                        <img src="/assets/TGG_V1/img/information-button.png"
                                                            className="w-auto mt-1" />&nbsp; Additional information required to
                                                    </p>
                                                    <p className="fs-6 margin-5 text-weight-600 d-flex justify-content-center  text-start">
                                                        <strong>expedite</strong> &nbsp;your claim process.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="map">
                        <img src="./../assets/TGG_V1_B/img/uk-map.svg" />
                    </div>
                </section>
                <HomeTestimonials />
                <AccordSection />
                <Footer />
                <Loader loaderLender={loaderLender} />
            </div>
        </>
    )
}

export default QuestionnaireTGGV1B
import React, { useReducer, useState, useEffect, useRef } from "react";
import AddressTextField from "../../../UI/AddressTextFieldV2";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import InputButton from "../../../UI/InputButton";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
const initialState = {
    lookUp: true,
    nextClick: false,
    checkSelect: false,
    getDetails: [],
    getAddress: [],
    showManualAddress: "hide",
    getPostcode: null,
    clickManualLink: 0,
    readonly: true,
};

const postcodeReducer = (state, action) => {
    switch (action.type) {
        case "validationSucess": {
            return {
                ...state,
                lookUp: action.payload.lookup,
                nextClick: action.payload.next_click,
                getPostcode: action.payload.postcode,
                getAddress: action.payload.get_address,
                checkSelect: action.payload.check_select,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "getExtraAddressDetails": {
            return {
                ...state,
                getDetails: action.payload.get_details,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "showManualDiv": {
            return {
                ...state,
                showManualAddress: "show",
                lookUp: false,
                nextClick: true,
                checkSelect: false,
                clickManualLink: action.payload.manual_click,
                readonly: false,
            };
        }
        case "resetaddress": {
            return {
                ...state,
                getDetails: [],
                getAddress: [],
                showManualAddress: "hide",
                lookUp: true,
                nextClick: false,
                checkSelect: false,
            };
        }
        case "searchAddressAdr": {
            return {
                ...state,
                getPostcode: '',
                showSection: false,
                getDetails: [],
                getAddress: [],
                showManualAddress: "hide",
                lookUppre: true,
                nextClick: false,
                checkSelect: false,
            };
        }
        case "setPrevAdrrTwo": {
            return {
                ...state,
                getPostcode: action.payload.postcodePrevT,
            };
        }
        case "updateField": {
            return {
                ...state,
                getDetails: {
                    ...state.getDetails,
                    [action.payload.field]: action.payload.value
                }
            };
        }
        default:
            return state;
    }
};
const PostCode = ({
    validation,
    validationMsg,
    clearErrors,
    setError,
    slideChange,
    trigger,
    className,
    backClick,
    scrollView,
    setPreviousAddressData,
    getValues,
    formSubmit
}) => {

    const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
        usePostCodeAddress();
    const [state, dispatch] = useReducer(postcodeReducer, initialState);
    const [postCodeAddress, setPostCodeAddress] = useState([]);
    const [postCodeEntry, setPostCodeEntry] = useState("0");
    const [userpostCode, setuserPostCode] = useState();
    const customRef = useRef(null);
    const postCodeFieldRef = useRef(null);
    const [manualEdit, setManualEdit] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const checkValidation = async (e) => {
        e.preventDefault();
        dispatch({
            type: "resetaddress",
            get_address: [],
        });
        const txtPostCode = await trigger("txtPostCode");
        if (txtPostCode) {
            await getPostcodeAddress(e.target.value)
                .then((res) => {
                    if (res.data.status === "0") {
                        setError("txtPostCode", {
                            type: "manual",
                            message: "Please Enter Valid Postcode",
                        });
                    }
                    if (res.data.length > 0) {
                        setuserPostCode(e.target.value.toUpperCase());
                        clearErrors("txtPostCode");
                        dispatch({
                            type: "validationSucess",
                            payload: {
                                lookup: false,
                                next_click: true,
                                postcode: e.target.value,
                                get_address: res.data,
                                check_select: true,
                                manual_address: "hide",
                            },
                        });
                    }
                })
                .catch(function () {
                    setError("txtPostCode", {
                        type: "manual",
                        message: "Please Enter Valid Postcode",
                    });
                });
        }
    };
    const getValue = async (e) => {
        e.preventDefault();
        // clearErrors([
        //   "txtHouseNumber",
        //   "txtAddress2",
        //   "txtCounty",
        //   "txtTown",
        //   "txtCountry",
        // ]);
        if (e.target.value != "") {
            const getData = await getSplitPostcodeAddress(
                e.target.value,
                state.getPostcode
            );
            clearErrors(["address1"]);
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: getData.data, manual_address: "show" },
            });
            customRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setPostCodeAddress(getData.data);
        } else {
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
            });
            setPostCodeAddress([]);
        }
    };
    const lookupValidation = async (e) => {
        const pCode = await trigger("txtPostCode");
        if (pCode && state.checkSelect) {
            const selpcode = await trigger("address1");
            if (selpcode && e.target.name == "postcode") {
                setDisabled(true);
                GtmDataLayer({
                    question: "postcode",
                  });
                formSubmit();
            }
        }
    };
    const slideChanger = async (e) => {
        await trigger("txtPostCode");

    }

    // const checkPostCodeEntry = () => {
    //   const addressLine1 = document.getElementById("txtHouseNumber").value;
    //   const addressLine2 = document.getElementById("txtAddress2").value;
    //   const town = document.getElementById("txtTown").value;
    //   const county = document.getElementById("txtCounty").value;
    //   const country = document.getElementById("txtCountry").value;

    //   if (
    //     postCodeAddress.length === 0 ||
    //     postCodeAddress.Country !== country ||
    //     postCodeAddress.County !== county ||
    //     postCodeAddress.Line1 !== addressLine1 ||
    //     postCodeAddress.Line2 !== addressLine2 ||
    //     postCodeAddress.Town !== town
    //   ) {
    //     setPostCodeEntry("1");
    //   }
    // };

    const nextSlide = async (e) => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            slideChange(e);
        }, 1000);
    };
    // const addressValidation = async (e) => {

    //   let errorFlag = 0;
    //   const txtPostCode = await trigger("txtPostCode");
    //   var manual_txtHouseNumber;
    //   var manual_txtTown;
    //   var manual_txtCountry;
    //   var txtAddress;
    //   if (txtPostCode) {
    //     manual_txtHouseNumber = await trigger("txtHouseNumber");
    //     txtAddress = await trigger("address1");
    //   } else {
    //     document.getElementById("txtPostCode").focus();
    //     return false;
    //   }
    //   if (manual_txtHouseNumber) {
    //     manual_txtTown = await trigger("txtTown");
    //   } else {
    //     return false;
    //   }
    //   if (manual_txtTown) {
    //     manual_txtCountry = await trigger("txtCountry");
    //   } else {
    //     return false;
    //   }
    //   if (
    //     !txtPostCode ||
    //     !manual_txtHouseNumber ||
    //     !manual_txtTown ||
    //     !manual_txtCountry
    //   ) {
    //     errorFlag = 1;
    //   }

    //   if (state.clickManualLink === 0) {
    //     const address1 = await trigger("address1");
    //     if (!address1) {
    //       errorFlag = 1;
    //     }
    //   }
    //   if (errorFlag === 0) {
    //     checkPostCodeEntry();
    //     // slideChange(e);
    //     GtmDataLayer({
    //       question: "Postcode",
    //     });
    //   } else {
    //     return false;
    //   }
    // };

    const HiddenFields = () => (
        <>
             <HiddenField
                name="txtDistrict"
                inputId="txtDistrict"
                inputValue={state.getDetails !== "#" ? state.getDetails.district : ""}
                validation={validation()}
            />
            {/* <HiddenField
                name="txtAddress3"
                inputId="txtAddress3"
                inputValue={state.getDetails !== "#" ? state.getDetails.line_3 : ""}
                validation={validation()}
            />  */}
            <HiddenField
                name="txtUdprn"
                inputId="txtUdprn"
                inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
            />
            <HiddenField
                name="txtDeliveryPointSuffix"
                inputId="txtDeliveryPointSuffix"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
                }
            />
            <HiddenField
                name="txtPz_mailsort"
                inputId="txtPz_mailsort"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
                }
            />
            <HiddenField
                name="txtStreet"
                inputId="txtStreet"
                inputValue={
                    state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
                }
            />
        </>
    );
    const searchAddress = () => {
        dispatch({
            type: "searchAddressAdr",
        });
        postCodeFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const onChangePrevPostcode = (e) => {
        dispatch({
            type: "setPrevAdrrTwo",
            payload: {
                postcodePrevT: e.target.value,
            },
        });
    }



    const handlePostcodeChange = (e) => {
        const { name, value } = e.target;

        let fieldname = ""
        switch (name) {
            case "txtHouseNumber":
                fieldname = "line_1"
                break;
            case "txtAddress2":
                fieldname = "line_2"
                break;
            case "txtAddress3":
                fieldname = "line_3"
                break;
            case "txtCounty":
                fieldname = "county"
                break;
            case "txtTown":
                fieldname = "town"
                break;
            case "txtCountry":
                fieldname = "country"
                break;
            default:
                break;
        }
        dispatch({ type: "updateField", payload: { field: fieldname, value: value } });
    };


    return (
        <>
            <div className={`home_plate_005  ${className}`}>
                <div className="row justify-content-center ">
                    <div className="cs_hero_text col-lg-7 col-12 order-lg-1 order-md-1 order-2">
                        <div className="plateTwo_anim">
                            <h3 className="top_pad_Lil animate__animated animate__fadeInLeft animate__delay-.5s">
                                Congratulations!
                            </h3>
                            <h3 className="top_pad_Lil sub_bl2Xw pb-3 animate__animated animate__fadeInLeft animate__delay-.5s">
                                According to your responses, it seems you might be eligible for compensation. To proceed, please finalize your registration by providing your current address.
                            </h3>

                            <div className="form_drop col-lg-12 col-12" ref={postCodeFieldRef}>
                                <div className="sect_bt animate__animated animate__fadeInLeft animate__delay-.5s" style={{ zIndex: 2, position: 'relative' }}>
                                    <p className="png_t">
                                        Enter your postcode and confirm your current address
                                    </p>
                                    <HiddenField
                                        name="pCode_EntryType"
                                        inputId="pCode_EntryType"
                                        inputValue={postCodeEntry}
                                    />
                                    <div className="enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s">
                                        <label>Postcode</label>
                                        <div className="postcode_wrap">
                                            <AddressTextField
                                                type="text"
                                                placeholder="Enter your postcode"
                                                name="txtPostCode"
                                                id="txtPostCode"
                                                autocomplete="off"
                                                className="form-control"
                                                dataId="txtPostCode"
                                                onBlur={checkValidation}
                                                value={state.getPostcode}
                                                onChange={onChangePrevPostcode}
                                                autoComplete="off"
                                                validation={validation({
                                                    required: "Please Enter Postcode",
                                                    pattern: {
                                                        value:
                                                            /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                                                        message: "Please Enter a Valid Postcode",
                                                    },
                                                    minLength: {
                                                        value: 5,
                                                        message: "Please Enter a Valid Postcode",
                                                    },
                                                })}
                                            />
                                            <button type="button" className="bg_add_click button"
                                                name="next"
                                                value="Find My Address"
                                                onClick={slideChanger}>
                                                Find My Address
                                            </button>

                                        </div>
                                        {validationMsg.txtPostCode && (
                                            <span className="error_msg " id="email_err">
                                                {validationMsg.txtPostCode.message}
                                            </span>
                                        )}
                                        <p className="tiny_note" ref={customRef}>
                                            <img src="/assets/TGG_V2/img/info.png" className="pe-1" alt="" />Your address is required for a smooth claim process
                                        </p>
                                        <div className="col-lg-5 col-12">

                                        </div>
                                        <div
                                            className={`col-12 pt-3 animate__animated animate__fadeInLeft animate__delay-.5s`}
                                            id="selcetAdd"
                                            style={
                                                state.checkSelect
                                                    ? { display: "block" }
                                                    : { display: "none" }
                                            }
                                        >
                                            <AddressSelectBox
                                                className="form-select"
                                                OptionValue={state.getAddress}
                                                name="address1"
                                                id="address1"
                                                onChange={getValue}
                                                myRef={validation({ required: "Please Select Address" })}
                                                validationMsg={
                                                    validationMsg.address1 && validationMsg.address1.message
                                                }
                                            />
                                            <div ></div>
                                            {/*  <AddressTextField
                                                type="text"
                                                placeholder="Address Line 1 *"
                                                name="txtHouseNumber"
                                                id="txtHouseNumber"
                                                className="hide"
                                                dataId="txtHouseNumber"
                                                autoComplete="off"
                                                readonly={state.readonly}
                                                validation={validation({
                                                    required: "Please Enter Address Line 1",
                                                })}
                                                validationMsg={
                                                    validationMsg.txtHouseNumber &&
                                                    validationMsg.txtHouseNumber.message
                                                }
                                                value={state.getDetails.line_1}
                                            />
                                            <AddressTextField
                                                type="text"
                                                placeholder="Address Line 2"
                                                name="txtAddress2"
                                                id="txtAddress2"
                                                className="hide"
                                                dataId="txtAddress2"
                                                autoComplete="off"
                                                readonly={state.readonly}
                                                validation={validation()}
                                                validationMsg=""
                                                value={state.getDetails.line_2}
                                            />
                                            <AddressTextField
                                                type="text"
                                                placeholder="County"
                                                name="txtCounty"
                                                id="txtCounty"
                                                className="hide"
                                                dataId="txtCounty"
                                                autoComplete="off"
                                                readonly={state.readonly}
                                                validation={validation()}
                                                validationMsg=""
                                                value={state.getDetails.county}
                                            />
                                            <AddressTextField
                                                type="text"
                                                placeholder="Town *"
                                                name="txtTown"
                                                id="txtTown"
                                                className="hide"
                                                dataId="txtTown"
                                                autoComplete="off"
                                                readonly={state.readonly}
                                                validation={validation({ required: "Please Enter Town" })}
                                                validationMsg={
                                                    validationMsg.txtTown && validationMsg.txtTown.message
                                                }
                                                value={state.getDetails.town}
                                            />
                                            <AddressTextField
                                                type="text"
                                                placeholder="Country *"
                                                name="txtCountry"
                                                id="txtCountry"
                                                className="hide"
                                                dataId="txtCountry"
                                                autoComplete="off"
                                                readonly={state.readonly}
                                                validation={validation({ required: "Please Enter Country" })}
                                                validationMsg={
                                                    validationMsg.txtCountry && validationMsg.txtCountry.message
                                                }
                                                value={state.getDetails.country}
                                            />
                                            {Object.keys(state.getDetails).length > 0 ? (
                                                <HiddenFields />
                                            ) : null} */}
                                            
                                            <div id="selcetAdd" className={`slide8_add row mt-2 gy-2 ${state.showManualAddress}` }>
                                                {!manualEdit &&
                                                    <div
                                                        className="ADD_detail py-3 px-3 animate__animated animate__fadeInLeft animate__delay-.5s"
                                                        style={{ display: "block" }}
                                                    >
                                                        <p className="ADD_clr_typ">
                                                            {state.getDetails.line_1}<br />
                                                            {state.getDetails.line_2}<br />
                                                            {state.getDetails.county}<br />
                                                            {state.getDetails.town}<br />
                                                            {state.getDetails.country}<br />

                                                        </p>
                                                        <p className="ADD_na_top mb-0">
                                                            {userpostCode}
                                                        </p>
                                                    </div>
                                                }
                                                <div className={`${!manualEdit && "d-none"} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}>
                                                    <AddressTextField
                                                        type="text"
                                                        placeholder="Address Line 1 *"
                                                        name="txtHouseNumber"
                                                        id="txtHouseNumber"
                                                        className="form-control"
                                                        dataId="txtHouseNumber"
                                                        autoComplete="off"
                                                        readonly={manualEdit ? false : true}
                                                        validation={validation({
                                                            required: "Please Enter Address Line 1",
                                                        })}
                                                        validationMsg={
                                                            validationMsg.txtHouseNumber &&
                                                            validationMsg.txtHouseNumber.message
                                                        }
                                                        value={state.getDetails.line_1 || ""}
                                                        onChange={handlePostcodeChange}
                                                    />
                                                    <i
                                                        className="validate validate_success"
                                                        aria-hidden="true"
                                                        style={{ display: "none" }}
                                                    ></i>
                                                </div>

                                                <div className={`${!manualEdit && "d-none"} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}>
                                                    <AddressTextField
                                                        type="text"
                                                        placeholder="Address Line 2"
                                                        name="txtAddress2"
                                                        id="txtAddress2"
                                                        className="form-control"
                                                        dataId="txtAddress2"
                                                        autoComplete="off"
                                                        readonly={manualEdit ? false : true}
                                                        validation={validation()}
                                                        validationMsg=""
                                                        value={state.getDetails.line_2 || ""}
                                                        onChange={handlePostcodeChange}
                                                    />
                                                    <i
                                                        className="validate validate_success"
                                                        aria-hidden="true"
                                                        style={{ display: "none" }}
                                                    ></i>
                                                </div>
                                                <div className={` ${!manualEdit && "d-none"} animate__animated animate__fadeInLeft animate__delay-.5s`}>
                                                    <AddressTextField
                                                        type="text"
                                                        placeholder="Address Line 3"
                                                        name="txtAddress3"
                                                        id="txtAddress3"
                                                        className="form-control"
                                                        dataId="txtAddress3"
                                                        autoComplete="off"
                                                        readonly={manualEdit ? false : true}
                                                        validation={validation()}
                                                        validationMsg=""
                                                        value={state.getDetails.line_3 || ""}
                                                        onChange={handlePostcodeChange}
                                                    />
                                                    <i
                                                        className="validate validate_success"
                                                        aria-hidden="true"
                                                        style={{ display: "none" }}
                                                    ></i>
                                                </div>

                                                <div className={` ${!manualEdit && "d-none"} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}>
                                                    <AddressTextField
                                                        type="text"
                                                        placeholder="County"
                                                        name="txtCounty"
                                                        id="txtCounty"
                                                        className="form-control"
                                                        dataId="txtCounty"
                                                        autoComplete="off"
                                                        readonly={manualEdit ? false : true}
                                                        validation={validation()}
                                                        validationMsg=""
                                                        value={state.getDetails.county || ""}
                                                        onChange={handlePostcodeChange}
                                                    />
                                                    <i
                                                        className="validate validate_success"
                                                        aria-hidden="true"
                                                        style={{ display: "none" }}
                                                    ></i>
                                                </div>

                                                <div className={` ${!manualEdit && "d-none"} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}>
                                                    <AddressTextField
                                                        type="text"
                                                        placeholder="Town *"
                                                        name="txtTown"
                                                        id="txtTown"
                                                        className="form-control"
                                                        dataId="txtTown"
                                                        autoComplete="off"
                                                        readonly={manualEdit ? false : true}
                                                        validation={validation({ required: "Please Enter Town" })}
                                                        validationMsg={
                                                            validationMsg.txtTown && validationMsg.txtTown.message
                                                        }
                                                        value={state.getDetails.town || ""}
                                                        onChange={handlePostcodeChange}
                                                    />
                                                    <i
                                                        className="validate validate_success"
                                                        aria-hidden="true"
                                                        style={{ display: "none" }}
                                                    ></i>
                                                </div>
                                                <div className={`${!manualEdit && "d-none"} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}>
                                                    <AddressTextField
                                                        type="text"
                                                        placeholder="Country *"
                                                        name="txtCountry"
                                                        id="txtCountry"
                                                        className="form-control"
                                                        dataId="txtCountry"
                                                        autoComplete="off"
                                                        readonly={manualEdit ? false : true}
                                                        validation={validation({ required: "Please Enter Country" })}
                                                        validationMsg={
                                                            validationMsg.txtCountry && validationMsg.txtCountry.message
                                                        }
                                                        value={state.getDetails.country || ""}
                                                        onChange={handlePostcodeChange}
                                                    />
                                                    <i
                                                        className="validate validate_success"
                                                        aria-hidden="true"
                                                        style={{ display: "none" }}
                                                    ></i>
                                                </div>

                                                {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
                                            </div>
                                            <a className="return_back">
                                                <p className="mb-0 mt-4">
                                                    <span className="" onClick={searchAddress}>
                                                        <img src="/assets/TGG_V2/img/return.png" alt="" />
                                                        <span>Search Again</span>
                                                    </span>
                                                    <span className={`${manualEdit && "d-none"} ${state.showManualAddress}`} onClick={() => setManualEdit(true)}>
                                                        <img src="/assets/TGG_V2/img/edit.png" alt="" />
                                                        <span>Edit</span>
                                                    </span>
                                                </p>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div className="box_button pt-3 animate__animated animate__fadeInLeft animate__delay-.5s">
                                    <div className="bg-1">
                                        <input type="button"
                                            className="button button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice" id="nextPostCode"
                                            value="Submit Claim"
                                            name="postcode"
                                            onClick={lookupValidation}
                                            disabled={disabled}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="img_float animate__animated animate__fadeInLeft animate__delay-.5s order-lg-2 order-md-2 order-1 col-lg-5 col-12 text-center d-lg-block d-md-block d-none">

                        <img src="/assets/TGG_V2/img/bg-float2.png" alt="Hero" className="cs_hero_img" />
                    </div>
                </div>
            </div>



        </>
    );

};

export default PostCode;
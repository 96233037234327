import React from "react";


const LoaderV2 = ({ loader1 }) => {


    return (
        <>



            <div className={` ${loader1}`}>
                <div className="inner">
                    <div className="my-3">
                        <img height="80" src="/assets/TGG_V2/img/loader03.svg" alt="" />
                    </div>
                    <h6 className="px-2">Please wait while we review the information you have provided to determine your eligibility for a claim.</h6>
                </div>
            </div>

        </>
    )

}

export default LoaderV2;

function RedirectModule({ splitName, uuid, pageAProbability }) {
    let pageName;
    console.log("probability",pageAProbability);
    if (splitName === "TGG_V1" || splitName === "TGG_V1_A") {
        pageName = RedirectAnalyze({ splitName, uuid, pageAProbability });  
    } 
    else if (splitName === "TGG_V1_B" ) {
        pageName= `/pcp/questionnaireTGG?split_name=${splitName}&uuid=${uuid}`;
    } else if(splitName === "TGG_V2" ) {
        pageName= `/pcp/signatureTGGV2?split_name=${splitName}&uuid=${uuid}`;
    } else if("TGGV2_sign" === splitName ) {
        pageName= `/pcp/analyzeV2?split_name=${splitName}&uuid=${uuid}`;
    }
    else {
        console.log("Else Condition");
        pageName = RedirectAnalyze({ splitName, uuid, pageAProbability });
    }
    return pageName;
}
const RedirectAnalyze = ({ splitName, uuid, pageAProbability }) => {
    const randomValue = Math.random();
    console.log("randomValue ",randomValue);
    let page;
    if (randomValue < pageAProbability) {
        page = `/pcp/analyze?split_name=${splitName}&uuid=${uuid}`;
    } else {
        page = `/pcp/analyze-lead?split_name=${splitName}&uuid=${uuid}`;
    }
    return page;
}

export default RedirectModule;


import React from "react";
import "../../../assets/TGG_V1_OLD/css/newTGG.scss"

const PrivacyPolicy = () => {
  return (
    <>
      <div className="doc_sections">
        <section className="doc_sections">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 py-5 offset-lg-1">
                <div className="doc-head">
                  <h2 className="text-center animate__animated animate__fadeInUp animate__delay-.5s">
                    Privacy Policy
                  </h2>
                  <h1 className="text-center doc-head mb-lg-5 mb-md-5 mb-sm-4 mb-4 animate__animated animate__fadeInUp animate__delay-.5s">
                    Privacy Policy
                  </h1>
                </div>
                <div className="elementor-widget-container">
                  <p>
                    <strong>
                      Last Updated: On 12-June-2024 <br />
                      Effective Date: 12-June-2024{" "}
                    </strong>
                  </p>
                  <p>
                    This Privacy Policy describes the policies of Courmacs Legal
                    Ltd (“we,” “us,” or “our”), Alexander House, Beehive Trading
                    Park, Haslingden Road, Blackburn, Lancashire BB1 2EE, United
                    Kingdom of Great Britain and Northern Ireland (the),
                    email:&nbsp;{" "}
                    <a href="mailto:info@courmacslegal.co.uk">
                      info@courmacslegal.co.uk
                    </a>
                    , phone:&nbsp; <a href="tel:+443303410481">0330 341 0481</a>
                    &nbsp;on the collection, use and disclosure of your
                    information that we collect when you use our website
                    (courmacslegal.co.uk) (“the Service”). By accessing or using
                    the Service, you are consenting to the collection, use and
                    disclosure of your information in accordance with this
                    Privacy Policy. If you do not consent to the same, please do
                    not access or use the Service.{" "}
                  </p>
                  <p>
                    We may modify this Privacy Policy at any time without any
                    prior notice to you and will post the revised Privacy Policy
                    on the Service. The revised Policy will be effective
                    immediately from when the revised Policy is posted in the
                    Service and your continued access or use of the Service
                    after such time will constitute your acceptance of the
                    revised Privacy Policy. We therefore recommend that you
                    periodically review this page.
                  </p>
                  <h3 className="wp-block-heading">Information We Collect</h3>
                  <p>
                    <strong>
                      By filling out a form on our website or social media we
                      may collect and process the following personal information
                      about you
                    </strong>
                    :
                  </p>
                  <ul>
                    <li>Name</li>
                    <li>Email</li>
                    <li>Mobile</li>
                    <li>
                      Company Name{" "}
                      <em>(in relation to Business Energy Claims)</em>
                    </li>
                  </ul>
                  <h5 className="wp-block-heading">
                    Third Parties or Public Sources
                  </h5>
                  <p>
                    We may receive information about you from various reputable
                    third-party lead providers, credit reference agencies, DVLA,
                    from our regulators or from publicly available data. We will
                    always inform you where we have obtained your information
                    from if requested by you.
                  </p>
                  <h5 className="wp-block-heading">
                    Third Party Marketing Provider
                  </h5>
                  <p>
                    We collaborate with TGG, a third-party marketing provider,
                    to run our marketing campaigns. TGG will collect and process
                    personal data, which includes but is not limited to:
                  </p>
                  <ul>
                    <li>
                      <strong>Contact Information:</strong> Name, current and
                      previous addresses (including postal, email, and IP
                      addresses), and telephone numbers.
                    </li>
                    <li>
                      <strong>Identity Data:</strong> First name, maiden name,
                      last name, username or similar identifier, marital status,
                      title, date of birth, and gender.
                    </li>
                    <li>
                      <strong>Financial Data:</strong> Information on your
                      financial circumstances, the lenders or financial
                      institutions used by you, the financial products you have
                      taken out, and the details and status of those financial
                      products.
                    </li>
                    <li>
                      <strong>Vehicle Data:</strong> Information about your
                      vehicle such as registration number, make, and model.
                    </li>
                    <li>
                      <strong>Usage Data:</strong> Information about how you use
                      TGG’s website, products, and services. This includes your
                      IP address, geographical location, browser type and
                      version, operating system, referral source, length of
                      visit, page views, and website navigation paths, as well
                      as information about the timing, frequency, and pattern of
                      your service use. The source of the usage data is TGG’s
                      analytics tracking system.
                    </li>
                    <li>
                      <strong>Marketing and Communications Data:</strong> Your
                      preferences in receiving marketing from us and third
                      parties and your communication preferences.
                    </li>
                    <li>
                      <strong>Special Category Data:</strong> This includes
                      sensitive data such as race or ethnicity, religious or
                      philosophical beliefs, sex life, sexual orientation,
                      political opinions, trade union membership, health
                      information, and genetic and biometric data. TGG will only
                      process special category data with your explicit consent
                      or where there is a legal/regulatory obligation.
                    </li>
                  </ul>
                  <p>
                    At the point of input, you will agree to have your
                    information processed by TGG and passed on to us. This data
                    enables us to verify your eligibility for our services and
                    to determine whether you can make a claim. For information
                    on how TGG handles your data, please refer to their{" "}
                    <a
                      href="https://genius.co.uk/privacy.php"
                      target="_blank"
                      rel="noopener"
                    >
                      privacy policy
                    </a>
                    . Once we receive your information from TGG, we will use it
                    to perform the necessary checks and contact you regarding
                    the processing or closure of your case file.{" "}
                  </p>
                  <h3 className="wp-block-heading">
                    How We Use Your Information
                  </h3>
                  <p>
                    <strong>
                      We will use the information that we collect about you for
                      the following purposes:
                    </strong>
                  </p>
                  <ul>
                    <li>Marketing/ Promotional</li>
                    <li>Customer feedback collection</li>
                    <li>Administration info</li>
                    <li>Targeted advertising</li>
                  </ul>
                  <p>
                    If we want to use your information for any other purpose, we
                    will ask you for consent and will use your information only
                    on receiving your consent and then, only for the purpose(s)
                    for which grant consent unless we are required to do
                    otherwise by law.
                  </p>
                  <h5 className="wp-block-heading">Marketing</h5>
                  <p>
                    You are provided with choices regarding marketing, and we
                    record your preferences in relation to this and how we
                    communicate with you. <br />
                    We may rely on consent when we use your personal information
                    for direct marketing. This will be where you have
                    specifically consented to us or a third party that you are
                    happy to receive marketing contact from us. <br />
                    We may also rely on legitimate interests for our direct
                    marketing. Our legitimate interests are to inform
                    individuals about products or services which may be of
                    interest to them. Our legitimate interest may also be our
                    commercial interests in operating our business, which
                    includes acquiring new customers, providing additional
                    services to existing or previous customers that are similar
                    or aligned with previous products or services and expanding
                    our operations. <br />
                    You may also receive marketing communications from us if you
                    have previously enquired about or purchased similar goods or
                    services from us and, in each case, you have not opted-out
                    of receiving that marketing. This is known as a ‘soft
                    opt-in’. <br />
                    Such marketing communications may be in relation to claims
                    management, debt advice, financial advice and legal services
                    which could be done by post, email, SMS or telephone. <br />
                    To opt out of receiving future marketing under any lawful
                    basis, or under soft opt in, in which we intend to rely on
                    for direct marketing, please opt-out using the contact
                    details provided in this Privacy Policy. <br />
                    If you require any further information about the lawful
                    basis we have relied on to send direct marketing to you,
                    please do not hesitate to contact us.{" "}
                  </p>
                  <h3 className="wp-block-heading">
                    How We Share Your Information
                  </h3>
                  <p>
                    <strong>
                      We will not transfer your personal information to any
                      third party without seeking your consent, except in
                      limited circumstances as described below:
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Google Analytics, this will include your IP address for
                      marketing and tracking purposes.
                    </li>
                    <li>
                      Funders who fund your claim, this will include details of
                      who you are and the claim we are making on your behalf as
                      well as details of any settlement offer.
                    </li>
                  </ul>
                  <p>
                    We require third parties to use the personal information we
                    transfer to them only for the purpose for which it was
                    transferred and not to retain it for longer than is required
                    for fulfilling the said purpose. We may also disclose your
                    personal information for the following: (1) to comply with
                    applicable law, regulation, court order or other legal
                    process; (2) to enforce your agreements with us, including
                    this Privacy Policy; or (3) to respond to claims that your
                    use of the Service violates any third-party rights. If the
                    Service or our company is merged or acquired with another
                    company, your information will be one of the assets that is
                    transferred to the new owner.
                  </p>
                  <h3 className="wp-block-heading">
                    Retention Of Your Information
                  </h3>
                  <p>
                    We will retain your personal information with us for 90 days
                    to 6 years after user accounts remain idle or for as long as
                    we need it to fulfil the purposes for which it was collected
                    as detailed in this Privacy Policy. We may need to retain
                    certain information for longer periods such as
                    record-keeping/reporting in accordance with applicable law
                    or for other legitimate reasons like enforcement of legal
                    rights, fraud prevention, etc. Residual anonymous
                    information and aggregate information, neither of which
                    identifies you (directly or indirectly), may be stored
                    indefinitely.
                  </p>
                  <h3 className="wp-block-heading">Your Rights</h3>
                  <p>
                    Depending on the law that applies, you may have a right to
                    access and rectify or erase your personal data or receive a
                    copy of your personal data, restrict or object to the active
                    processing of your data, ask us to share (port) your
                    personal information to another entity, withdraw any consent
                    you provided to us to process your data, a right to lodge a
                    complaint with a statutory authority and such other rights
                    as may be relevant under applicable laws. To exercise these
                    rights, you can write to us at&nbsp;{" "}
                    <a href="mailto:info@courmacslegal.co.uk">
                      info@courmacslegal.co.uk
                    </a>
                    . We will respond to your request in accordance with
                    applicable law.{" "}
                  </p>
                  <p>
                    Do note that if you do not allow us to collect or process
                    the required personal information or withdraw the consent to
                    process the same for the required purposes, you may not be
                    able to access or use the services for which your
                    information was sought.
                  </p>
                  <h3 className="wp-block-heading">Cookies Etc</h3>
                  <p>
                    To learn more about how we use these and your choices in
                    relation to these tracking technologies, please refer to
                    our&nbsp;{" "}
                    <a href="https://courmacslegal.co.uk/cookie-policy">
                      Cookie Policy
                    </a>
                    .{" "}
                  </p>
                  <h3 className="wp-block-heading">Security</h3>
                  <p>
                    The security of your information is important to us and we
                    will use reasonable security measures to prevent the loss,
                    misuse or unauthorised alteration of your information under
                    our control.{" "}
                  </p>
                  <h3 className="wp-block-heading">
                    Third Party Links &amp; Use of Your Information
                  </h3>
                  <p>
                    Our Service may contain links to other websites that are not
                    operated by us. This Privacy Policy does not address the
                    privacy policy and other practices of any third parties,
                    including any third party operating any website or service
                    that may be accessible via a link on the Service. We
                    strongly advise you review the privacy policy of every site
                    you visit. We have no control over and assume no
                    responsibility for the content, privacy policies or
                    practices of any third-party sites or services.
                  </p>
                  <h3 className="wp-block-heading">
                    Grievance/Data Protection Officer
                  </h3>
                  <p>
                    If you have any queries or concerns about the processing of
                    your information that is available with us, you may email
                    our Data Protection Officer, Darren Smith (Reg ref:
                    ZA886741), at Courmacs Legal Ltd, Alexander House, Beehive
                    Trading Park, Haslingden Road, BB1 2EE, email:&nbsp;{" "}
                    <a href="mailto:ds@courmacslegal.co.uk">
                      ds@courmacslegal.co.uk
                    </a>
                    . We will address your concerns in accordance with
                    applicable law.{" "}
                  </p>
                  <h3 className="wp-block-heading">Marketing Firm</h3>
                  <p>
                    TGG Ltd are acting as Marketing agents for Courmacs Legal
                    Ltd
                  </p>
                  <p>TGG Ltd are paid for their services.</p>
                  <p>
                    TGG Ltd don’t use the data captured in any other way other
                    than to pass to Courmacs Legal Ltd, or in some cases to
                    reengage with customers prior to passing to Courmacs Legal
                    Ltd to allow a customer to complete their application.
                  </p>
                  <p>
                    Re-engagement may include but is not limited to Email &amp;
                    SMS &amp; Telephone.
                  </p>
                  <p>All re-engagement will include an opt out option.</p>
                  <p>
                    <strong>
                      If you have any queries about marketing Contact us:
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Write to us at: Deansfield House, 98 Lancaster Road,
                      Newcastle-Under-Lyme, Staffordshire, England, ST5 1DS{" "}
                    </li>
                    <li>
                      See TGG’S Privacy Policy ({" "}
                      <a
                        href="https://genius.co.uk/policies/"
                        target="_blank"
                        rel="noopener"
                      >
                        https://genius.co.uk/policies/
                      </a>
                      ){" "}
                    </li>
                    <li>
                      For queries please contact Courmacs Legal Ltd ;{" "}
                      <a href="mailto:info@courmacslegal.co.uk">
                        info@courmacslegal.co.uk
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;

import React from "react";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Header = () => {
  return (
    <>
      <div>
        <div className="progress progress_main d-none">
          <div className="progress-bar">
            <div id="progressBarInner" style={{ width: "0%" }} />
          </div>
        </div>
        <header>
          <div className="wrap larger container">
            <div className="row">
              <div className="logo">
                {/* <img src="/assets/TGG_V1/img/logo.png" style={{ width: 200 }} /> */}
                <LogoBasedOnDomain style={{ width: 200 }}/>
              </div>
              
              <div className="secure-badge">
                <img src="/assets/TGG_V1/img/secure.png" />
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;

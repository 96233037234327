import React from "react";
import Accordion from "react-bootstrap/Accordion";

const AccordionSection = () => {
  const buttontotop =()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
 }
  return (
    <>
      <section className="background-grey progressed_hide">
        <div className="wrap">
          <div className="container">
            <h3>Frequently asked questions</h3>
          </div>
        </div>
        <div className="wrap">
          <div className="container">
            <Accordion>
              <Accordion.Item eventKey="0" className="margin-15">
                <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                  How do I determine if I qualify for compensation?
                </Accordion.Header>
                <Accordion.Body>
                  If you've had a finance agreement like PCP or HP in the last
                  decade, you may be eligible for compensation due to
                  mis-selling
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="accordion-item margin-15">
                <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                  What criteria must I meet to make a claim?
                </Accordion.Header>
                <Accordion.Body>
                  Your lender must have failed to disclose the commission
                  details on your agreement(s). If your finance details were
                  inadequately explained or you faced higher interest rates due
                  to commissions, you could be eligible for £1,000s
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="accordion-item margin-15">
                <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                  Does the type of agreement matter (PCP vs. HP)?
                </Accordion.Header>
                <Accordion.Body>
                  Don't worry if you had an HP loan instead of a PCP agreement —
                  we accept claims for various vehicle finance agreements
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <p className="margin-none">
              <a className="button smaller" onClick={buttontotop} >
                Check my claim <img src="/assets/TGG_V1/img/arrow-right.png" alt="" />
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccordionSection;

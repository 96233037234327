import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useContext,
} from "react";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import CarFinance from "../Includes/Layouts/TGG_V1_OLD/CarFinance";
import Lenders from "../Includes/Layouts/TGG_V1_OLD/Lenders";
import Commision from "../Includes/Layouts/TGG_V1_OLD/Commision";
import PostCode from "../Includes/Layouts/TGG_V1_OLD/PostCode";
import Name from "../Includes/Layouts/TGG_V1_OLD/Name";
import DateOfBirth from "../Includes/Layouts/TGG_V1_OLD/DateOfBirth";
import ContactDetails from "../Includes/Layouts/TGG_V1_OLD/ContactDetails";
import QueryStringContext from "../../Contexts/QueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useDuplicateCheck } from "../../Hooks/useDuplicateCheck";

const FormTGG_V1 = ({ setLoader1, setLoader2, setProgressBar }) => {
  const initialState = {
    carFinance: "show",
    lenders: "hide",
    commission: "hide",
    postcode: "hide",
    name: "hide",
    dateofbirth: "hide",
    contactdetails: "hide",
  };

  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "carFinance") {
          return {
            ...state,
            carFinance: "hide",
            lenders: "show",
          };
        } else if (action.payload.clickedSlide.slide === "lenders") {
          return {
            ...state,
            lenders: "hide",
            commission: "show",
          };
        } else if (action.payload.clickedSlide.slide === "commission") {
          return {
            ...state,
            commission: "hide",
            postcode: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            postcode: "hide",
            name: "show",
          };
        } else if (action.payload.clickedSlide.slide === "name") {
          return {
            ...state,
            name: "hide",
            dateofbirth: "show",
          };
        } else if (action.payload.clickedSlide.slide === "dateofbirth") {
          return {
            ...state,
            dateofbirth: "hide",
            contactdetails: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "carFinance") {
          return {
            ...state,
            carFinance: "show",
            lenders: "hide",
          };
        } else if (action.payload.previousSlide.slide === "lenders") {
          return {
            ...state,
            lenders: "show",
            commission: "hide",
          };
        } else if (action.payload.previousSlide.slide === "commission") {
          return {
            ...state,
            commission: "show",
            postcode: "hide",
          };
        } else if (action.payload.previousSlide.slide === "postcode") {
          return {
            ...state,
            postcode: "show",
            name: "hide",
          };
        } else if (action.payload.previousSlide.slide === "name") {
          return {
            ...state,
            name: "show",
            dateofbirth: "hide",
          };
        } else if (action.payload.previousSlide.slide === "dateofbirth") {
          return {
            ...state,
            dateofbirth: "show",
            contactdetails: "hide",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      default: {
      }
    }
  };

  const splitForm = useRef(null);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [isLenders, setIsLenders] = useState(false);
  const history = useHistory();
  const field = "pid";
  const [LenderSelectedList, setLenderSelectedList] = useState([]);
  const [LenderValidationMsg, setLenderValidationMsg] = useState();
  const { queryString } = useContext(QueryStringContext);
  const { formDataIngestion } = useDataIngestion();
  const { duplicateCheck } = useDuplicateCheck();

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;
      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    formData.carFinance = formData.carFinance;
    formData.lenders = LenderSelectedList;
    formData.commission = formData.commission;

    formData.page_name = "/pcp/TGG_V1";
    formData.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("fName", formData.txtFName);
      localStorage.setItem("lName", formData.txtLName);
      localStorage.setItem("pCode", formData.txtPostCode);
      localStorage.setItem("addressLine1", formData.txtHouseNumber);
      localStorage.setItem("dobYr", formData.DobYear);
      localStorage.setItem("dobMon", formData.DobMonth);
      localStorage.setItem("dobDy", formData.DobDay);
      localStorage.setItem("addressLine2", formData.txtAddress2);
      localStorage.setItem("postTown", formData.txtTown);
      localStorage.setItem("country", formData.txtCountry);
      localStorage.setItem("title", formData.lstSalutation);
      localStorage.setItem("addressLine3", formData.txtAddress3);
      localStorage.setItem("county", formData.txtCounty);
      localStorage.setItem(
        "district",
        formData.txtDistrict ? formData.txtDistrict : ""
      );

      let UcFname =
        formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);
      const formSubmitResult = formDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "TGG_V1",
        queryString,
        visitorParameters.data
      );
      let uuid = visitorParameters.visitor_parameters.uuid;
      let email = formData.txtEmail;
      let mobile = formData.txtPhone;
      let postcode = formData.txtPostCode;
      const response = await duplicateCheck(uuid, email, mobile, postcode);
      if (response.data.response.isDuplicate === "yes") {
        window.scrollTo(0, 0);
        history.push(
          "/pcp/unqualified?split_name=TGG_V1&uuid=" +
            visitorParameters.visitor_parameters.uuid
        );
      } else {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            if (EnvConstants.AppConversionPixel === "true") {
              history.push("/fbpixel?split_name=TGG_V1"); // fb fixel firing
            } else {
              window.scrollTo(0, 0);
              history.push("/pcp/signatureTGG?split_name=TGG_V1&uuid=" + uuid);
            }
          } else {
            window.scrollTo(0, 0);
            history.push("/pcp/signatureTGG?split_name=TGG_V1&uuid=" + uuid);
          }
        } else {
          window.scrollTo(0, 0);
          history.push("/pcp/signatureTGG?split_name=TGG_V1&uuid=" + uuid);
        }
      }
    }
  };

  const slideChange = (e) => {
    if (e.target.name == "carFinance") {
      setProgressBar({ status: "show", width: "50%" });
    } else if (e.target.name == "lenders") {
      setProgressBar({ status: "show", width: "80%" });
    } else {
      setProgressBar({ status: "hide", width: "0%" });
    }
    setClickedSlide({ slide: e.target.name });
  };
  const previousSlideChange = (e) => {
    window.scrollTo(0, 0);
    if (e.target.name == "lenders") {
      setProgressBar({ status: "show", width: "50%" });
    } else if (e.target.name == "commission") {
      setProgressBar({ status: "show", width: "80%" });
    } else {
      setProgressBar({ status: "hide", width: "0%" });
    }
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  return (
    <>
      <form
        ref={splitForm}
        className="main_form"
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div className="wrap larger">
          <div className="container">
            <CarFinance
              className={`${state.carFinance}`}
              setLoader1={setLoader1}
              slideChange={slideChange}
              setProgressBar={setProgressBar}
              validation={register}
            />
            <Lenders
              className={`${state.lenders}`}
              slideChange={slideChange}
              backClick={previousSlideChange}
              setProgressBar={setProgressBar}
              isLenders={isLenders}
              setIsLenders={setIsLenders}
              setLenderSelectedList={setLenderSelectedList}
              LenderValidationMsg={LenderValidationMsg}
              setLenderValidationMsg={setLenderValidationMsg}
            />
            <Commision
              className={`${state.commission}`}
              slideChange={slideChange}
              setLoader2={setLoader2}
              backClick={previousSlideChange}
              setProgressBar={setProgressBar}
              validation={register}
            />
            <PostCode
              className={`${state.postcode}`}
              slideChange={slideChange}
              backClick={previousSlideChange}
              validation={register}
              validationMsg={errors}
              splitForm={splitForm}
              trigger={trigger}
              clearErrors={clearErrors}
              setError={setError}
              getValues={getValues}
              setLoader1={setLoader1}
            />
            <Name
              className={`${state.name}`}
              backClick={previousSlideChange}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              clearErrors={clearErrors}
              setError={setError}
              getValues={getValues}
              slideChange={slideChange}
              setLoader1={setLoader1}
            />
            <DateOfBirth
              className={`${state.dateofbirth}`}
              backClick={previousSlideChange}
              slideChange={slideChange}
              textDob="Date Of Birth"
              trigger={trigger}
              setLoader1={setLoader1}
              validation={register}
              validationMsg={errors}
            />
            <ContactDetails
              className={`${state.contactdetails}`}
              backClick={previousSlideChange}
              getValues={getValues}
              trigger={trigger}
              setLoader1={setLoader1}
              validation={register}
              validationMsg={errors}
              setError={setError}
              formSubmit={formSubmit}
            />
          </div>
        </div>
      </form>
      <div className="map">
        <img src="/assets/TGG_V1/img/uk-map.svg" />
      </div>
    </>
  );
};

export default FormTGG_V1;

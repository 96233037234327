import React, { useState } from "react";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  getValues,
  setLoader1,
  formSubmit,
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const [disabled, setDisabled] = useState(false);

  const validateSlide4 = async (e) => {
    e.preventDefault();
    const values = getValues();
    var emailVal = await trigger("txtEmail");
    var phoneVal;
    if (emailVal) {
      phoneVal = await trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }

    if (emailVal && phoneVal) {
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        setDisabled(true);
        setLoader1("hide");
        formSubmit();
      }
    }
  };
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    const email = values["txtEmail"];

    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    const phone = values["txtPhone"];

    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
      }
    }
  };
  return (
    <>
      <div className={`step ${className}`} id="step_6">
        <div className="form-right bounce-y top-up d-block d-md-none">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-none fw-bold">Possible Claim Value</p>
              <h2 className="margin-none fw-bold">£5,318*</h2>
            </div>
          </div>
        </div>
        <div className="form-left">
          <p className="h5 margin-15">
            Please provide your email and phone number below so that we can keep
            you updated on your potential refund
          </p>
          <div className="field padded">
            <div className="field margin-15">
              <label className="h5 margin-none">Email address</label>
              <p className="text-size-smaller margin-10">
                Enter your current email address
              </p>
              <div className="field-icon email">
                <Email
                  name="txtEmail"
                  id="txtEmail"
                  placeholder="Email address"
                  autocomplete="off"
                  onBlur={emailValidCheck}
                  validation={validation({
                    required: "Please Enter Valid Email Address",
                  })}
                ></Email>
                {validationMsg.txtEmail && (
                  <span className="error_msg " id="email_err">
                    {validationMsg.txtEmail.message}
                  </span>
                )}
              </div>
            </div>
            <div className="field margin-15">
              <label className="h5 margin-none">Mobile telephone number</label>
              <p className="text-size-smaller margin-10">
                Enter your current mobile telephone number
              </p>
              <div className="field-icon telephone">
                <Telephone
                  name="txtPhone"
                  id="txtphone"
                  className="form-control"
                  placeholder="Phone Number"
                  autocomplete="off"
                  onBlur={phoneValidCheck}
                  onKeyPress={(e) =>
                    !/[0-9]+$/.test(e.key) && e.preventDefault()
                  }
                  maxlength="11"
                  validation={validation({
                    pattern: {
                      value: /^[0-9]*$/i,
                      message: "Please Enter Valid Phone Number",
                    },
                    required: "Please Enter Phone Number",
                  })}
                ></Telephone>
                {validationMsg.txtPhone && (
                  <span className="error_msg " id="phone_err">
                    {validationMsg.txtPhone &&
                      "Please Enter Valid Phone Number"}
                  </span>
                )}
              </div>
            </div>
            <div className="field">
              <p className="text-size-smallest margin-none">
                By clicking <strong>'Submit'</strong> you agree to the{" "}
                <a href={"/privacyTGG"} target="_blank">
                  <span className="link privacy_policy_modal">
                    Privacy policy
                  </span>
                </a>
              </p>
            </div>
          </div>
          <div className="form-navigation">
            <ul>
              <li>
                <button
                  type="button"
                  className="button submit"
                  name="contactdetails"
                  id="nextInto7"
                  disabled={disabled}
                  onClick={validateSlide4}
                >
                  Submit
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="button back"
                  name="dateofbirth"
                  id="BackInto5_1"
                  onClick={backClick}
                >
                  <img
                    src="/assets/TGG_V1/img/arrow-left.png"
                    className="px-2"
                    name="dateofbirth"
                  />{" "}
                  Back
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="form-right bounce-y top-up d-none d-md-block">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-none fw-bold">Possible Claim Value</p>
              <h2 className="margin-none fw-bold">£5,318*</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;

import React, { useRef, useState, useContext, useReducer, useEffect } from "react";
import "../../assets/TGG_V1_OLD/css/LenderTGGV1.scss";
import LenderHeader from "../Includes/Layouts/LenderTGGV1/LenderHeader";
import Testimonial from "../Includes/Layouts/TGG_V1/Testimonial.js";
import AccordSection from "../Includes/Layouts/TGG_V1/AccordSection.js";
import Footer from "../Includes/Layouts/TGG_V1/Footer";
// import FormLenderTGGV1 from "../Includes/Layouts/LenderTGGV1/FormLenderTGGV1";
import Loader from "../Includes/Layouts/LenderTGGV1/Loader";
import { useForm } from "react-hook-form";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import VisitorParamsContext from "../../Contexts/VisitorParams"
import { queryString } from "../../Utility/QueryString";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { FormData } from "../../Utility/FormData";
import { useUserInfo } from "../../Hooks/useUserInfo";
import { useHistory } from "react-router-dom";
import FormLenderTGGV1 from "../Includes/Layouts/LenderTGGV1/FormLenderTGGV1_NEW";
import {UseSaveLenders } from "../../Hooks/UseSaveLenders";
import GtmDataLayer from "../../Utility/GtmDataLayer";
const LenderTGG_V1 = () => {

    const [LoaderLender, SetLenderLodar] = useState("show");
    const [LenderSelectedList, setLenderSelectedList] = useState([]);
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    const query_string = localStorage.getItem("querystring");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { setFormData } = FormData();
    const splitForm = useRef(null);
    const [splitName, setSplitName] = useState();
    const { getUserInfo } = useUserInfo();
    const history = useHistory();
    const divFocusOne = useRef(null);
    const split_name =localStorage.getItem("split_name");
    const {saveLender} =UseSaveLenders();
    const [userLenderSelected,setUserSelected]=useState([]);
    useEffect(() => {
        const timer = setTimeout(() => {
            SetLenderLodar("hide");
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            scrollToDivWithDelay();
        }, 3000);
    }, []);

    const scrollToDivWithDelay = () => {
        setTimeout(() => {
            if (divFocusOne.current) {
                divFocusOne.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 2000);
    };

    const storeQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            query_string,
            formdata
        );
    };

    const getUuid = () => {
        const visitorData = visitorParameters.visitor_parameters;
        if (visitorData != "") {
            var uuid = visitorData.uuid;
        } else {
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem("uuid");
            var uuid = query_uuid ? query_uuid : local_storage_uuid;
        }
        return uuid;
    };
    const uuid = getUuid();

    useEffect(() => {
        if (formdata === undefined || formdata === null || formdata == "") {
            (async () => {
                const response = await getUserInfo(uuid);
                const set_formdata = setFormData(response);
                let dataLength = response.data.response.length;
                if (dataLength > 0) {
                    const pageName = response.data.response[0].page_name
                        ? response.data.response[0].page_name
                        : " ";
                    setSplitName(pageName);
                }
            })();
        } else {
            const pageName = formdata.page_name;
            setSplitName(pageName);
        }
    }, [splitName]);


    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    const SaveLenderFn = async (e) => {
        const responseSaveLender = await saveLender(
            uuid,
            LenderSelectedList
          )
          if (responseSaveLender.data.status === "Success") {
            GtmDataLayer({
                question: "select lender",
              });
            history.push("/register-car-v1?uuid=" + uuid);
        }
        // history.push("/register-car-v1?uuid=" + uuid);

    };

  

    return (
        <>
            <GetVisitorsParams />
            <div className="LenderTGGV1">
                <LenderHeader />
                <section className="form">
                    <form
                        ref={splitForm}
                        name="split_form"
                        id="user_form"
                        autoComplete="off"
                        className="main_form"
                    >
                        <div className="wrap larger" ref={divFocusOne}>
                            <div className="container">
                                <div className="step start" id="step_lender">
                                    <div className="form-right bounce-y top-up d-block d-md-none">
                                        <div className="badge">
                                            <div className="content">
                                                <p className="fs-6 mb-0 text-weight-600 d-flex justify-content-center text-start">
                                                    <img src="/assets/TGG_V1/img/information-button.png" alt
                                                        className="w-auto mt-1" />&nbsp; Additional information required to
                                                </p>
                                                <p className="fs-6 margin-5 text-weight-600 d-flex justify-content-center  text-start">
                                                    <strong>expedite</strong> &nbsp;your claim process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <FormLenderTGGV1
                                        setLenderSelectedList={setLenderSelectedList}
                                        LenderSelectedList={LenderSelectedList}
                                        SaveLenderFn={SaveLenderFn}
                                        divFocusOne={divFocusOne}
                                        uuid={uuid}
                                        split_name={split_name}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="map">
                        <img src="/assets/TGG_V1/img/uk-map.svg" alt />
                    </div>
                    <div className="confetti-pop">
                        <canvas id="congratulations_confetti" />
                    </div>
                </section>
                <Testimonial />
                <AccordSection />
                <Footer />
                <Loader LoaderLender={LoaderLender} />
            </div>
        </>
    );
};
export default LenderTGG_V1;
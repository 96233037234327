import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

// Install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const HomeTestimonials = () => {
  return (
    <>
    <section className="home-testimonials pt-lg-5 pt-md-5 pt-sm-4 pt-4">
      <div className="container">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="testimonial-wrap">
              <div className="testimonial drop-in-3">
                <strong>"Simple, easy and painless"</strong>
                <div className="d-flex">
                  <div className="pe-2">
                    <img height="20" src="../../assets/TGG_V1_B/img/starts05.jpg" alt="Rating" />
                  </div>
                  <div className="mb-2">
                    <img height="30" className="rounded" src="../../assets/TGG_V1_B/img/review001.jpg" alt="Reviewer" />
                  </div>
                </div>
                <p>Coutmacs service has been first rate, they perused my claim over many months and obviously put in a lot of hard work, eventually referring my claim to the ombudsman after the finance company rejected it out of hand. The ombudsman has ruled in my favour and I could not have done it without Courmacs and particularly my case handler who I cannot praise enough.</p>
              </div>
              <cite className="testimonial-author drop-in-3">
                <img src="../../assets/TGG_V1_B/img/user-avatar.png" alt="User Avatar" className="rounded-circle me-3" />
                <span className="name">
                  <strong>Miss selling claim</strong>
                  <div className="mt-2">May 14, 2024</div>
                </span>
              </cite>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-wrap">
              <div className="testimonial drop-in-3">
                <strong>Extremely helpful</strong>
                <div className="d-flex">
                  <div className="pe-2">
                    <img height="20" src="../../assets/TGG_V1_B/img/starts05.jpg" alt="Rating" />
                  </div>
                  <div className="mb-2">
                    <img height="30" className="rounded" src="../../assets/TGG_V1_B/img/review001.jpg" alt="Reviewer" />
                  </div>
                </div>
                <p>Eleanor was very supportive during preparation of my case. Beforehand, I was very nervous about the prospect of going to court but Eleanor talked me through the case in depth and gave me lots of advice. I felt much more confident after our preparation session. She was extremely knowledgeable and helpful.</p>
              </div>
              <cite className="testimonial-author drop-in-3">
                <img src="../../assets/TGG_V1_B/img/user-avatar.png" alt="User Avatar" className="rounded-circle me-3" />
                <span className="name">
                  <strong>Lauren Watmough</strong>
                  <div className="mt-2">April 11, 2024</div>
                </span>
              </cite>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-wrap">
              <div className="testimonial drop-in-3">
                <strong>Excellent service</strong>
                <div className="d-flex">
                  <div className="pe-2">
                    <img height="20" src="../../assets/TGG_V1_B/img/starts05.jpg" alt="Rating" />
                  </div>
                  <div className="mb-2">
                    <img height="30" className="rounded" src="../../assets/TGG_V1_B/img/review001.jpg" alt="Reviewer" />
                  </div>
                </div>
                <p>Very professional, speedy and attentive law firm</p>
              </div>
              <cite className="testimonial-author drop-in-3">
                <img src="../../assets/TGG_V1_B/img/user-avatar.png" alt="User Avatar" className="rounded-circle me-3" />
                <span className="name">
                  <strong>Michael</strong>
                  <div className="mt-2">May 10, 2024</div>
                </span>
              </cite>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-wrap">
              <div className="testimonial drop-in-3">
                <strong>Excellent service.</strong>
                <div className="d-flex">
                  <div className="pe-2">
                    <img height="20" src="../../assets/TGG_V1_B/img/starts05.jpg" alt="Rating" />
                  </div>
                  <div className="mb-2">
                    <img height="30" className="rounded" src="../../assets/TGG_V1_B/img/review001.jpg" alt="Reviewer" />
                  </div>
                </div>
                <p>Courmacs Solicitors was very efficient, professional, and reliable. I would recommend them to anyone who needs legal help.</p>
              </div>
              <cite className="testimonial-author drop-in-3">
                <img src="../../assets/TGG_V1_B/img/user-avatar.png" alt="User Avatar" className="rounded-circle me-3" />
                <span className="name">
                  <strong>Janet Erdwin</strong>
                  <div className="mt-2">March 25, 2024</div>
                </span>
              </cite>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-wrap">
              <div className="testimonial drop-in-3">
                <strong>claim</strong>
                <div className="d-flex">
                  <div className="pe-2">
                    <img height="20" src="../../assets/TGG_V1_B/img/starts05.jpg" alt="Rating" />
                  </div>
                  <div className="mb-2">
                    <img height="30" className="rounded" src="../../assets/TGG_V1_B/img/review001.jpg" alt="Reviewer" />
                  </div>
                </div>
                <p>Excellent service. Take calls quickly and put you through to the person dealing with your case. Emails received and easy to download. Very happy with the service.</p>
              </div>
              <cite className="testimonial-author drop-in-3">
                <img src="../../assets/TGG_V1_B/img/user-avatar.png" alt="User Avatar" className="rounded-circle me-3" />
                <span className="name">
                  <strong>Jane Turner</strong>
                  <div className="mt-2">March 20, 2024</div>
                </span>
              </cite>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
    <section className="welcome-highlights py-lg-5 py-md-0 pb-lg-4 pb-md-5 pb-sm-5 pb-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
          <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
            <img src="../../assets/TGG_V1_B/img/icon01.svg" alt="" />
            <h2 className="mt-3">
              {" "}
              Get your Mis-sold Loan Fees and Charges back
            </h2>
            <p>
              Many loans were not sold properly. You could claim back
              the fees and charges plus 8% interest.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
          <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
            <img src="../../assets/TGG_V1_B/img/icon02.svg" alt="" />
            <h2 className="mt-3">
              Your Claim Fought by Financial & Legal Experts
            </h2>
            <p>
              We're ahead of the field, drawing on years of financial
              services and legal expertise for a strong claim
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
          <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
            <img src="../../assets/TGG_V1_B/img/icon03.svg" alt="" />
            <h2 className="mt-3">
              Respecting Your Information Security
            </h2>
            <p>
              We take security very seriously and follow the rules of
              the Data Protection Act
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
    </>
  );
};

export default HomeTestimonials;

export const Lender = [
  { id:1, label:"Lender-1", value:1},
  { id:2, label:"Lender-2", value:2},
  { id:3, label:"Lender-3", value:3},
  { id:4, label:"Lender-4", value:4},
  { id:5, label:"Lender-5", value:5},
  { id:6, label:"Lender-6", value:6},
  { id:7, label:"Lender-7", value:7}
];

export const LenderList={
  data:{
      response:{
          lenders:[
              {id:3, lenderName:"BLACK HORSE", value:"BLACK HORSE"},
              {id:5, lenderName:"CLOSE BROTHERS", value:"CLOSE BROTHERS"},
              {id:8, lenderName:"MOTONOVO", value:"MOTONOVO"},
              {id:10, lenderName:"VOLKSWAGEN FINANCE", value:"VOLKSWAGEN FINANCE"},
              
          ],
          additional_lenders:[
              {id:1, lenderName:"AUDI FINANCE", value:"AUDI FINANCE"},
              {id:2, lenderName:"BARCLAYS PARTNER FINANCE", value:"BARCLAYS PARTNER FINANCE"},
              {id:4, lenderName:"BMW FINANCE", value:"BMW FINANCE"},
              {id:7, lenderName:"MERCEDES FINANCE", value:"MERCEDES FINANCE"},
              {id:33, lenderName:"VAUXHALL FINANCIAL SERVICES (GMAC)", value:"VAUXHALL FINANCIAL SERVICES (GMAC)"},
              {id:6, lenderName:"FORD CREDIT (FCE Bank)", value:"FORD CREDIT (FCE Bank)"},
              {id:9, lenderName:"SANTANDER", value:"SANTANDER"},
              {id:11, lenderName:"ALPHERA FINANCIAL SERVICES", value:"ALPHERA FINANCIAL SERVICES"},
          ],
          other_lenders:[
            {id:35, lenderName:"ALDERMORE", value:"ALDERMORE BANK PLC"},
            {id:12, lenderName:"BENTLEY (VW Finance)", value:"BENTLEY (VW Finance)"},
            {id:13, lenderName:"BLUE MOTOR FINANCE", value:"BLUE MOTOR FINANCE"},
            {id:36, lenderName:"CA AUTO FINANCE", value:"CA AUTO FINANCE UK LTD"},
            {id:14, lenderName:"CITROEN (PSA Finance)", value:"CITROEN (PSA Finance)"},
            {id:15, lenderName:"CLYDESDALE FINANCIAL SERVICES", value:"CLYDESDALE FINANCIAL SERVICES"},
            {id:16, lenderName:"CUPRA (VW Finance)", value:"CUPRA (VW Finance)"},
            {id:17, lenderName:"DS AUTOMOBILES (PSA Finance)", value:"DS AUTOMOBILES (PSA Finance)"},
            {id:18, lenderName:"DUCATI", value:"DUCATI"},
            {id:19, lenderName:"FIRSTRAND BANK", value:"FIRSTRAND BANK"},
            {id:20, lenderName:"GENESIS (Hyundai Capital)", value:"GENESIS (Hyundai Capital)"},
            {id:37, lenderName:"HONDA FINANCE", value:"HONDA FINANCE"},
            {id:21, lenderName:"HYUNDAI CAPITAL", value:"HYUNDAI CAPITAL"},
            {id:22, lenderName:"LAMBORGHINI (VW Finance)", value:"LAMBORGHINI (VW Finance)"},
            {id:23, lenderName:"LAND ROVER FINANCE", value:"LAND ROVER FINANCE"},
            {id:24, lenderName:"MAZDA (TOYOTA FINANCE)", value:"MAZDA (TOYOTA FINANCE)"},
            {id:25, lenderName:"NISSAN FINANCE", value:"NISSAN FINANCE"},
            {id:26, lenderName:"PEUGEOT (PSA Finance)", value:"PEUGEOT (PSA Finance)"},
            {id:27, lenderName:"PORSCHE (VW Finance)", value:"PORSCHE (VW Finance)"},
            {id:28, lenderName:"RENAULT FINANCE", value:"RENAULT FINANCE"},
            {id:29, lenderName:"SEAT FINANCE (VW Finance)", value:"SEAT FINANCE (VW Finance)"},
            {id:38, lenderName:"SHOGUN FINANCE", value:"SHOGUN FINANCE"},
            {id:30, lenderName:"SKODA (VW Finance)", value:"SKODA (VW Finance)"},
            {id:31, lenderName:"STELLANTIS FINANCIAL SERVICES", value:"STELLANTIS FINANCIAL SERVICES"},
            {id:32, lenderName:"TOYOTA", value:"TOYOTA"},
            {id:34, lenderName:"VOLVO", value:"VOLVO"},

          ]
        }
      }
    }
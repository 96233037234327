import loaderSvg from "../../../../assets/img/loader03.svg";

export default function Loader() {
  return (
    <div className="loader-div loaderresult loader-fix z-3">
      <div className="text-center">
        <img height={110} src={loaderSvg} alt="loader" />
        {/* <h4> Analysing the information you have provided.</h4> */}
      </div>
    </div>
  );
}

import { useEffect } from "react";


import successTick from "../../assets/TGG_V2/img/success-tick.gif";


import Footer from "../Includes/Layouts/TGG_V2/Footer";
import Header from "../Includes/Layouts/TGG_V2/Header";

export default function ThankyouV2() {

  localStorage.clear();
  useEffect(() => {
      setTimeout(() => {
          window.scrollTo(0, 0);
      }, 500);
  }, []);


  return (
    <div className="IdUploadTGGV2">
      <div className="__PCPtcg">
        <div className="bg_lol">
          <Header />
          <section className="cs_hero cs_style_1 sec_design section_design1">
            <div className="cs_hero_wrap cs_bg_filed">
              {/*Gradient*/}
              <div className="container-md">
                <div className=" success-box col-lg-6 col-md-8 offset-lg-3 offset-md-2 rounded-3 shadow-md  text-center">
                  <div>
                    <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                      <img height={130} src={successTick} alt="successTick" />
                    </div>
                    <div>
                      <h2> Thank You</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />

      </div>
    </div>
  );
}

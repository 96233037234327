import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
} from "react";
import { useLenderList } from "../../../../Hooks/LendersLists";
import GtmDataLayer from "../../../../Utility/GtmDataLayer"; 
import Select from "react-select";

const Lenders = ({ setDivShow,setLenderSelectedList,lenderRef }) => {
  const [lendersloop, setLendersloop] = useState({});
  const [lendersTop, setLendersTop] = useState({});
  const [lendersAddloop, setAddLendersloop] = useState({});
  const { lenderList } = useLenderList();
  const [LenderSelectedValue, setLenderSelectedValue] = useState([]);
  const [selectedAddOptions, setSelectedAddOptions] = useState([]);
  const [additionalLenders, setAdditionalLenders] = useState();
  const [isLenders, setIsLenders] = useState(false);
  const [LenderValidationMsg, setLenderValidationMsg] = useState();
  const [otherLenders, setOtherLenders] = useState("hide");
  const [isChecked, setIsChecked] = useState(false);
  

  const lenderPopup = (display) => {
    if(display == "show"){
      setLenderValidationMsg(false);
    }
    setIsChecked(false);
    setOtherLenders(display);
  };

  const slideChange = async (e) => {
    if (isLenders && LenderSelectedValue.length >= 1) {
      setLenderValidationMsg(false);
      window.scrollTo(0, 0);
      localStorage.setItem("Lenders", JSON.stringify(LenderSelectedValue));
      setLenderSelectedList(LenderSelectedValue);
      let lendersArray = lendersloop.lenders || [];
      let additionalLendersArray = lendersTop.lenders || [];
      let combinedLenders = lendersArray.concat(additionalLendersArray);

      let selectedIds = LenderSelectedValue.map((lender) => lender.lenderName);

      let unselectedlenders = combinedLenders.filter(
        (lender) => !selectedIds.includes(lender.lenderName)
      );
      localStorage.setItem(
        "UnselectedLender",
        JSON.stringify(unselectedlenders)
      );
      lenderPopup('hide');
      setDivShow("is-open");
      GtmDataLayer({
        question: "which lender",
      });
    } else {
      lenderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setIsLenders(false);
      setDivShow("hide");
      setLenderValidationMsg("Please select at least one lender");
      return false;
    }
  };
  const onSelectTopLender = (e) => {
    const { id, name, value, checked } = e.target;
    if (checked) {
      setIsLenders(true);
      setLenderValidationMsg(false);
      setLenderSelectedValue((prevSelected) => [
        ...prevSelected,
        {
          id: id,
          lenderName: name,
          lender_value: value,
        },
      ]);
    } else {
      setLenderSelectedValue((prevSelected) =>
        prevSelected.filter((lender) => lender.id !== id)
      );
      setSelectedAddOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === id ? { ...option, label: name } : option
        )
      );
    }
  };

  const handleSelectChange = (selectedOptions) => {
    if (
      !selectedAddOptions.some((option) => option.id === selectedOptions.id)
    ) {
      setSelectedAddOptions([...selectedAddOptions, selectedOptions]);
    }
  };
  // passing values to first checkbox
  let lendersOption;
  useEffect(() => {
    (async () => {
      const lenders = await lenderList();
      setLendersloop({ lenders: lenders.data.response.lenders });
    })();
  }, []);

  if (lendersloop.lenders) {
    lendersOption = lendersloop.lenders.map((LenderData, index) => {
      return (
        <div className="col-lg-12 mb-2 text-center" key={index}>
          <div className="selectoptions2">
            <div>
                <input 
                type="checkbox"
                className={`custom-checkbox`}
                onChange={onSelectTopLender}
                name={`${LenderData.lenderName}`}
                id={`${LenderData.id}`}
                value={LenderData.value}
                />
                <label htmlFor={LenderData.id} className="selected-false"><span className="option_title title_1">{LenderData.lenderName}</span></label>
            </div>
            </div>
        </div>
      );
    });
  }

  let AdditionalOption;
  useEffect(() => {
    (async () => {
      const lenders = await lenderList();
      setLendersTop({ lenders: lenders.data.response.additional_lenders });
    })();
  }, []);

  if (lendersTop.lenders) {
    AdditionalOption = lendersTop.lenders.map(
      (LenderData, index) => {
        return (
          <div className="mb-2" key={index}>
            <input
              type="checkbox"
              className={`custom-checkbox`}
              onChange={onSelectTopLender}
              name={`${LenderData.lenderName}`}
              id={`${LenderData.id}`}
              value={LenderData.value}
            />
            <label htmlFor={LenderData.id} className="selected-false">
              <span className="option_title title_1">{LenderData.lenderName}</span>
            </label>
          </div>
        );
      }
    );
  }

  // passing value to selectbox
  let Additionallendersoption;
  useEffect(() => {
    (async () => {
      const lenders = await lenderList();
      setAddLendersloop({
        other_lenders: lenders.data.response.other_lenders,
      });
    })();
  }, []);
  if (lendersAddloop.other_lenders) {
    Additionallendersoption = lendersAddloop.other_lenders.map(
      (LenderData, index) => ({
        id: LenderData.id,
        value: LenderData.value,
        label: LenderData.lenderName,
      })
    );
  }
  // passing value from selectbox to second checkbox
  useEffect(() => {
    const Additionallendersoptiondis = selectedAddOptions.map(
      (LenderData, index) => (
        <div className="mb-2" key={index}>
          <input
            type="checkbox"
            className={`custom-checkbox`}
            onChange={onSelectTopLender}
            name={`${LenderData.label}`}
            id={`${LenderData.id}`}
            value={LenderData.value}
          />
          <label htmlFor={`${LenderData.id}`} className="selected-false">{LenderData.label}</label>
        </div>
      )
    );
    setAdditionalLenders(Additionallendersoptiondis);
  }, [selectedAddOptions]);
  return (
    <>
      <fieldset className="ques1 drop-in-2">
        <legend className="drop-in-2 tex-lg-start text-md-start text-sm-center text-center">
          Which lender(s) did not tell you they would receive a commission
          against the loan?
        </legend>
        <div className="row">
          {lendersOption}
          <div className="col-lg-12 mb-2 text-center">
          <div className="selectoptions2">
            <div>
                <input 
                type="checkbox"
                className={`custom-checkbox`}
                onChange={() => lenderPopup("show")}
                checked={isChecked}
                name="otherLender"
                id="other"
                value="0"
                />
                <label htmlFor="other" className="selected-false"><span className="option_title title_1">OTHER</span></label>
            </div>
            </div>
          </div>
          <span className={`error_msg mb-2 ${otherLenders === 'show' ? 'hide' : 'show'}`}>{LenderValidationMsg}</span>
        </div>
        <p className="margin-none my-4 text-end">
          <button type="button" className="blue-btn p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky pulse2" onClick={slideChange}>
            <span>
              {" "}
              NEXT
              {" "}
              <img src="../../assets/TGG_V1/img/arrow-right.png" alt="" />
            </span>
          </button>
        </p>
      </fieldset>

      <div
        className={`modal fade ${otherLenders} overlay-style`}
        id="lenderPop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg poplender">
          <div className="modal-content">
            <div className="modal-header  align-items-start">
              <div>
                <h2>
                  Select the lender(s) that you used to finance your vehicle(s):
                </h2>
                <p className="sub-text mb-0">Select all that apply below</p>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => lenderPopup("hide")}
              ></button>
            </div>
            <div className="modal-body">
              <div className="clearfix  mb-3">
                <div className="selectoptions">{AdditionalOption}</div>
              </div>
              <div className="mt-2">
                <h6>Others</h6>

                <div className="mb-4">
                <Select
                  className={`additional_lender`}
                  styles={{height:"80px"}}
                  placeholder="Select Lender Name"
                  options={Additionallendersoption}
                  name={lendersAddloop}
                  onChange={handleSelectChange}
                  isSearchable={true}
                />
                </div>
              </div>
              <div className="selectoptions">{additionalLenders}</div>

              <div>
              {LenderValidationMsg ? (
            <span className={`error_msg mb-2 ${otherLenders === 'show' ? 'show' : 'hide'}`}>{LenderValidationMsg}</span>
              ) : (
                ""
              )}
                <button
                  className="blue-btn p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mt-3 py-3 fs-5 fw-bold"
                  id=" "
                  name="formSubmitBtn"
                  type="button"
                  onClick={slideChange}
                >
                  <span>START</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lenders;

import {Api} from '../api/Api';
import {useErrorWebhook} from "./useErrorWebhook";

export const useCarRegisterValidation = () => {
    const { ErrorWebhookTrigger } = useErrorWebhook();

    const registrationvalidation = async (carreg,uuid,page_name) => {
        const getValidation = await Api.get(
            "/v1/carno-validation?carRegNo=" + carreg+"&uuid=" +uuid+"&page_name="+page_name,
            {}
          ).catch(function (error) {
            const erroMessage = {
              serviceName : 'CAR NO REGISTRATION VALIDATION',
              errorReason:error.message,
              errorCode:error.code,
              uuid:uuid
            }
            ErrorWebhookTrigger(erroMessage);
          });
        return getValidation;
    }
    return { registrationvalidation }
}
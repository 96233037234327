import React from "react";
import "../../../assets/TGG_V1_OLD/css/newTGG.scss";

const CookiePolicy = () => {
  return (
    <>
      <div className="doc_sections">
        <section className="doc_sections">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 py-5 offset-lg-1">
                <div className="doc-head">
                  <h2 className="text-center animate__animated animate__fadeInUp animate__delay-.5s">
                    COOKIES POLICY
                  </h2>
                  <h1 className="text-center doc-head mb-lg-5 mb-md-5 mb-sm-4 mb-4 animate__animated animate__fadeInUp animate__delay-.5s">
                    COOKIES POLICY
                  </h1>
                </div>
                <div className="elementor-widget-container">
                  <p>
                    Cookies are small text files which are transferred from our
                    websites or services, and stored on your device. We use
                    cookies to help us provide you with a personalised service,
                    and to help make our websites and services better for
                    you.Our cookies may be session cookies (temporary cookies
                    that identify and track users within our websites or
                    services that are deleted when you close your browser or
                    leave your session in the application or service) or
                    persistent cookies (cookies which enable our websites or
                    services to “remember” who you are and to remember your
                    preferences within our websites or services and that will
                    stay on your computer or device after you close your browser
                    or leave your session in the application or service).
                  </p>
                  <p>We use the following different types of cookies:</p>
                  <h4>Strictly Necessary Cookies</h4>
                  <p>
                    These cookies are necessary for the website to function and
                    cannot be switched off in our systems. They are set in
                    response to actions made by the user in the website and are
                    required to identify irregular site behavior, prevent
                    fraudulent activity, and improve security.
                  </p>
                  <p>
                    You can set your browser to block or alert about these
                    cookies, but some parts of the site will then not work.
                    These cookies do not store any personally identifiable
                    information.
                  </p>

                  <h4>Performance cookies and analytics technologies</h4>

                  <p>
                    These cookies collect information about how visitors and
                    users use our websites and services, for instance which
                    functionality visitors use most often, and if they get error
                    messages from areas of the websites or services. These
                    cookies do not collect information that identifies a visitor
                    or user. All information these cookies collect is aggregated
                    and therefore anonymous. We only use these cookies to
                    improve how our websites and services work.
                  </p>

                  <p>
                    We use third parties like Google Analytics for this purpose,
                    for further information, please visit their privacy policy:
                    <a
                      href="https://policies.google.com/privacy?hl=en&gl=ZZ"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      https://policies.google.com/privacy
                    </a>
                  </p>

                  <h4>Functionality cookies</h4>

                  <p>
                    These cookies allow our websites and services to remember
                    choices you make (such as your user name, language, or the
                    region you are in) and provide enhanced, more personal
                    features. These cookies can also be used to remember changes
                    you have made to text size, fonts, and other parts of web
                    pages that you can customise. They may also be used to
                    provide services you have asked for. The information these
                    cookies collect may be anonymised and they cannot track your
                    browsing activity on other websites.
                  </p>

                  <h4>Targeting or advertising cookies</h4>
                  <p>
                    These cookies are used to deliver adverts more relevant to
                    you and your interests. They are also used to limit the
                    number of times you see an advertisement, as well as help
                    measure the effectiveness of the advertising campaign. They
                    are usually placed by advertising networks with the website
                    operator’s permission. They remember that you have visited a
                    website and this information is shared with other
                    organisations such as advertisers. Quite often, targeting or
                    advertising cookies will be linked to site functionality
                    provided by the other organisation.
                  </p>

                  <p>
                    For further details in how these networks process personal
                    data, please find a link to their respective privacy
                    policies. You can also manage your cookies settings and
                    automatically disable targeting or advertising cookies at
                    the bottom of this page
                  </p>
                  <p>
                    Facebook Advertising:{" "}
                    <a
                      href="https://www.facebook.com/privacy/explanation"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      https://www.facebook.com/privacy/explanation
                    </a>{" "}
                    or visit{" "}
                    <a
                      href="https://www.facebook.com/help/610457675797481/?helpref=hc_fnav"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      https://www.facebook.com/help/610457675797481/?helpref=hc_fnav
                    </a>{" "}
                    to see how your data is used on Facebook Ads. Google Ads:{" "}
                    <a
                      href="https://policies.google.com/privacy?hl=en-GB"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      https://policies.google.com/privacy?hl=en-GB
                    </a>{" "}
                    or visit{" "}
                    <a
                      href="https://adssettings.google.com/authenticated"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      https://adssettings.google.com/authenticated
                    </a>{" "}
                    to control ad personalisation on other websites and apps
                    that use Google ad services. Bing Ads:{" "}
                    <a
                      href="https://privacy.microsoft.com/da-dk/privacystatement"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      privacy.microsoft.com/da-dk/privacystatement
                    </a>{" "}
                    LinkedIn Ads:{" "}
                    <a
                      href="https://linkedin.com/legal/privacy-policy"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      www.linkedin.com/legal/privacy-policy
                    </a>{" "}
                    Twitter Ads:{" "}
                    <a
                      href="https://Twitter.com/privacy"
                      target="_blank"
                      style={{ textDecoration: 'none', color: 'black' }}
                      rel="noopener"
                    >
                      Twitter.com/privacy
                    </a>
                  </p>
                  <h4>Web beacons and parameter tracking</h4>
                  <p>
                    We also use cookies and similar software known as web
                    beacons to count users who have visited our website after
                    clicking through from one of our advertisements on another
                    website or in emails, and to collect details of any products
                    or services purchased. These web beacons collect limited
                    information that does not identify particular individuals.
                    It is not possible to refuse the use of web beacons.
                    However, because they are used in conjunction with cookies,
                    you can effectively disable them by setting your browser to
                    restrict or block cookies.
                  </p>
                  <h4>Third party website widgets</h4>
                  <p>
                    We use a third party review provider, Trustpilot, to receive
                    and publish reviews about our services. If you make a review
                    about our website, this will be published on
                    www.trustpilot.com and also may be published on our website
                    as well as in Google Search Results. For further details,
                    please see Trustpilot privacy notice:
                    legal.trustpilot.com/end-user-privacy-terms
                  </p>
                  <h4>IP Address and traffic data</h4>
                  <p>
                    We keep a record of traffic data which is logged
                    automatically by our servers, such as your Internet Protocol
                    (IP) address, device information, the website that you
                    visited before ours, and the website you visit after leaving
                    our site. We also collect some site, application, and
                    service statistics such as access rates, page hits, and page
                    views. We are not able to identify an individual user from
                    traffic data or site statistics.
                  </p>
                  <h4>How to disable cookies</h4>
                  <p>
                    You may be able to configure your browser or our website or
                    service to restrict cookies or block all cookies if you
                    wish, however if you disable cookies you may find this
                    affects your ability to use certain parts of our websites or
                    services. For more information about cookies and
                    instructions on how to disable cookies in your browser:
                  </p>
                  <p>
                    Internet Explorer:{" "}
                    <a
                      style={{ textDecoration: 'none'}}
                      href="https://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies"
                    >
                      windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies
                    </a>{" "}
                  </p>
                  <p>
                    Firefox:{" "}
                    <a
                      style={{ textDecoration: 'none'}}
                      href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                    >
                      support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                    </a>{" "}
                  </p>
                  <p>
                    Google Chrome:{" "}
                    <a
                      style={{ textDecoration: 'none'}}
                      href="https://support.google.com/chrome/answer/95647?hl=en"
                    >
                      support.google.com/chrome/answer/95647?hl=en
                    </a>{" "}
                  </p>
                  <p>
                    Safari:{" "}
                    <a
                      style={{ textDecoration: 'none'}}
                      href="https://help.apple.com/safari/mac/8.0/#/sfri11471"
                    >
                      help.apple.com/safari/mac/8.0/#/sfri11471
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CookiePolicy;

import React, { useEffect } from 'react';
import "../../assets/TGG_V1_OLD/ThankYouTGG/css/thankyou.scss";
import "../../assets/TGG_V1_OLD/ThankYouTGG/css/thankyou_custom.scss";
import Footer from '../Includes/Layouts/TGG_V1_OLD/Footer';
import LogoBasedOnDomain from '../Includes/Common/LogoBasedOnDomain';

const ThankYouTGG = () => {
  localStorage.clear();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    }, []);


    
    const preventBackNavigation = () => {
      window.history.pushState(null, null, window.location.pathname);
    };
    
    useEffect(() => {
      preventBackNavigation();
      window.addEventListener('popstate', preventBackNavigation);
    
      return () => {
        window.removeEventListener('popstate', preventBackNavigation);
      };
    }, []);
    
    
    return (
        <>
            <div className='ThankyouPage'>
                <header className=" thankyou-head" >
                    <div className="container pt-3">
                        <div className="row">
                            <div className="col-lg-12 col-12 text-center">
                                <LogoBasedOnDomain style={{ width: 200 }} />
                            </div>
                        </div>
                    </div>
                </header>

                <section className="thankyou">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10  col-12  offset-lg-1 ">
                                <div className="thankyou_div">
                                    <div className="main-container pt-3 d-flex justify-content-center">
                                        <div className="check-container">
                                            <div className="check-background">
                                                <svg
                                                    viewBox="0 0 65 51"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M7 25L27.3077 44L58.5 7"
                                                        stroke="white"
                                                        strokeWidth={13}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="check-shadow" />
                                        </div>
                                    </div>
                                    <h2 className="cs_hero_title text-center animate__animated animate__fadeInUp animate__delay-1s">
                                        Thank you for completing our claim enquiry form.
                                    </h2>
                                    <h3
                                        className="text-center mt-3 w-100 lit_sm animate__animated animate__fadeInUp animate__delay-.5s"
                                        style={{ maxWidth: "unset !important" }}
                                    >
                                        Our team will now review the information you have provided and
                                        will contact you to finalise your enquiry.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map">
                        <img src="/assets/TGG_V1/img/uk-map.svg" alt="" />
                    </div>
                </section>

                {/* <footer>
    <div className="wrap larger text-size-smallest">
      <div className="container">
        <div className="logo smaller">
          <img src="/assets/TGG_V1/img/logo.png" alt="" />
        </div>
        <div className="content">
          <p className="margin-5">
            © <span className="current_year">2024</span> louisastips.co.uk. This
            site is owned and operated by Fountain Finances Limited which is
            registered in England and Wales — Registered number: 08069774 —
            Registered office: 1D MacLaren House, Talbot Road, Old Trafford, M32
            0FP. Fountain Finances Ltd is authorised and regulated by the
            Financial Conduct Authority in respect of regulated claims
            management activity — FRN: 836354 — Information Commissioners Office
            registration number ZA465505 — VAT No: 250 2839 19
          </p>
          <p className="margin-5">
            Fountain Finances is a claims management company (CMC). Fountain
            Finances will undertake a free, no obligation check to assess if you
            may have a PCP claim. Once we have completed your free check, upon
            your consent, we will pass your case to one of our legal partner
            firms. We will be paid a fee for referring your case to them as part
            of an ongoing marketing arrangement
          </p>
          <p className="margin-5">
            * This figure is our average claim value as of 29/05/2024
          </p>
          <p className="margin-5">
            ^ This information has been sourced from the findings of the
            Financial Conduct Authority's (FCA) comprehensive review of the
            motor finance sector
          </p>
          <nav>
            <ul>
              <li>
                <a href="website-terms.html" target="_blank">
                  {" "}
                  Terms &amp; conditions
                </a>
              </li>
              <li>
                <a href="complaints-policy.html" target="_blank">
                  Complaints procedure
                </a>
              </li>
              <li>
                <a href="privacy-policy.html" target="_blank">
                  Privacy policy
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
                    </footer> */}

                <div className='thankyou'>
                    <Footer />
                </div>
            </div>

        </>

    )
}

export default ThankYouTGG
import React, { useState, useEffect, useRef, useContext } from "react";
import TextField2 from "../../UI/TextField2";
import Modal from "react-bootstrap/Modal";
import { useSkippedLeads } from "../../../Hooks/useSkippedLeads";
import { useHistory } from "react-router-dom";
import { useCarRegisterValidation } from "../../../Hooks/useCarRegisterValidation";
import { UseSaveCarDetails } from "../../../Hooks/UseSaveCarDetails";
import tick from "../../../assets/TGG_V1_OLD/img/tick.png";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import GtmDataLayer from "../../../Utility/GtmDataLayer";

const FormCarRegistration = ({ validation,
    validationMsg,
    trigger,
    getValues,
    setError,
    uuid,
    split_name
}) => {
    const [VeichleModal, SetVeichleAddModal] = useState(false);
    const [textInputValues, setTextInputValues] = useState({});
    const [validationMessages, setValidationMessages] = useState({});
    const [buttonVisibility, setButtonVisibility] = useState({});
    const [lenderData, setLenderData] = useState([]);
    const inputRefs = useRef({});
    const [buttonClickedStatus, setButtonClickedStatus] = useState({});
    const [ErrorMessageBtn, setErrorMsgBtn] = useState("");
    const { skippedLeads } = useSkippedLeads();
    const history = useHistory();
    const { registrationvalidation } = useCarRegisterValidation();
    const { SaveCarDetails } = UseSaveCarDetails();
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const query_string = localStorage.getItem("querystring");
    const { visitorParameters } = useContext(VisitorParamsContext);



    useEffect(() => {
        const storedLenders = JSON.parse(localStorage.getItem('Lenders')) || [];
        const initialTextValues = {};
        const initialValidationMessages = {};
        const initialButtonVisibility = {};
        const initialLenderData = [];

        console.log("storedLenders", storedLenders);

        storedLenders.forEach(({ id, lenderName, lender_value }) => {
            initialTextValues[lenderName] = '';
            initialValidationMessages[lenderName] = '';
            initialButtonVisibility[lenderName] = true;
            initialLenderData.push({ id, lenderName, lender_value });
            inputRefs.current[lenderName] = React.createRef();

        });
        setTextInputValues(initialTextValues);
        setValidationMessages(initialValidationMessages);
        setButtonClickedStatus(initialButtonVisibility);
        setLenderData(initialLenderData);

    }, []);

    const handleTextInputChange = (event) => {
        const { name, value } = event.target;
        setTextInputValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };


    const validateField = (name, value) => {
        const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/;
        if (value.trim() === '') {
            return 'Please Enter Vehicle Registration';
        } else if (value.trim().length < 3) {
            return 'Please Enter Valid Vehicle Registration';
        } else if (!pattern.test(value)) {
            return 'Your Vehicle Registration is Invalid. Please Recheck';
        }
        // setValidationMessages("");
        setValidationMessages(prevMessages => ({
            ...prevMessages,
            [name]: false
        }));
        return;
    };






    const handleAddButtonClick = async (name) => {
        const validationMessage = validateField(name, textInputValues[name]);
        if (validationMessage) {
            setValidationMessages(prevMessages => ({
                ...prevMessages,
                [name]: validationMessage
            }));
        } else {
            try {
                console.log(`Adding ${textInputValues[name]} for ${name}`);
                const getRegisterValidation = await registrationvalidation(textInputValues[name], uuid, split_name);
                console.log("getRegisterValidation.data.validity", getRegisterValidation.data.validity);

                if (getRegisterValidation.data.validity == "1") {
                    SetVeichleAddModal(true);
                    // let vehicle = getRegisterValidation.data.vehicleData;
                    // vehicle.reg_no = textInputValues[name];

                    const response = await SaveCarDetails(
                        visitorParameters.visitor_parameters,
                        textInputValues[name],
                        name,
                        'save_car_details',
                        visitorParameters.data,
                        query_string,
                        formdata
                    );

                    setButtonClickedStatus(prevStatus => ({
                        ...prevStatus,
                        [name]: true
                    }));
                    setButtonClickedStatus(prevVisibility => ({
                        ...prevVisibility,
                        [name]: false
                    }));

                    setTimeout(() => {
                        SetVeichleAddModal(false);
                    }, 2000);
                    console.log(`API call successful for ${name}`);
                } else {
                    setValidationMessages(prevMessages => ({
                        ...prevMessages,
                        [name]: 'Vehicle registration is invalid. Please recheck.'
                    }));
                    SetVeichleAddModal(false);
                }
            } catch (error) {
                console.warn('Error occurred:', error);
            }
        }
    };


    const validateAllFields = () => {
        const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/;
        const newValidationMessages = {};
        let firstInvalidField = null;

        Object.keys(textInputValues).forEach(name => {
            const value = textInputValues[name];
            if (value.trim() === '') {
                newValidationMessages[name] = 'Please Enter Vehicle Registration';
                if (!firstInvalidField) firstInvalidField = name;
            } else if (!pattern.test(value)) {
                newValidationMessages[name] = 'Your Vehicle Registration is Invalid. Please Recheck';
                if (!firstInvalidField) firstInvalidField = name;
            } else if (value.length < 3) {
                newValidationMessages[name] = 'Vehicle Registration must be at least 3 characters';
                if (!firstInvalidField) firstInvalidField = name;
            } else {
                newValidationMessages[name] = '';
            }
        });

        setValidationMessages(newValidationMessages);

        const allValid = Object.values(newValidationMessages).every(message => message === '');
        const allFieldsValidated = Object.keys(textInputValues).every(name => {
            return buttonClickedStatus[name] === false; // Check if all buttons have been clicked
        });
        if (firstInvalidField) {
            inputRefs.current[firstInvalidField].current.focus();
        } else {
            if (allFieldsValidated) {
                GtmDataLayer({
                    question: "car reg. number",
                  });
                setErrorMsgBtn("");
                const collectedData = lenderData.map(lender => ({
                    lender_id: lender.id,
                    lender_name: lender.lender_name,
                    lender_value: lender.lender_value,
                    registration: textInputValues[lender.lender_name]
                }));
                console.log("collectedData", collectedData);
                history.push("/pcp/thankyou?uuid=" + uuid);
            } else {
                setErrorMsgBtn("Please Verify the Registration Number By Clicking Add Button");
            }
        }
    };

    const SkipRegistration = async (e) => {
        GtmDataLayer({
            question: "skip registration",
          });
        const skippedLeadsResponse = await skippedLeads("skipRegistration", uuid, split_name);
        if (skippedLeadsResponse.data.status == "Success") {
            history.push("/pcp/thankyou?uuid=" + uuid);
        }
    }

    // const SkipBtnDummy = () => {
    //     history.push(
    //         "/pcp/thankyou?uuid=" + uuid
    //     );
    // };

    return (
        <>
            <div className="car-regi-middle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-0 ">
                            {/* <label className="h5 text-dark fw-semibold margin-10">Providing your car registration number will help us expedite your claim process.</label> */}

                            {Object.keys(textInputValues).map((name, index) => (
                                <div key={index} className={`car-box p-3 mb-3 ${validationMessages[name] ? "animation-green-flash-infinite" : ""}`}>
                                    <div className="bank-name mb-2">
                                        <i className="bi bi-bank2" /> {name}
                                    </div>
                                    <div className="numb-plate-wrap justify-content-between">
                                        <div className="d-flex number-plate">
                                            <div className="flag"></div>
                                            <div className="input-wrap w-100">
                                                <input
                                                    type="text"
                                                    className="form-control w-100"
                                                    name={name}
                                                    value={textInputValues[name]}
                                                    onChange={handleTextInputChange}
                                                    ref={inputRefs.current[name]}
                                                    validation={validation({
                                                        required: "Please Enter Vehicle Registration",
                                                        minLength: {
                                                            value: 3,
                                                            message: "Please Enter Valid Vehicle Registration",
                                                        },
                                                        pattern: {
                                                            value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
                                                            message:
                                                                "Your Vehicle Registration is Invalid. Please Recheck",
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        {/* {buttonClickedStatus[name] && (
                                            <button
                                                type="button"
                                                className={`add-more addNUmber ${buttonClickedStatus[name] ? "animation-green-flash-infinite" : ""}`}
                                                name={name}
                                                onClick={() => handleAddButtonClick(name)}
                                            >
                                                Add
                                            </button>
                                        )} */}

                                        {buttonClickedStatus[name] ? (
                                            <button
                                                type="button"
                                                className={`add-more addNUmber ${buttonClickedStatus[name] ? "animation-green-flash-infinite" : ""}`}
                                                name={name}
                                                onClick={() => handleAddButtonClick(name)}
                                            >
                                                Add
                                            </button>
                                        ) : (<div className="tick-img">
                                            <img src={tick} alt="Success" className="tick-image" />
                                        </div>
                                        )}
                                    </div>
                                    {validationMessages[name] && (
                                        <label className="error mt-2">{validationMessages[name]}</label>
                                    )}
                                </div>
                            ))}

                            {
                                ErrorMessageBtn && (
                                    <label id="refund_previously-error" className="mt-2 mb-2 error_msg text-center" htmlFor="refund_previously">
                                        {ErrorMessageBtn} </label>
                                )
                            }


                            {/* End Car Cards */}
                            <div className="row my-lg-4 my-md-4 my-sm-4 my-4">
                                <div className="col-4 pe-0">
                                    <button type="button"
                                        id="next3"
                                        className=" com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn"
                                        name="skipRegistration"
                                        onClick={SkipRegistration}
                                    >
                                        Skip
                                    </button>
                                </div>
                                <div className="col-8">
                                    <button
                                        type="button"
                                        onClick={validateAllFields}
                                        id="next4"
                                        className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2">
                                        Next
                                    </button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={VeichleModal}
                onHide={VeichleModal}
                backdrop="static"
                keyboard={false}
                centered
                contentClassName="modalcontentcar carmodal modal-dialog-centered"
            >
                <Modal.Body>
                    <div className={`Second_slide1`}>
                        <div className="main-container pt-3">
                            <div className="check-container">
                                <div className="check-background">
                                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth={13} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="check-shadow" />
                            </div>
                        </div>
                        <label className="h5 margin-5 text-center">Vehicle Added Successfully</label>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default FormCarRegistration;
import React, { useEffect, useState, useContext } from 'react';
import "../../assets/TGG_V1_OLD/ID_Upload/css/id_upload_1.scss";
import "../../assets/TGG_V1_OLD/ID_Upload/css/id_upload_2.scss";
import "../../assets/TGG_V1_OLD/ID_Upload/css/animate.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Testimonial from "../Includes/Layouts/TGG_V1/Testimonial.js";
import AccordSection from '../Includes/Layouts/TGG_V1/AccordSection';
import Footer from '../Includes/Layouts/TGG_V1/Footer';
import { useHistory } from "react-router-dom";
import Header from '../Includes/Layouts/TGG_V1_OLD/Header';
import LogoBasedOnDomain from '../Includes/Common/LogoBasedOnDomain';
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import { useIdUpload } from '../../Hooks/useIdUpload';
import {UseLeadApi} from "../../Hooks/UseLeadApi";
import { useSkippedLeads } from '../../Hooks/useSkippedLeads';
import GtmDataLayer from '../../Utility/GtmDataLayer';
import { Api } from "../../api/Api";


const userfile = {
  preview: undefined,
  title: undefined,
};

const Id_upload = () => {
  const { saveIdUpload, isLoading } = useIdUpload();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const split_name =localStorage.getItem("split_name");
  const { skippedLeads } = useSkippedLeads();


  const [display, setDisplay] = useState("hide");
  const [loader, setLoader] = useState("hide");
  const [JPGdisp, setJPGdisp] = useState("show");
  const [docdiv, setDocdiv] = useState("hide");
  const [docSubmit, setDocSubmit] = useState("");
  const [submitName, setSubmitName] = useState("");
  // const [displayError, setDisplayError] = useState(false);
  const [loaderModal, setLoaderModal] = useState("show");

  const [selectedFileType, setSelectedFileType] = useState();
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [IDCard, setIDCard] = useState(false);
  // const [driversLicense, setDriversLicense] = useState(false);
  // const [passport, setPassport] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const history = useHistory();
  const {LeadUpdateApi} =UseLeadApi();
  let count = 0;

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    //binaryString = binaryString.replace(";base64", `;name=${selectedFile.name};base64`);
    // setBase64Image({
    //   //base64TextString : btoa(binaryString)
    //   base64TextString : btoa(encodeURIComponent(binaryString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    //     return String.fromCharCode('0x' + p1);
    //   }))
    // });
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const handleReUpload = () => {
    setDocdiv("hide");
    setJPGdisp("show");
  };


  const changeFilesHandler = (event) => {
    setJPGdisp("hide");
    setLoader("show");
    setTimeout(() => {
      setLoader('hide');
      setDocdiv("show");
    }, 1000); // 1000 milliseconds = 1 second

    // setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      // setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      console.log(imgsize);
      console.log(1024 * 1024 * 10);
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }
      setIDCard(false);
      // setDriversLicense(false);
      // setPassport(false);

      if ("IDCard_doc" == event.target.name) {
        setIDCard(true);
      }
      // else if ("driversLicense_doc" == event.target.name) {
      //   setDriversLicense(true);
      // } else if ("passport_doc" == event.target.name) {
      //   setPassport(true);
      // }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      // setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };


  const changeCameraHandler = (event) => {

    setJPGdisp("hide");
    setLoader("show");
    setTimeout(() => {
      setLoader('hide');
      setDocdiv("show");
    }, 1000);

    // setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      // setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }
      setIDCard(false);
      // setDriversLicense(false);
      // setPassport(false);

      if ("IDCard_doc_cam" == event.target.name) {
        setIDCard(true);
      }
      // else if ("driversLicense_doc_cam" == event.target.name) {
      //   setDriversLicense(true);
      // } else if ("passport_doc_cam" == event.target.name) {
      //   setPassport(true);
      // }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        //reader.readAsBinaryString(imageFile);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      // setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };


  const savedocument = async (e) => {
    
    setLoaderModal("show");
    console.log(e.target.name);
    setDocSubmit(e.target.name);
    setDisplay("hide");

    // GtmDataLayer({
    //   'question': 'identity verification' 
    // });

    try {
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {
        let uploadData = "";
        uploadData = {
          imageFile: base64Image.base64TextString,
          captureType: selectedFileType.substring(
            selectedFileType.indexOf("doc")
          ),
          documentType: submitName,
        };
        const response = await saveIdUpload(
          uploadData,
          visitorParameters,
          formParameters,
          queryString
        );
        
      // updateMilestone(visitorParameters.visitor_parameters.uuid,"id_upload","live");
        // if(response.status=="Success"){

          // window.scrollTo(0, 0);
          // history.push(
          //   "/pcp/thankyou?uuid=" 
          //   + visitorParameters.visitor_parameters.uuid
          // );
        // }

      }
    } catch (e) {
      console.warn("error");
      console.warn(e);

    } finally {
      // GtmDataLayer({
      //   question: "verify your identity"
      // });
      // window.scrollTo(0, 0);
      // history.push(
      //   "/pcp/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
      // );
      handleLeadUpdate();
    }


    async function handleLeadUpdate() {
      count += 1;
      try {
        const responseLeadApi = await LeadUpdateApi(
          visitorParameters.visitor_parameters.uuid,
          base64Image.base64TextString
        );
    
        if (!responseLeadApi.data.response || responseLeadApi.status !== 200) {
          throw new Error(`HTTP error! status: ${responseLeadApi.status}`);
        }
    
        await Api.post("api-count", {
          uuid : visitorParameters.visitor_parameters.uuid,
          api_type: "LeadUpdateApi",
          count: count,
          source: "live"
        });
      } catch (error) {
        console.error("Error making the API call:", error);
    
        try {
          await Api.post("api-count", {
            uuid : visitorParameters.visitor_parameters.uuid,
            api_type: "LeadUpdateApi",
            count: count,
            source: "live"
          });
        } catch (e) {
          console.log("Error updating the API count:", e);
        }
    
        if (count < 3) {
          // Increment count and retry LeadSendApi
          await handleLeadUpdate();
        }
      } 

      GtmDataLayer({
        question: "verify your identity"
      });
      window.scrollTo(0, 0);
      history.push(
        "/pcp/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    }
  };


  const handleClick = (e) => {

    const name = e.currentTarget.getAttribute('name');

    setDisplay("show");
    if (name == "national_id") {
      setSubmitName("national_id");
    }
    else if (name == "driversLicence") {
      setSubmitName("driversLicence");
    }
    else if (name == "passport") {
      setSubmitName("passport");
    } else {
      setSubmitName("");
    }

    // setDisplayError(false);

    // if(docSubmit == "")
    // {
    // setDocdiv("hide");
    // setJPGdisp("show");
    // }

  };

  const handlClose = () => {
    setDisplay("hide");
    setSubmitName("");
  };

  useEffect(() => {
    if (docSubmit == "") {
      setDocdiv("hide");
      setJPGdisp("show");
    }
  }, [docSubmit]);

  useEffect(() => {
    if (display === 'show') {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [display]);

  useEffect(() => {

    setTimeout(() => {
      setLoaderModal("hide");
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  // const nextClick = () => {
  //   setDisplayError(true);
  //   history.push(
  //     "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
  //   );
  // };
  
  const skipClick = async () => {
    GtmDataLayer({
      question: "skip idupload",
    });
    try {
      const skippedLeadsResponse = await skippedLeads("skip_ID_Upload", visitorParameters.visitor_parameters.uuid, split_name);
    } catch (error) {
      console.error('Error skipping leads:', error);
    } finally {
      window.scrollTo(0, 0);
      history.push(
        "/pcp/thankyou?uuid=" 
        + visitorParameters.visitor_parameters.uuid
      );
    }
  };
  

  return (
    <>
    <GetVisitorsParams />
    <div className='IdUpload'>
      <div className="position-sticky top_mob_sticky d-block d-md-none">
        <div className="banner text-weight-600">
          <img src="assets/TGG_V1/img/warning.png" alt="" /> £16 billion estimated to be paid
          out
        </div>
      </div>
      <div className="progress progress_main d-none">
        <div className="progress-bar">
          <div id="progressBarInner" style={{ width: "0%" }} />
        </div>
      </div>
      {/* <header>
        <div className="wrap larger container">
          <div className="row">
            <div className="logo">
              <img src="assets/TGG_V1/img/logo.png" alt="" style={{ width: 200 }} />
            </div>
            <div className="secure-badge">
              <img src="assets/TGG_V1/img/secure.png" alt="" />
            </div>
          </div>
        </div>
      </header> */}
      <Header />
      <section className="form">
        <form className="main_form" action="" method="get">
          <div className="wrap larger">
            <div className="container">
              <div className="step start" id="step_lender">
                <div className="form-right bounce-y top-up d-block d-md-none">
                  <div className="badge">




                    <div className='content '>
                      <div className='d-flex justify-content-center'>
                        <div>
                          <img
                            src="assets/TGG_V1/img/information-button.png"
                            alt=""
                            className="w-auto mt-1"
                          />
                        </div>
                        <div >
                          <p className='text-start ps-2 h6 mb-0a'> Additional information required to  <br />

                            <b> expedite</b> your claim process.
                          </p>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
                <div className="form-left">
                  <h1 className="margin-10">Now, let's verify your identity</h1>
                  <div className="d-flex margin-5">
                    {" "}
                    <img
                      className='me-3'
                      height={60}
                      src="assets/TGG_V1/img/security.png"
                      alt=""
                      style={{ width: "auto" }}
                    />{" "}
                    <label className="h5 ms-4 margin-10">
                      Your ID will be stored securely and used solely for the
                      purpose of identity verification.
                    </label>
                  </div>
                  <div className="field">
                    <div className="car-regi-middle">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 p-0">
                            {/* //////////////////// Upload Box */}

                            {/* <div className={`upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 nation-card  ${docSubmit == 'national_id' ? 'updated' : ''}`}>
                              <div className="d-flex align-items-center">
                                <div>
                                  {" "}
                                  <div className="icon-circle me-3">
                                    {" "}
                                    <i className="bi bi-person-badge" />{" "}
                                  </div>{" "}
                                </div>
                                <div id="upload">
                                  {" "}
                                  <h3 className="margin-5">National ID Card</h3>

                                  <div
                                    className={`click-upload p-1 px-2 pe-3 fileupload ${docSubmit == 'national_id' ? 'show' : 'hide'}`}
                                    onClick={handleClick}
                                    name="national_id"

                                  >
                                    <i className="bi bi-check-circle-fill me-1" />
                                    File Uploaded

                                  </div>

                                  <div className={`click-upload p-1 px-3 clickupload-label ${docSubmit == 'national_id' ? 'hide' : 'show'}`}
                                    // onClick={() => setDisplay("show")}
                                    onClick={handleClick}
                                    name="national_id"
                                  >
                                    Click to upload
                                  </div>
                                </div>
                                <div className="forward-angle">
                                  <div
                                    className={`close-file ${docSubmit == 'national_id' ? 'show' : 'hide'}`}
                                  >

                                    <i className="bi bi-x-lg" onClick={() => setDocSubmit("")}></i>
                                  </div>
                                  <div className={`forwardarrow ${docSubmit == 'national_id' ? 'hide' : 'show'}`}>
                                    <i>
                                      <img
                                        src="assets/TGG_V1/img/arrow_forward_ios.svg"
                                        alt=""
                                      />
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            {/* //////////////////// Upload Box */}
                           
                            <div className={`upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 license  ${docSubmit == 'driversLicence' ? 'updated' : ''}`}>
                              <div className="d-flex align-items-center">
                                <div>
                                  {" "}
                                  <div className="icon-circle me-3">
                                    {" "}
                                    <i className="bi bi-car-front-fill" />{" "}
                                  </div>{" "}
                                </div>
                                <div id="upload">
                                  {" "}
                                  <h3 className="margin-5">Driver's Licence</h3>

                                  <div
                                    className={`click-upload p-1 px-2 pe-3 fileupload ${docSubmit == 'driversLicence' ? 'show' : 'hide'}`}
                                    // onClick={()=> setDisplay("show")}
                                    onClick={handleClick}
                                    name="driversLicence"
                                  >
                                    <i className="bi bi-check-circle-fill me-1" />
                                    File Uploaded

                                  </div>

                                  <div className={`click-upload p-1 px-3 clickupload-label ${docSubmit == 'driversLicence' ? 'hide' : 'show'}`}
                                    // onClick={() => setDisplay("show")}
                                    onClick={handleClick}
                                    name="driversLicence"
                                  >
                                    Click to upload
                                  </div>
                                </div>
                                <div className="forward-angle">
                                  <div
                                    className={`close-file ${docSubmit == 'driversLicence' ? 'show' : 'hide'}`}
                                  >

                                    <i className="bi bi-x-lg" onClick={() => setDocSubmit("")}></i>
                                  </div>
                                  <div className={`forwardarrow ${docSubmit == 'driversLicence' ? 'hide' : 'show'}`}>
                                    <i>
                                      <img
                                        src="assets/TGG_V1/img/arrow_forward_ios.svg"
                                        alt=""
                                      />
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* //////////////////// Upload Box */}
                            
                            <div className={`upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 passport  ${docSubmit == 'passport' ? 'updated' : ''} `}>
                              <div className="d-flex align-items-center">
                                <div>
                                  {" "}
                                  <div className="icon-circle me-3">
                                    {" "}
                                    <i className="bi bi-airplane" />{" "}
                                  </div>{" "}
                                </div>
                                <div id="upload">
                                  {" "}
                                  <h3 className="margin-5">Passport</h3>

                                  <div
                                    className={`click-upload p-1 px-2 pe-3 fileupload ${docSubmit == 'passport' ? 'show' : 'hide'}`}
                                    // onClick={()=> setDisplay("show")}
                                    onClick={handleClick}
                                    name="passport"
                                  >
                                    <i className="bi bi-check-circle-fill me-1" />
                                    File Uploaded

                                  </div>

                                  <div className={`click-upload p-1 px-3 clickupload-label ${docSubmit == 'passport' ? 'hide' : 'show'}`}
                                    // onClick={() => setDisplay("show")}
                                    onClick={handleClick}
                                    name="passport"
                                  >
                                    Click to upload
                                  </div>
                                </div>
                                <div className="forward-angle">
                                  <div
                                    className={`close-file ${docSubmit == 'passport' ? 'show' : 'hide'}`}
                                  >

                                    <i className="bi bi-x-lg" onClick={() => setDocSubmit("")}></i>
                                  </div>
                                  <div className={`forwardarrow ${docSubmit == 'passport' ? 'hide' : 'show'}`}>
                                    <i>
                                      <img
                                        src="assets/TGG_V1/img/arrow_forward_ios.svg"
                                        alt=""
                                      />
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* {displayError && docSubmit === "" && (
                              <p className="error-msg mt-2">
                                Please Upload An ID
                              </p>
                            )} */}

                            {/* //////////////////// Upload Box */}

                            {/* End Upload Cards */}
                            <div className="row my-lg-4 my-md-4 my-sm-4 my-4">
                              <div className="col-4 pe-0">
                                {/* <button
                                  id="next3"
                                  className=" com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn "
                                >
                                  Skip
                                </button> */}
                              </div>
                              <div className="col-8">
                                {/* <div className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2"
                                  onClick={nextClick}
                                >
                                  Next
                                </div> */}
                                <div
                                  id="next3"
                                  className=" com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn "
                                  onClick={skipClick}
                                >
                                  Skip
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-right bounce-y top-up d-none d-md-block">
                    <div className="badge">


                      <div className='content '>
                        <div className='d-flex justify-content-center'>
                          <div>
                            <img
                              src="assets/TGG_V1/img/information-button.png"
                              alt=""
                              className="w-auto mt-1"
                            />
                          </div>
                          <div >
                            <p className='text-start ps-2 h6 mb-0a'> Additional information required to  <br />

                              <b> expedite</b> your claim process.
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="map">
          <img src="assets/TGG_V1/img/uk-map.svg" alt="" />
        </div>
      </section>


      <Testimonial />

      <AccordSection />

      <Footer />


      <div className={`loading-step ${loaderModal}`}>
        <div className="loading-spinner">
          <LogoBasedOnDomain className="loader_logo" />
        </div>
        <div className="overlay">
          <p className="fw-bold showBefore_add">Analysing your vehicle details</p>
        </div>
      </div>

      {/* Modal */}
      {/* Modal */}

      <div className={`${display === 'show' ? 'modal-open' : ''}`} >
      <div
        className={`modal fade-scale ${display === 'show' ? 'show' : ''}`}
        id="uploadpop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        style={{ display: display === 'show' ? 'block' : 'none' }}
        aria-modal={display === 'show'}
        aria-hidden={display !== 'show'}
        role={display === 'show' ? 'dialog' : undefined}
      >


          <div className="modal-dialog fade-scale-in modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body file-upload-body ">
                <h2 className="title pt-2 ">
                  Upload your files
                  <div data-bs-dismiss="modal" className="close-btn">
                    <i className="bi bi-x-circle" onClick={handlClose} > </i>
                  </div>
                </h2>
                {/* Uploads Box */}
                <div className="file-uploads-box text-center p-3 my-3">
                  <div className={`choosed-filebody ${docdiv}`}>
                    {/* <img src="dist/img/upload-demo.jpg" /> */}
                    {IDCard && !isInValidfFile ? (
                      <img
                        src={userfile.preview}
                        alt={userfile.title}
                        style={{ width: "100%", padding: "0px 69px" }}
                      />
                    ) : (
                      <img src="assets/TGG_V1/img/upload-demo.jpg" alt="" />
                    )}
                  </div>

                  <div className="choose-filebody">
                    {/* <input type="file" className="iput-file" name="" id="choosefile" /> */}
                    <div className={`${docdiv === 'show' ? 'hide' : 'show'}`}>
                      <i className="bi bi-cloud-arrow-up" />
                    </div>

                    <div className={`file-text ${JPGdisp}`}>JPG, PNG, or PDF, smaller than 10MB</div>


                    <div className={`text-center my-1 imgloader ${loader} `}>
                      <div className="file-text">Image Uploading...</div>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Animated striped example"
                        aria-valuenow={75}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <div className="my-3 choose-items">
                      <span className={` ${loader === 'show' ? 'hide' : 'show'}`}>
                        <span className={`choose-file p-2 px-3 ${docdiv === 'show' ? 'hide' : 'show'}`}>
                          Choose File
                          <input type="file"
                            className={`iput-file  ${docdiv === 'show' ? 'hide' : 'show'}`}
                            id="choosefile"
                            name="IDCard_doc"
                            onChange={changeFilesHandler}
                            onClick={(e) => (e.target.value = null)}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                {isInValidfFile && (
                  <p className="error-msg mt-2">
                    {errorMessage.txt}
                  </p>
                )}
                <div className="row ">
                  <div className="col-6 pe-0">

                    <button
                      id=""
                      className={`com-button px-1 takephoto  w-100 skipbtn ${docdiv === 'show' ? 'hide' : 'show'}`}
                    >
                      {/* <div className=" com-button px-1 takephoto  w-100 skipbtn "> */}

                      <label>
                        <i className="bi bi-camera-fill me-2 ms-0" /> Take Photo

                        <input
                          type="file"
                          accept="image/*"
                          capture="camera"
                          name="IDCard_doc_cam"
                          onChange={changeCameraHandler}
                          onClick={(e) => (e.target.value = null)}
                          style={{
                            opacity: 0,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            cursor: 'pointer',
                          }}
                        />

                      </label>
                      {/* </div> */}

                    </button>

                    <button
                      id=""
                      className={`com-button px-1 takephoto reupload  w-100 skipbtn ${docdiv === 'show' ? 'show' : 'hide'}`}
                      onClick={handleReUpload}
                    >
                      <i className="bi bi-arrow-repeat me-2 ms-0" /> Re Upload
                    </button>

                  </div>
                  {/* <div className="col-6"> */}
                  {/* <button id="fileuplSubmit" className="com-button  w-100">
                    Submit
                  </button> */}
                  {!isInValidfFile && (
                    <div className="col-6">
                      <button
                        type="button"
                        className="com-button  w-100"
                        name={submitName}
                        value="Submit"
                        onClick={savedocument}
                        style={{
                          border: "none",
                          cursor: "pointer",
                          color: "white",
                          // backgroundColor: docdiv === 'hide' ? 'grey' :'#005398', // Change color based on state

                        }}
                        disabled={docdiv === 'hide'}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/* Modal */}
    </>

  )
}

export default Id_upload
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
const AccordSection = () => {
  const buttontotop =()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
 }
  return (
    <>
      <section className="faqsection bg-white py-5">
        <div className="container">
          <div className="row title-sec">
            <div className="col-lg-2 col-md-2 col-sm-2 col-3 text-center">
              <img src="../../assets/TGG_V1/img/icon-faq.png" />
            </div>
            <div className="col-lg-10 col-md-10 col-sm-10 col-9 ps-lg-0 ps-md-0 ">
              <h2 className="pt-lg-3 pt-md-3 pt-sm-3 pt-0">Your questions about PCP answered</h2>
              <p>Click on a question below to view the answer.</p>
            </div>
          </div>
          <div className="accordion my-lg-5 my-md-5 my-sm-4 my-3">
            <div className="accordion-item">
              <div className="accordion-header">
              <Accordion>
              <Accordion.Item eventKey="0" className="margin-15">
                <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                  How do I determine if I qualify for compensation?
                </Accordion.Header>
                <Accordion.Body>
                  If you've had a finance agreement like PCP or HP in the last
                  decade, you may be eligible for compensation due to
                  mis-selling
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="accordion-item margin-15">
                <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                  What criteria must I meet to make a claim?
                </Accordion.Header>
                <Accordion.Body>
                  Your lender must have failed to disclose the commission
                  details on your agreement(s). If your finance details were
                  inadequately explained or you faced higher interest rates due
                  to commissions, you could be eligible for £1,000s
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="accordion-item margin-15">
                <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                  Does the type of agreement matter (PCP vs. HP)?
                </Accordion.Header>
                <Accordion.Body>
                  Don't worry if you had an HP loan instead of a PCP agreement —
                  we accept claims for various vehicle finance agreements
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
              </div>
            </div>
          </div>
          <p className="margin-none my-3">
              <button className="blue-btn" onClick={buttontotop} >
               <span> Check my claim <img src="../../assets/TGG_V1/img/arrow-right.png" alt="" /></span>
              </button>
          </p>
        </div>
      </section>
    </>
  );
};

export default AccordSection;

import React from "react";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation"
import Email from "../../../UI/Email";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import Telephone from "../../../UI/Telephone";
const ContactDetails = ({
    validation,
    validationMsg,
    setError,
    className,
    backClick,
    trigger,
    getValues,
    setLoader1,
    slideChange
}) => {

    const { emailValidation } = useEmailValidation();
    const { phoneValidation } = usePhoneValidation();

    const phoneValidCheck = async () => {
        const values = getValues();
        const phone = values["txtPhone"];
        const email = values["txtEmail"];
    
        if (phone !== "") {
          const getPhoneValidation = await phoneValidation(phone);
          if (getPhoneValidation.data.status_code === 0) {
            setError("txtPhone", {
              type: "manual",
              message: "Please Enter Valid Working Phone Number",
            });
            return 0;
          } else if (getPhoneValidation.data.status_code === 2) {
            setError("txtPhone", {
              type: "manual",
              message: "Phone Number Already Exist",
            });
            return 0;
          } else if (getPhoneValidation.data.status_code === 3) {
            setError("txtPhone", {
              type: "manual",
              message: "Please Enter Valid Phone Number",
            });
            return 0;
          } else {
            return 1;
          }
        }
      };
    
      const emailValidCheck = async () => {
        const values = getValues();
        const email = values["txtEmail"];
        const phone = values["txtPhone"];
    
        if (email !== "") {
          const getEmailValidation = await emailValidation(email);
          if (getEmailValidation.data.status_code === 1) {
            return 1;
          } else {
            setError("txtEmail", {
              type: "manual",
              message: getEmailValidation.data.message,
            });
          }
        }
      };

      const validateSlide4 = async (e) => {
        e.preventDefault();
        const values = getValues();
        var emailVal = await trigger("txtEmail");
        var phoneVal;
        if (emailVal) {
          phoneVal = await trigger("txtPhone");
        } else {
          document.getElementById("txtEmail").focus();
          return false;
        }
    
        if (emailVal && phoneVal) {
          if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
            GtmDataLayer({
                question: "contact details",
              });
            slideChange(e);
          }
        }
      };
    return (
        <>


            <div className={`modal ${className}`} id="lenderinfopop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content animate__animated animate__zoomIn animate__delay-.5s ">

                        <div className="modal-body pt-0">

                            <div className="jst_adDf_lx">
                                <div className="slide4 col-12">
                                    <div className="cicle_loader pt-0">
                                        <div className="text12">
                                            <h5 className="txt">Checking Lender information</h5>
                                        </div>
                                        <div className="loader_gif_">
                                            <svg className="lp" viewBox="0 0 128 128" width="128px" height="128px" xmlns="http://www.w3.org/2000/svg">
                                                <defs>
                                                    <linearGradient id="grad1" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="0%" stopColor="#000" />
                                                        <stop offset="100%" stopColor="#fff" />
                                                    </linearGradient>
                                                    <mask id="mask1">
                                                        <rect x="0" y="0" width="128" height="128" fill="url(#grad1)" />
                                                    </mask>
                                                </defs>
                                                <g fill="none" strokeLinecap="round" strokeWidth="16">
                                                    <circle className="lp__ring" r="56" cx="64" cy="64" stroke="#ddd" />
                                                    <g stroke="hsl(215.48deg 70.55% 31.96%)">
                                                        <polyline className="lp__fall-line" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay1" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay2" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay3" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay4" points="64,8 64,120" />
                                                        <circle className="lp__drops" r="56" cx="64" cy="64" transform="rotate(90,64,64)" />
                                                        <circle className="lp__worm" r="56" cx="64" cy="64" transform="rotate(-90,64,64)" />
                                                    </g>
                                                    <g stroke="hsl(215.48deg 70.55% 31.96%)" mask="url(#mask1)">
                                                        <polyline className="lp__fall-line" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay1" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay2" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay3" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay4" points="64,8 64,120" />
                                                        <circle className="lp__drops" r="56" cx="64" cy="64" transform="rotate(90,64,64)" />
                                                        <circle className="lp__worm" r="56" cx="64" cy="64" transform="rotate(-90,64,64)" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form_drop col-lg-12 col-12" id="ENt_DTLS">
                                <p className="sty_pop">Meanwhile, Please provide your email and phone number below so that we can keep you updated on your potential refund</p>
                                <div className="check_email_add animate__animated animate__fadeInUp animate__delay-.5s">
                                    <label>Email Address: </label>
                                    <div className="input-group flex-nowrap secure">

                                        <Email
                                            className="form-control"
                                            name="txtEmail"
                                            id="txtEmail"
                                            placeholder="Email address"
                                            autocomplete="off"
                                            onBlur={emailValidCheck}
                                            validation={validation({
                                                required: "Please Enter Valid Email Address",
                                            })}
                                        ></Email>
                                      
                                    </div>
                                    {validationMsg.txtEmail && (
                                            <span className="error_msg " id="email_err">
                                                {validationMsg.txtEmail.message}
                                            </span>
                                        )}
                                    <p className="tiny_note">
                                        <img src="dist/img/info.png" className="pe-1" alt="" />We need this so we can keep you updated on your claim by email.
                                    </p>
                                </div>

                                <div className="row phone_deatail animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="col-12 user_no">
                                        <label>Mobile Number:</label>
                                        <div className="input-group flex-nowrap secure">

                                            <Telephone
                                                name="txtPhone"
                                                id="txtphone"
                                                className="form-control"
                                                placeholder="Phone Number"
                                                autocomplete="off"
                                                onBlur={phoneValidCheck}
                                                onKeyPress={(e) =>
                                                    !/[0-9]+$/.test(e.key) && e.preventDefault()
                                                }
                                                maxlength="11"
                                                validation={validation({
                                                  minLength: {
                                                    value: 10,
                                                    message:
                                                      "Please Enter Valid Working Phone Number",
                                                  },
                                                    pattern: {
                                                        value: /^[0-9]*$/i,
                                                        message: "Please Enter Valid Phone Number",
                                                    },
                                                    required: "Please Enter Phone Number",
                                                })}
                                            ></Telephone>
                                           
                                        </div>
                                        {validationMsg.txtPhone && (
                                                <span className="error_msg " id="phone_err">
                                                    {validationMsg.txtPhone &&
                                                        "Please Enter Valid Phone Number"}
                                                </span>
                                            )}
                                    </div>
                                    <p className="tiny_note mb-0">
                                        <img src="dist/img/info.png" className="pe-1" alt="" />We need this so we can keep you updated on your claim with text message.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                            <div className="bg-1">
                                <input type="button" 
                                className="confetti button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice" 
                                id="nextPlate_006" 
                                value="Next"
                                name="contactDetails"
                                onClick={validateSlide4}
                                 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


};

export default ContactDetails;
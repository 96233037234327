const InputTag = ({ id, styleClass, title,myref, ...rest }) => (
  <>
    <label htmlFor={id}>{title}</label>
    <input
      className={styleClass}
      ref={myref}
      id={id}
      {...rest}
    />
  </>
);

export default InputTag;

import React from "react";
import "../../../../assets/TGG_V1_OLD/css/newTGG.scss";
import "../../../../assets/TGG_V1_OLD/css/thankyou.scss";
import Footer from "../../Layouts/TGG_V1_OLD/Footer";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const UnqualifiedTGG = () => {
  return (
    <>
      <div className="doc_sections ty-css ">
        <header>
          <div className="container pt-3">
            <div className="row">
              <div className="col-lg-12 col-12 text-center ">
                {/* <img src="/assets/TGG_V1/img/logo.png" alt style={{ width: 200 }} /> */}
                <LogoBasedOnDomain style={{ width: 200 }}/>
              </div>
            </div>
          </div>
        </header>

        <section className="thankyou">
          <div className="container">
            <div className="row">
              <div className="col-lg-10  col-12  offset-lg-1 ">
                <div className="thankyou_div">
                  <div className="main-container pt-3 d-flex justify-content-center">
                    <div className="check-container">
                      <div className="check-background">
                        <svg
                          viewBox="0 0 65 51"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 25L27.3077 44L58.5 7"
                            stroke="white"
                            strokeWidth={13}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="check-shadow" />
                    </div>
                  </div>
                  <h2 className="cs_hero_title text-center animate__animated animate__fadeInUp animate__delay-1s">
                    {" "}
                    We appreciate your effort, but it appears that the
                    information you provided is already stored in our database.
                    Thank you!
                  </h2>
                  <h3
                    className="text-center mt-3 w-100 lit_sm animate__animated animate__fadeInUp animate__delay-.5s"
                    style={{ maxWidth: "unset !important" }}
                  ></h3>
                </div>
              </div>
            </div>
          </div>
          <div className="map">
            <img src="/assets/TGG_V1/img/uk-map.svg" />
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default UnqualifiedTGG;

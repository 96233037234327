import { useState } from "react";
import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useDuplicateCheck = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const duplicateCheck = async (uuid, email, mobile, postcode,fName,lName,dob) => {
    const response = await Api.post("v1/duplicate-check", {
      uuid,
      email,
      mobile,
      postcode,
      fName,
      lName,
      dob
    }).catch(function (error) {
      const erroMessage = {
        uuid: uuid,
        email: email,
        mobile: mobile,
        postcode: postcode,
        fName:fName,
        lName:lName,
        dob:dob
      };
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { duplicateCheck };
};

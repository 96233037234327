import React, { useContext, useEffect, useState } from 'react'
import "../../assets/TGG_V2/CarRegistrationV2/CSS/car_registration.scss";
import "../../assets/TGG_V2/CarRegistrationV2/CSS/car_reg_custom.scss";
import { UseSaveLenders } from '../../Hooks/UseSaveLenders';
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import { useSkippedLeads } from '../../Hooks/useSkippedLeads';
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import Header from '../Includes/Layouts/TGG_V2/Header';
import Footer from '../Includes/Layouts/TGG_V2/Footer';
import GtmDataLayer from '../../Utility/GtmDataLayer';
const LendersTGG_V2 = () => {
    const [lenderList, setLenderList] = useState([]);
    const [checkboxes, setCheckboxes] = useState({});
    const [UserSelectedArray, setUserSelectedArray] = useState([]);
    const [LenderSelectedList, setLenderSelectedList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const { saveLender } = UseSaveLenders();
    const { skippedLeads } = useSkippedLeads();
    
    
    const split_name = localStorage.getItem("split_name");
    const history = useHistory();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [errorMsg, setErrorMessage] = useState('');
    const [LoaderLender, SetLenderLodar] = useState("hide");
    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    }, []);

    const getUuid = () => {
        const visitorData = visitorParameters.visitor_parameters;
        if (visitorData != "") {
            var uuid = visitorData.uuid;
        } else {
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem("uuid");
            var uuid = query_uuid ? query_uuid : local_storage_uuid;
        }
        return uuid;
    };

    const uuid = getUuid();


    useEffect(() => {
        let ApiLenders;
        const storedApiLenders = localStorage.getItem('ApiLenders');

        if (storedApiLenders) {
            try {
                ApiLenders = JSON.parse(storedApiLenders);
            } catch (error) {
                console.error("Error parsing JSON from localStorage:", error);
                ApiLenders = [];
            }
        } else {
            ApiLenders = [];
        }
        // Retrieve data from localStorage
        //const ApiLenders = JSON.parse(localStorage.getItem('ApiLenders')) || [];
        const storedLenderArray = JSON.parse(localStorage.getItem('Lenders')) || [];
        const storedUnselectedLenders = JSON.parse(localStorage.getItem('UnselectedLender')) || [];

        console.log("UserSelectedLender ", storedLenderArray);
        console.log("APILender :: ", ApiLenders);

        // Create a set of lender names from storedLenderArray for quick lookup
        const storedLenderNamesSet = new Set(storedLenderArray.map(lender => lender.lenderName));

        // Filter ApiLenders to keep only those not in storedLenderArray
        const filteredApiLenders = ApiLenders.filter(lender => !storedLenderNamesSet.has(lender.lenderName));

        console.log("Filtered API Lenders: ", filteredApiLenders);

        const combinedLenderNamesSet = new Set([
            ...storedLenderArray.map(lender => lender.lenderName),
            ...ApiLenders.map(lender => lender.lenderName)
        ]);

        // Function to get random elements from an array
        const getRandomElements = (array, count) => {
            const shuffled = array.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, count);
        };



        // Get 3 random lenders from storedUnselectedLenders
        const randomLenderNames = getRandomElements(storedUnselectedLenders, 3)
            .map(lender => ({
                id: lender.id,
                lenderName: lender.lenderName,
            }));


        console.log("randomLenderNames", randomLenderNames);

        // Combine filtered ApiLenders with randomLenderNames
        const finalLenderList = [...filteredApiLenders, ...randomLenderNames];

        console.log("finalLenderList", finalLenderList);

        // Set the final lender list and initialize checkboxes
        setLenderList(finalLenderList);
        const initialCheckboxes = {};
        finalLenderList.forEach(lender => {
            initialCheckboxes[lender.lenderName] = false;
        });
        setCheckboxes(initialCheckboxes);
        setUserSelectedArray(storedLenderArray);
    }, []);
    const handleCheckboxChange = (e) => {
        const selectedLenderName = e.target.name;
        const selectedLenderValue = e.target.value;
        const updatedCheckboxes = { ...checkboxes, [selectedLenderName]: e.target.checked };
        setCheckboxes(updatedCheckboxes);

        let updatedList = [...LenderSelectedList];
        if (e.target.checked) {
            updatedList = [...LenderSelectedList, { lenderName: selectedLenderName, lender_value: selectedLenderValue }];
        } else {
            updatedList = LenderSelectedList.filter(option => option.lenderName !== selectedLenderName);
        }
        setLenderSelectedList(updatedList);


        let final_array = (UserSelectedArray.concat(updatedList));
        localStorage.setItem('Lenders', JSON.stringify(final_array));
    };

    const handleSubmit = (e) => {
        let selectedCount = LenderSelectedList.length;
        if (selectedCount === 0) {
            setErrorMessage('Please Choose an Option');
        } else {
            setErrorMessage('');
            setIsDisabled(true);
            SaveLenderFn();
            GtmDataLayer({
                question: "select lender",
            });
        }
    };

    const SaveLenderFn = async () => {
        SetLenderLodar("showLoader");
        const responseSaveLender = await saveLender(
            uuid,
            LenderSelectedList
        )

        const timer = setTimeout(() => {
            if (responseSaveLender.data.status === "Success") {
                window.scrollTo(0, 0);
                history.push("/pcp/register_car_TGG_V2?uuid=" + uuid);
            }
            // SetLenderLodar("hide");
        }, 2000);


    };

    const SkipLender = async () => {
        SetLenderLodar("showLoader");
        GtmDataLayer({
            question: "skip lender",
          });
        const skippedLeadsResponse = await skippedLeads("skipLender", uuid, split_name);
        const timer = setTimeout(() => {
            if (skippedLeadsResponse.data.status === "Success") {
                window.scrollTo(0, 0);
                history.push("/pcp/register_car_TGG_V2?uuid=" + uuid);
            }
        }, 2000);


    }

    return (
        <>
            <GetVisitorsParams />
            <div className="CarRegistration" >
                <div className="__PCPtcg">
                    <div className="bg_lol">
                        {/*TOP NAVIGATION*/}
                        
                        <Header />
                        <section className="cs_hero cs_style_1 sec_design section_design1">
                            <div className="cs_hero_wrap cs_bg_filed">
                                {/*Gradient*/}
                                <div className="container-md">
                                    <form id="form">
                                        {/*  Car Registration */}
                                        <div className="car-section ">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-8 col-md-12  8 car-regi-middle mb-5">
                                                    {/* Selcted Lenders */}
                                                    <div id="lender-section" className="leder-section">
                                                        <h1 className="fw-bold title">
                                                            Many of our customers have financed their cars with these
                                                            popular lenders. Please select any that apply to you.
                                                        </h1>
                                                        <p>
                                                            {" "}
                                                            <i>
                                                                (If this doesn't apply to you, please proceed by
                                                                clicking the skip button)
                                                            </i>
                                                        </p>
                                                        <div className="lenderpopslide2 mt-4">
                                                            <div className="selectoptions  animate__animated animate__fadeInUp  ">
                                                                
                                                                {lenderList.map((lender, index) => (
                                                                    <div key={index}>
                                                                        <input
                                                                            type="checkbox"
                                                                            name={lender.lenderName}
                                                                            id={`lender_${index}`}
                                                                            className="test"
                                                                            value={lender.lenderName}
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                        <label htmlFor={`lender_${index}`} className="selected-false">
                                                                            <span className="option_title title_1">
                                                                                {lender.lenderName}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                ))}

                                                               
                                                            </div>
                                                            {errorMsg && (
                                                                <label id="refund_previously-error" className="mt-2 error_msg text-center" htmlFor="refund_previously">
                                                                    {errorMsg}
                                                                </label>
                                                            )}
                                                        </div>
                                                        <div className="clearfix" />
                                                        {/* Submit */}
                                                        <div
                                                            className="box_button mb-4 sign_Btn_paGe pt-4 animate__animated animate__fadeInUp animate__delay-1s animated-element wht_iFNot"
                                                            id="subRegNo1"
                                                        >
                                                            <div className="bg-1 hme_btn text-lg-start text-md-start text-sm-center text-sm-center">
                                                                <input
                                                                    type="button"
                                                                    className="button button--moema button--text-thick button--text-upper button--size-s blick_notice"
                                                                    id="nextLender"
                                                                    defaultValue="Next"
                                                                    aria-label="submitid"
                                                                    name="subRegNo1"
                                                                    onClick={handleSubmit}
                                                                    disabled={isDisabled}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 text-lg-start text-md-start text-sm-center text-center animate__animated animate__fadeInUp animate__delay-1s">
                                                            <p className="btn skipbtn skip1" onClick={SkipLender} >
                                                                {" "}
                                                                Skip <i className="bi bi-chevron-double-right" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-4 col-md-8 d-lg-block d-md-none d-sm-none d-none">
                                                    <div className="mt-lg-4 ">
                                                        <img
                                                            src="/assets/TGG_V2/img/bg-float2.png"
                                                            alt="Hero"
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                    
                    <Footer />
                </div>
                <div className={`loader-div loaderresult loader-fix ${LoaderLender}`}>
                    <div className="text-center">
                        <img height="110" src="/assets/TGG_V2/img/loader03.svg" alt="" />
                    </div>
                </div>
            </div>
        </>

    )
}

export default LendersTGG_V2
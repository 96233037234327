import React, { useReducer, useState, useEffect, useRef } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import InputButton from "../../../UI/InputButton";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
  readonly: true,
};

const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
        readonly: false,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  scrollView,
  setPreviousAddressData,
  GtmDataLayer,
  getValues,
  setLoader1,
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const [userpostCode, setuserPostCode] = useState();
  const customRef = useRef(null);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            setuserPostCode(e.target.value.toUpperCase());
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    // clearErrors([
    //   "txtHouseNumber",
    //   "txtAddress2",
    //   "txtCounty",
    //   "txtTown",
    //   "txtCountry",
    // ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      customRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setPostCodeAddress(getData.data);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };
  const lookupValidation = async (e) => {
    const pCode = await trigger("txtPostCode");
    if (pCode && state.checkSelect) {
      const selpcode = await trigger("address1");
      if (selpcode && e.target.name == "postcode") {
        nextSlide(e);
      }
    }
  };
  const slideChanger = async (e) => {
    await trigger("txtPostCode");

  }

  // const checkPostCodeEntry = () => {
  //   const addressLine1 = document.getElementById("txtHouseNumber").value;
  //   const addressLine2 = document.getElementById("txtAddress2").value;
  //   const town = document.getElementById("txtTown").value;
  //   const county = document.getElementById("txtCounty").value;
  //   const country = document.getElementById("txtCountry").value;

  //   if (
  //     postCodeAddress.length === 0 ||
  //     postCodeAddress.Country !== country ||
  //     postCodeAddress.County !== county ||
  //     postCodeAddress.Line1 !== addressLine1 ||
  //     postCodeAddress.Line2 !== addressLine2 ||
  //     postCodeAddress.Town !== town
  //   ) {
  //     setPostCodeEntry("1");
  //   }
  // };

  const nextSlide = async (e) => {
    window.scrollTo(0, 0);
    setLoader1("show");
    const timer = setTimeout(() => {
      setLoader1("hide");
      slideChange(e);
    }, 1000);
  };
  // const addressValidation = async (e) => {

  //   let errorFlag = 0;
  //   const txtPostCode = await trigger("txtPostCode");
  //   var manual_txtHouseNumber;
  //   var manual_txtTown;
  //   var manual_txtCountry;
  //   var txtAddress;
  //   if (txtPostCode) {
  //     manual_txtHouseNumber = await trigger("txtHouseNumber");
  //     txtAddress = await trigger("address1");
  //   } else {
  //     document.getElementById("txtPostCode").focus();
  //     return false;
  //   }
  //   if (manual_txtHouseNumber) {
  //     manual_txtTown = await trigger("txtTown");
  //   } else {
  //     return false;
  //   }
  //   if (manual_txtTown) {
  //     manual_txtCountry = await trigger("txtCountry");
  //   } else {
  //     return false;
  //   }
  //   if (
  //     !txtPostCode ||
  //     !manual_txtHouseNumber ||
  //     !manual_txtTown ||
  //     !manual_txtCountry
  //   ) {
  //     errorFlag = 1;
  //   }

  //   if (state.clickManualLink === 0) {
  //     const address1 = await trigger("address1");
  //     if (!address1) {
  //       errorFlag = 1;
  //     }
  //   }
  //   if (errorFlag === 0) {
  //     checkPostCodeEntry();
  //     // slideChange(e);
  //     GtmDataLayer({
  //       question: "Postcode",
  //     });
  //   } else {
  //     return false;
  //   }
  // };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtDistrict"
        inputId="txtDistrict"
        inputValue={state.getDetails !== "#" ? state.getDetails.district : ""}
        validation={validation()}
      />
      <HiddenField
        name="txtAddress3"
        inputId="txtAddress3"
        inputValue={state.getDetails !== "#" ? state.getDetails.line_3 : ""}
        validation={validation()}
      />
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  return (
    <>
      <div className={`step ${className}`} id="step_4">
        <div className="form-right bounce-y">
          <div className="badge extra_bb">
            <div className="content">
              <p className="h6 margin-none fw-bold">Possible Claim Value</p>
              <h2 className="margin-none fw-bold">£5,318*</h2>
            </div>
          </div>
        </div>
        <div className="form-left">
          <p className="h1 margin-15">Congratulations!</p>
          <label className="h6 margin-15 text-color-blue lh-sm">
            According to your responses, it seems you might be eligible for
            compensation. Please complete your registration with a few more
            details.
          </label>
          <div className="field padded">
            <label className="h5 margin-5">Confirm your current address</label>
            <p className="text-size-smaller margin-15">
              Please enter your postcode below and click{" "}
              <strong>'Find address'</strong>
            </p>
            <HiddenField
              name="pCode_EntryType"
              inputId="pCode_EntryType"
              inputValue={postCodeEntry}
            />
            <div className="field-icon aligned-button address">
              <div id="postcode_lookup_field" className="position-relative">
                <AddressTextField
                  type="text"
                  placeholder="Enter your postcode"
                  name="txtPostCode"
                  id="txtPostCode"
                  autocomplete="off"
                  className="idpc-input"
                  dataId="txtPostCode"
                  onBlur={checkValidation}
                  autoComplete="off"
                  validation={validation({
                    required: "Please Enter Postcode",
                    pattern: {
                      value:
                        /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                      message: "Please Enter a Valid Postcode",
                    },
                    minLength: {
                      value: 5,
                      message: "Please Enter a Valid Postcode",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtPostCode &&
                    validationMsg.txtPostCode.message
                  }
                />
                <button
                  type="button"
                  name="next"
                  className="idpc-find-address button background-black mb-2"
                  value="Find address"
                  onClick={slideChanger}
                >
                  <span className="idpc-find-address button background-black mb-2">
                    Find address
                  </span>
                </button>

                <div
                  className={`idpc-select-container`}
                  id="currentAddressCollapse"
                  style={
                    state.checkSelect
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <AddressSelectBox
                    className="idpc-select input_padding"
                    OptionValue={state.getAddress}
                    name="address1"
                    id="address1"
                    onChange={getValue}
                    myRef={validation({ required: "Please Select Address" })}
                    validationMsg={
                      validationMsg.address1 && validationMsg.address1.message
                    }
                  />
                </div>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name="txtHouseNumber"
                  id="txtHouseNumber"
                  className="hide"
                  dataId="txtHouseNumber"
                  autoComplete="off"
                  readonly={state.readonly}
                  validation={validation({
                    required: "Please Enter Address Line 1",
                  })}
                  validationMsg={
                    validationMsg.txtHouseNumber &&
                    validationMsg.txtHouseNumber.message
                  }
                  value={state.getDetails.line_1}
                />
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name="txtAddress2"
                  id="txtAddress2"
                  className="hide"
                  dataId="txtAddress2"
                  autoComplete="off"
                  readonly={state.readonly}
                  validation={validation()}
                  validationMsg=""
                  value={state.getDetails.line_2}
                />
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name="txtCounty"
                  id="txtCounty"
                  className="hide"
                  dataId="txtCounty"
                  autoComplete="off"
                  readonly={state.readonly}
                  validation={validation()}
                  validationMsg=""
                  value={state.getDetails.county}
                />
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name="txtTown"
                  id="txtTown"
                  className="hide"
                  dataId="txtTown"
                  autoComplete="off"
                  readonly={state.readonly}
                  validation={validation({ required: "Please Enter Town" })}
                  validationMsg={
                    validationMsg.txtTown && validationMsg.txtTown.message
                  }
                  value={state.getDetails.town}
                />
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name="txtCountry"
                  id="txtCountry"
                  className="hide"
                  dataId="txtCountry"
                  autoComplete="off"
                  readonly={state.readonly}
                  validation={validation({ required: "Please Enter Country" })}
                  validationMsg={
                    validationMsg.txtCountry && validationMsg.txtCountry.message
                  }
                  value={state.getDetails.country}
                />
              </div>
              {Object.keys(state.getDetails).length > 0 ? (
                <HiddenFields />
              ) : null}
            </div>
            <p
              className={`address_pull text-size-smaller hide ${state.showManualAddress}`}
              id="selected_address"
            >
              <span className="address-field aligned premise_pull">
                {state.getDetails.line_1}&nbsp;
              </span>
              <span className="address-field aligned thoroughfare_pull">
                {state.getDetails.line_2}
              </span>
              <span className="address-field line_2_pull">
                {state.getDetails.county}
              </span>
              <span className="address-field post_town_pull">
                {state.getDetails.town}
              </span>
              <span className="address-field county_pull">
                {state.getDetails.country}
              </span>
              {/* {/* <span className="address-field postcode_pull">Kent</span> */}
              <span className="address-field country_pull">{userpostCode}</span>
            </p>
          </div>
          <div className="form-navigation">
            <ul>
              <li>
                <button
                  ref={customRef}
                  type="button"
                  className="button next"
                  name="postcode"
                  id="nextInto5"
                  onClick={lookupValidation}
                >
                  Next{" "}
                  <img
                    src="/assets/TGG_V1/img/arrow-right.png"
                    name="postcode"
                    className="px-2"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  name="commission"
                  className="button back"
                  onClick={backClick}
                  id="BackInto3"
                >
                  <img
                    src="/assets/TGG_V1/img/arrow-left.png"
                    name="commission"
                    className="px-2"
                  />{" "}
                  Back
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCode;

import React from "react";


const Section1 = ({ref1}) => {
    const buttontotop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
    return (
        <>
            <section className="sec_design section_design2" ref={ref1}>
                <div className="container-fluid mt-0 pt-0 ">
                    <div className="row statsShadow">
                        <div className="col-lg-4 col-md-4 col-4 p-0">
                            <div className="icon_blip">
                                <img src="/assets/TGG_V2/img/car-online.png" alt="" className="sml_img" />
                                <img src="/assets/TGG_V2/img/check.png" alt="" className="sml_img_mob" />
                                <p>Completely Online</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4 p-0">
                            <div className="icon_blip">
                                <img src="/assets/TGG_V2/img/easy.png" alt="" className="sml_img" />
                                <img src="/assets/TGG_V2/img/check.png" alt="" className="sml_img_mob" />
                                <p>Fast & Easy</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4 p-0">
                            <div className="icon_blip">
                                <img src="/assets/TGG_V2/img/nowin.png" alt="" className="sml_img" />
                                <img src="/assets/TGG_V2/img/check.png" alt="" className="sml_img_mob" />
                                <p>NO WIN, NO FEE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="haveyouever">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12 d-none d-md-block">
                            <img src="/assets/TGG_V2/img/img-4.webp" alt="" className="img-fluid" />
                            <div className="experience bounce-y">
                                <div className="text-center">
                                    Your PCP claim
                                    <br /> could be worth <br />
                                    <strong>£ 5,318*</strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 offset-lg-1 align-self-center" id="about_us">
                            <h3>
                                Have you ever wondered about the concept of 'mis-sold' PCP finance?
                            </h3>
                            <p>
                                Well, it turns out that a significant number of UK drivers might be
                                owed financial compensation. The FCA's investigation uncovered
                                instances where individuals were being charged excessively in their
                                monthly finance payments<span className="text-size-pence">^</span>
                            </p>
                            <h4>
                                <strong>Have you had a car on finance between 2007 and 2021?</strong>
                            </h4>
                            <p>
                                You can claim for free, without using a CMC, first to your lender or
                                to the FOS / FSCS
                            </p>
                            <div className="box_button">
                                <a className="button smaller" onClick={buttontotop}>
                                    Check my claim
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    );
}

export default Section1;
import { Api } from "../api/Api";

export const UseSaveLenders = () => {
  const saveLender = async (
    uuid,
    lenders,
    postUrl
  ) => {
    const response = await Api.post("v1/user-Lender-store", {
        uuid,
        lenders,
        postUrl
    });
    return response;
  };
  return { saveLender };
};

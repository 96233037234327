import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "../../assets/TGG_V2/CSS/id_uplaod_custom.scss";
import "../../assets/TGG_V2/CSS/id_uplaod_car_registration.scss";

import ssl from "../../assets/TGG_V2/img/ssl.png";
import security from "../../assets/TGG_V2/img/security.png";
import arrowForward from "../../assets/TGG_V2/img/arrow_forward_ios.svg";

import { useIdUpload } from "../../Hooks/useIdUpload";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { UseLeadApi } from "../../Hooks/UseLeadApi";

import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";

import Header from "../Includes/Layouts/TGG_V2/Header";
import Footer from "../Includes/Layouts/TGG_V2/Footer";
import UploadPopUp from "../Includes/Layouts/TGG_V2/UploadPopUp";
import Loader from "../Includes/Layouts/TGG_V2/Loader";
import GtmDataLayer from "../../Utility/GtmDataLayer";

export default function IdUploadTGGV2() {
  const history = useHistory();
  const { saveIdUpload } = useIdUpload();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const split_name = localStorage.getItem("split_name");
  const { skippedLeads } = useSkippedLeads();
  const { LeadUpdateApi } = UseLeadApi();

  const [showPopUp, setShowPopUp] = useState(false);
  const [submitName, setSubmitName] = useState("");
  const [selectedFileType, setSelectedFileType] = useState();
  const [base64Image, setBase64Image] = useState();
  const [loaderModal, setLoaderModal] = useState(true);


  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaderModal(false);
      window.scrollTo(0, 0);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);


  const handleUploadClick = (e) => {
    const { id } = e.target;
    setSubmitName(id);
    setShowPopUp(true);
  };

  const savedocument = async (e) => {
    setLoaderModal(true);
    setShowPopUp(false);

    try {
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {
        let uploadData = "";
        uploadData = {
          imageFile: base64Image.base64TextString,
          captureType: "doc",
          documentType: submitName,
        };

        await saveIdUpload(
          uploadData,
          visitorParameters,
          formParameters,
          queryString
        );

        await LeadUpdateApi(
          visitorParameters.visitor_parameters.uuid,
          base64Image.base64TextString
        );
      }
    } catch (e) {
      console.warn("error");
      console.warn(e);
    } finally {
      GtmDataLayer({
        question: "verify your identity",
      });
      setTimeout(() => {
        setLoaderModal(false);
        window.scrollTo(0, 0);
        history.push(
          `/pcp/thankyou?uuid=${visitorParameters.visitor_parameters.uuid}`
        );
      }, 1000);
    }
  };

  const skipClick = async () => {
    GtmDataLayer({
      question: "skip idupload",
    });
    try {
      await skippedLeads(
        "skip_ID_Upload",
        visitorParameters.visitor_parameters.uuid,
        split_name
      );
    } catch (error) {
      console.error("Error skipping leads:", error);
    } finally {
      window.scrollTo(0, 0);
      history.push(
        `/pcp/thankyou?uuid=${visitorParameters.visitor_parameters.uuid}`
      );
    }
  };

  return (
    <>
      <GetVisitorsParams />
      <div className="IdUploadTGGV2">
        <div className="__PCPtcg">
          <div className="bg_lol">
            <Header />
            <section className="cs_hero cs_style_1 sec_design section_design1">
              <div className="cs_hero_wrap cs_bg_filed">
                {/* <!--Gradient--> */}
                <div className="container-md">
                  <form id="form">
                    <div className="car-section ">
                      <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-12  8 car-regi-middle mb-5">
                          <div className="id-uploads  " id="IdUploads">
                            <div className=" animate__animated animate__fadeInLeft">
                              <h1 className="title2">
                                Now, let's verify your identity
                              </h1>
                              <div className=" ">
                                <h6 className="upload-sigo mt-4 mb-lg-4 mb-md-4 mb-sm-4 mb-3  ">
                                  To expedite your claim and ensure its
                                  authenticity, please upload a valid ID as part
                                  of your PCP claim submission.
                                </h6>
                                {/* //////////////////// Upload Box */}
                                {/* <div className="upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 nation-card ">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <div className="icon-circle me-3">
                                        <i className="bi bi-person-badge" />
                                      </div>
                                    </div>
                                    <div id="upload">
                                      <h3>National ID Card</h3>
                                      <div
                                        className="click-upload p-1 px-3 clickupload-label"
                                        id="national_id"
                                        onClick={handleUploadClick}
                                      >
                                        Click to upload
                                      </div>
                                    </div>
                                    <div className="forward-angle">
                                      <div
                                        className="close-file"
                                        style={{ display: "none" }}
                                      >
                                        <i className="bi bi-x-lg"> </i>
                                      </div>
                                      <div className="forwardarrow">
                                        <i>
                                          <img
                                            src={arrowForward}
                                            alt="arrowForward"
                                          />
                                        </i>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                {/* //////////////////// Upload Box */}
                                <div className="upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 license">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <div className="icon-circle me-3">
                                        <i className="bi bi-car-front-fill" />
                                      </div>
                                    </div>
                                    <div>
                                      <h3>Driver's License</h3>
                                      <div
                                        className="click-upload p-1 px-3"
                                        id="driversLicence"
                                        onClick={handleUploadClick}
                                      >
                                        Click to upload
                                      </div>
                                    </div>
                                    <div className="forward-angle">
                                      <i>
                                        <img
                                          src={arrowForward}
                                          alt="arrowForward"
                                        />
                                      </i>
                                    </div>
                                  </div>
                                </div>
                                {/* //////////////////// Upload Box */}
                                <div className="upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 passport">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <div className="icon-circle me-3">
                                        <i className="bi bi-airplane" />
                                      </div>
                                    </div>
                                    <div>
                                      <h3>Passport</h3>
                                      <div
                                        className="click-upload p-1 px-3"
                                        id="passport"
                                        onClick={handleUploadClick}
                                      >
                                        Click to upload
                                      </div>
                                    </div>
                                    <div className="forward-angle">
                                      <i>
                                        <img
                                          src={arrowForward}
                                          alt="arrowForward"
                                        />
                                      </i>
                                    </div>
                                  </div>
                                </div>
                                {/* //////////////////// Upload Box */}
                                {/* End Upload Cards */}
                                <div className="row mt-lg-4 mt-md-4 mt-sm-4 mt-5 justify-content-end">
                                  <div className="col-8 ">
                                      <button
                                        id
                                        type="button"
                                        className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn"
                                        onClick={skipClick}
                                      >
                                        Skip
                                      </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h2 className="mt-4 mb-lg-4 mb-md-4 mb-sm-4 mb-3   d-flex mb-2 upload-sigo  ">
                              <div className="me-2">
                                <img
                                  height={45}
                                  src={security}
                                  alt="security"
                                />
                              </div>
                              <div>
                                <h6>
                                  Your ID will be stored securely and used
                                  solely for the purpose of identity
                                  verification.
                                </h6>
                              </div>
                            </h2>
                            <p className="text-lg-start text-md-start  text-sm-center text-center secure-text" />
                            <div className="text-center">
                              <img height={50} src={ssl} alt="ssl" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>

        {showPopUp && (
          <UploadPopUp
            close={() => setShowPopUp(false)}
            submitName={submitName}
            savedocument={savedocument}
            setSelectedFileType={setSelectedFileType}
            setBase64Image={setBase64Image}
          />
        )}
        {loaderModal && <Loader />}
      </div>
    </>
  );
}

import React from "react";
import GTMDataLayer from "../../Utility/GTMDataLayer";
const RadioButton2 = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  validation,
  checked,
  gtm_question,
  gtm_trigger,
  gtm_answer,
  clickevent,
}) => {
  const handleClick = (e) => {
    if (e.target.dataset.gtm_trigger) {
      GTMDataLayer({
        clickEvent: e.target.dataset.clickevent,
        question: e.target.dataset.gtm_question,
        answer: e.target.dataset.gtm_answer,
      });
    }
    onClick(e);
  };
  return (
    <>
      <input
        type="radio"
        className={className}
        name={name}
        id={id}
        value={value}
        onClick={(e) => handleClick(e)}
        ref={validation}
        checked={checked}
        data-gtm_question={gtm_question}
        data-gtm_trigger={gtm_trigger}
        data-gtm_answer={gtm_answer}
        data-clickevent={clickevent}
      />
      <label htmlFor={id} className={labelClassName}>
        {labelName}
      </label>
    </>
  );
};
export default RadioButton2;

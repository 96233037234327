import React from "react";
import Accordion from "react-bootstrap/Accordion";

const AccordSectionV2 = ({ref2}) => {

    return (
        <>
            <section id="faq_sec" className="faq_area" ref={ref2}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <h2>Frequently asked questions</h2>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                            <Accordion>
                                <Accordion.Item eventKey="0" className="margin-15">
                                    <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                                        How do I determine if I qualify for compensation?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        If you've had a finance agreement like PCP or HP in the last
                                        decade, you may be eligible for compensation due to
                                        mis-selling
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="accordion-item margin-15">
                                    <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                                        What criteria must I meet to make a claim?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Your lender must have failed to disclose the commission
                                        details on your agreement(s). If your finance details were
                                        inadequately explained or you faced higher interest rates due
                                        to commissions, you could be eligible for £1,000s
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className="accordion-item margin-15">
                                    <Accordion.Header className="accorstyle fs-6 fw-bold lh-sm">
                                        Does the type of agreement matter (PCP vs. HP)?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Don't worry if you had an HP loan instead of a PCP agreement —
                                        we accept claims for various vehicle finance agreements
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section >

        
        
        
        
        
        </>


    );

}

export default AccordSectionV2;

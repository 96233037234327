import returnPng from "../../../../assets/SoftSearch/img/return.png";
import editPng from "../../../../assets/SoftSearch/img/edit.png";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import ErrorMsg from "./ErrorMsg";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import { Link } from "react-router-dom";
import AddressTextField from "../../../UI/AddressTextField";
import HiddenField from "../../../UI/HiddenField";
import { useLocation } from "react-router-dom";

const Postcode = ({oldPostcode, addressID}) => {
  const { register, errors, trigger, setError,getValues,reset } = useFormContext();
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const postcodeRef = useRef(null);
  const [getAddressList, setGetAddressList] = useState([]);
  const [postcode, setPostcode] = useState("");
  const [getAddress, setGetAddress] = useState([]);
  const [edit, setEdit] = useState("d-none");
  const [updateAddress, setUpdatedAddress] = useState({});
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const [userSelectedAddressID, setUserSelectedAddressID] = useState(null);
  const addressSelectRef = useRef(null);
  const {search}=useLocation();

  const setAddressOptionSelected = (value) => {
    if (addressSelectRef.current) {
      addressSelectRef.current.value = value;
      const event = new Event('change', { bubbles: true });
      addressSelectRef.current.dispatchEvent(event);
    }
  };
useEffect(()=>{
  setPostcode(oldPostcode);             
  async function fetchData() {
    if (oldPostcode) { 
      try{
        const response = await getPostcodeAddress(oldPostcode);
        if (response?.data?.length > 0) {
          setGetAddressList(response?.data);
          setTimeout(() => {
            setAddressOptionSelected(addressID);
          }, 1000);
        }
      }catch(error){
        console.error("Error fetching address:", error);
      } 
    }
  }
  fetchData();
},[oldPostcode]);

useEffect(()=>{
  setAddressOptionSelected(userSelectedAddressID);
},[edit])


  const checkValidation = async (e) => {
    e.preventDefault();
    setGetAddressList([]);
    setGetAddress([]);
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      try {
        const response =await getPostcodeAddress(e.target.value);
        if (response?.data?.status === "0") {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
        if (response?.data?.length > 0) {
          setGetAddressList(response?.data);
        }
      } catch (error) {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
    }
  };
  const handlePostcodeChange = (event) => {
    setPostcode(event?.target?.value);
    if(event?.target?.value == ""){
      setGetAddress([]);
      setGetAddressList([]);
      setEdit("d-none");
    }
  };

  const lookUpAddress = async (e) => {
    const lookupAddrssValue=await trigger("txtPostCode");
    if (lookUpAddress) {
      getPostcodeAddress(getValues("txtPostCode")).then((response)=>{
        if (response?.data?.status === "0") {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
        if (response?.data?.length > 0) {
          setGetAddressList(response?.data);
        }
       
      }).catch((error)=>{
        console.error("Error fetching address:", error);

      })
    }
  };

  const handleAddressSelect = async (event) => {
    event.preventDefault();
    if (event?.target?.value !== "") {
      setUserSelectedAddressID(event?.target?.value);
      const fetchAddress = await getSplitPostcodeAddress(
        event?.target?.value,
        postcode
      );
      setGetAddress([fetchAddress?.data]);
      reset({
        ...getValues(),
        txtHouseNumber: fetchAddress?.data?.line_1,
        txtAddress2: fetchAddress?.data?.line_2,
        txtAddress3: fetchAddress?.data?.line_3,
        txtCounty: fetchAddress?.data?.county,
        txtTown: fetchAddress?.data?.town,
        txtCountry: fetchAddress?.data?.country
      });
      setError("address1", {});
    } else {
      setGetAddress([]);
      setEdit("d-none");
      setError("address1", {
        type: "manual",
        message: "Please select your address",
      });
    }
  };

  const searchAddress = () => {
    setGetAddress([]);
    setGetAddressList([]);
    setPostcode("");
    setEdit("d-none");
    if (postcodeRef.current) {
      postcodeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleUpdateAddress = (event) => {
    const { name, value } = event.target;

    let fieldname = "";
    switch (name) {
      case "txtHouseNumber":
        fieldname = "line_1";

        break;
      case "txtAddress2":
        fieldname = "line_2";
        break;
      case "txtAddress3":
        fieldname = "line_3";
        break;
      case "txtCounty":
        fieldname = "county";
        break;
      case "txtTown":
        fieldname = "town";
        break;
      case "txtCountry":
        fieldname = "country";
        break;
      default:
        fieldname = null;
        break;
    }
    if (fieldname) {
      setUpdatedAddress((prev) => ({ ...prev, [fieldname]: value }));
      reset({
        ...getValues(),
        [name]: value,
      });
    }
  };


  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtDistrict"
        inputId="txtDistrict"
        inputValue={getAddress[0] !== "#" ? getAddress[0]?.district : ""}
        validation={register()}
      />
      <HiddenField
        name="txtAddress3"
        inputId="txtAddress3"
        inputValue={getAddress[0] !== "#" ? getAddress[0]?.line_3 : ""}
        validation={register()}
      />
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={getAddress[0] !== "#" ? getAddress[0]?.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          getAddress[0] !== "#" ? getAddress[0]?.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={getAddress[0] !== "#" ? getAddress[0]?.pz_mailsort : ""}
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={getAddress[0]?.line_3 !== "#" ? getAddress[0]?.line_3 : ""}
      />
    </>
  );

  return (
    <>
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <div
        className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
        style={{ zIndex: 2, position: "relative" }}
      >
        <p className="png_t mb-0">Address Registered with Lender(s)</p>
        <div className="enter_postcode_add animate__animated animate__fadeInUp animate__delay-.5s">
          <div className="postcode_wrap" ref={postcodeRef}>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Postcode"
              id="txtPostCode"
              name="txtPostCode"
              value={postcode}
              onChange={handlePostcodeChange}
              autoComplete="off"
              ref={register({
                required: "Please Enter Postcode",
                pattern: {
                  value:
                    /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                  message: "Please Enter a Valid Postcode",
                },
                minLength: {
                  value: 5,
                  message: "Please Enter a Valid Postcode",
                },
              })}
              onBlur={checkValidation}
            />

            <button
              type="button"
              className="bg_add_click button"
              onClick={lookUpAddress}
            >
              Look Up Address
            </button>
          </div>
          {errors?.txtPostCode && (
            <ErrorMsg message={errors?.txtPostCode?.message} />
          )}
          <div
            className="col-12 pt-3 animate__animated animate__fadeInUp animate__delay-.5s"
            id="selcetAdd"
            style={{ display: getAddressList?.length > 0 ? "block" : "none" }}
          >
            <AddressSelectBox
              className="form-select"
              OptionValue={getAddressList}
              name="address1"
              id="address1"
              onChange={handleAddressSelect}
              myRef={(e) => {
                register(e, { required: "Please select your address" });
                addressSelectRef.current = e;
              }}
            />
            <span className="d-block">
              {errors?.address1 && (
                <ErrorMsg message={errors?.address1?.message} />
              )}
            </span>
            {edit !== "d-block" ? (
              <div
                className="ADD_detail py-3 px-3 animate__animated animate__fadeInUp animate__delay-.5s"
                style={{ display: getAddress?.length > 0 ? "block" : "none" }}
              >
                <p className="ADD_clr_typ">
                  {getAddress[0]?.line_1}
                  <br />
                  {getAddress[0]?.line_2}
                  <br />
                  {getAddress[0]?.line_3}
                  <br />
                  {getAddress[0]?.county}
                  <br />
                  {getAddress[0]?.town}
                  <br />
                  {getAddress[0]?.country}
                  <br />
                </p>
                <HiddenField
                  name="txtHouseNumber"
                  inputId="txtHouseNumber"
                  inputValue={getAddress[0]?.line_1}
                />

                <HiddenField
                  name="txtAddress2"
                  inputId="txtAddress2"
                  inputValue={getAddress[0]?.line_2}
                />
                <HiddenField
                  name="txtAddress3"
                  inputId="txtAddress3"
                  inputValue={getAddress[0]?.line_3}
                />
                <HiddenField
                  name="txtCounty"
                  inputId="txtCounty"
                  inputValue={getAddress[0]?.county}
                />
                <HiddenField
                  name="txtTown"
                  inputId="txtTown"
                  inputValue= {getAddress[0]?.town}
                />
                <HiddenField
                  name="txtCountry"
                  inputId="txtCountry"
                  inputValue={getAddress[0]?.country}
                />
               
                <p className="ADD_na_top mb-0">{getAddress[0]?.postcode}</p>
              </div>
            ) : (
              getAddress?.length > 0 && (
                <>
                  <div
                    className={`${edit} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}
                  >
                    <AddressTextField
                      type="text"
                      placeholder="Address Line 1 *"
                      name="txtHouseNumber"
                      id="txtHouseNumber"
                      className="form-control"
                      dataId="txtHouseNumber"
                      autoComplete="off"
                      validation={register({
                        required: "Please Enter Address Line 1",
                      })}
                      value={getAddress[0]?.line_1 || ""}
                      onChange={handleUpdateAddress}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    {errors?.txtHouseNumber && (
                      <ErrorMsg message={errors?.txtHouseNumber?.message} />
                    )}
                  </div>

                  <div
                    className={` ${edit} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}
                  >
                    <AddressTextField
                      type="text"
                      placeholder="Address Line 2"
                      name="txtAddress2"
                      id="txtAddress2"
                      className="form-control"
                      dataId="txtAddress2"
                      autoComplete="off"
                      validation={register()}
                      validationMsg=""
                      value={getAddress[0]?.line_2 || ""}
                      onChange={handleUpdateAddress}
                    />

                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    {errors?.txtAddress2 && (
                      <ErrorMsg message={errors?.txtAddress2?.message} />
                    )}
                  </div>

                  <div
                    className={` ${getAddress[0]?.line_3? edit :"d-none"} animate__animated animate__fadeInLeft animate__delay-.5s mt-2`}
                  >
                    <AddressTextField
                      type="text"
                      placeholder="Address Line 3 (optional)"
                      name="txtAddress3"
                      id="txtAddress3"
                      className="form-control"
                      dataId="txtAddress3"
                      autoComplete="off"
                      validation={register()}
                      validationMsg=""
                      value={getAddress[0]?.line_3 || ""}
                      onChange={handleUpdateAddress}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    {errors?.txtAddress3 && (
                      <ErrorMsg message={errors?.txtAddress3?.message} />
                    )}
                  </div>

                  <div
                    className={` ${edit} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}
                  >
                    <AddressTextField
                      type="text"
                      placeholder="County"
                      name="txtCounty"
                      id="txtCounty"
                      className="form-control"
                      dataId="txtCounty"
                      autoComplete="off"
                      validation={register()}
                      validationMsg=""
                      value={getAddress[0]?.county || ""}
                      onChange={handleUpdateAddress}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    {errors?.txtCounty && (
                      <ErrorMsg message={errors?.txtCounty?.message} />
                    )}
                  </div>

                  <div
                    className={`${edit}  enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}
                  >
                    <AddressTextField
                      type="text"
                      placeholder="Town *"
                      name="txtTown"
                      id="txtTown"
                      className="form-control"
                      dataId="txtTown"
                      autoComplete="off"
                      validation={register({ required: "Please Enter Town" })}
                      value={getAddress[0]?.town || ""}
                      onChange={handleUpdateAddress}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    {errors?.txtTown && (
                      <ErrorMsg message={errors?.txtTown?.message} />
                    )}
                  </div>
                  <div
                    className={`${edit} enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s`}
                  >
                    <AddressTextField
                      type="text"
                      placeholder="Country *"
                      name="txtCountry"
                      id="txtCountry"
                      className="form-control"
                      dataId="txtCountry"
                      autoComplete="off"
                      validation={register({
                        required: "Please Enter Country",
                      })}
                      value={getAddress[0]?.country || ""}
                      onChange={handleUpdateAddress}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    {errors?.txtCountry && (
                      <ErrorMsg message={errors?.txtCountry?.message} />
                    )}
                  </div>
                </>
              )
            )}
            {getAddress.length > 0 && Object.keys(getAddress[0]).length > 0 && (
              <HiddenFields />
            )}
            {getAddress.length > 0 && (
              <Link to={`/pcp/softsearch${search}`} className="return_back">
              <p className="mb-0 mt-3">
                <span onClick={searchAddress}>
                  <img src={returnPng} alt="return" />
                  <span>Search Again</span>
                </span>
                <span onClick={() => setEdit("d-block")}>
                  <span>Edit</span>
                  <img src={editPng} alt="edit" />
                </span>
              </p>
            </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Postcode;

import React from "react";

const ProgressBar = ({ progressBar }) => {
  return (
    <>
      <div className="position-sticky top_mob_sticky d-block d-md-none">
        <div className="banner text-weight-600">
          <img src="/assets/TGG_V1/img/warning.png" /> £16 billion estimated to
          be paid out
        </div>
      </div>

      <div className={`progress progress_main ${progressBar.status}`}>
        <div className="progress-bar">
          <div id="progressBarInner" style={{ width: progressBar.width }}></div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;

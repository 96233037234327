import React, {
    useState,
    useReducer,
    useRef,
    useContext,
    useEffect,
} from "react";
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../../Components/UI/SelectBox";
import useValidDOB from "../../../../Hooks/useValidDOB";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const DateOfBirth = ({ slideShowLoader,
    className,
    setLoader1,
    backClick,
    textDob,
    trigger,
    validation,
    validationMsg,
    dob_day,
    dob_month,
    dob_yr, 
}) => {

    const [notDobValid, setNotDobValid] = useState(false);
    const { validateDateOfBirth } = useValidDOB();

    const nextSlide = async (e) => {
        let errorFlag = 0;

        var dobdayResult = await trigger("DobDay");
        var DobMonthResult;
        var DobYearResult;
        var dobday = document.getElementById("DobDay").value;
        var dobmonth = document.getElementById("DobMonth").value;
        var dobyear = document.getElementById("DobYear").value;
        if (dobdayResult) {
            DobMonthResult = await trigger("DobMonth");
        } else {
            document.getElementById("DobDay").focus();
            return false;
        }
        if (DobMonthResult) {
            DobYearResult = await trigger("DobYear");
        } else {
            document.getElementById("DobMonth").focus();
            return false;
        }
        if (DobYearResult) {
            const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);

            if (!dobIsValid) {
                document.getElementById("DobDay").focus();
                setNotDobValid(true);
                return false;
            } else {
                setNotDobValid(false);
            }
        } else {
            document.getElementById("DobYear").focus();
        }
        if (!dobdayResult || !DobMonthResult || !DobYearResult) {
            errorFlag = 1;
        }
        if (errorFlag === 0) {
            window.scrollTo(0, 0);
            GtmDataLayer({
                question: "dob",
              });
            slideShowLoader(e);
        }
    };

    return (
        <>


            <div className={`modal ${className}`} id="add_db" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="add_dbLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content animate__animated animate__zoomIn animate__delay-.5s ">

                        <div className="modal-body">

                            <div className="jst_adDf_lx">
                                <div className="slide4 col-12">
                                    <div className="cicle_loader">
                                        <div className="text12">
                                            <h5 className="txt">Verifying your information in accordance with our qualifying criteria.</h5>
                                        </div>
                                        <div className="loader_gif_">
                                            <svg className="lp" viewBox="0 0 128 128" width="128px" height="128px" xmlns="http://www.w3.org/2000/svg">
                                                <defs>
                                                    <linearGradient id="grad1" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="0%" stopColor="#000" />
                                                        <stop offset="100%" stopColor="#fff" />
                                                    </linearGradient>
                                                    <mask id="mask1">
                                                        <rect x="0" y="0" width="128" height="128" fill="url(#grad1)" />
                                                    </mask>
                                                </defs>
                                                <g fill="none" strokeLinecap="round" strokeWidth="16">
                                                    <circle className="lp__ring" r="56" cx="64" cy="64" stroke="#ddd" />
                                                    <g stroke="hsl(215.48deg 70.55% 31.96%)">
                                                        <polyline className="lp__fall-line" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay1" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay2" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay3" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay4" points="64,8 64,120" />
                                                        <circle className="lp__drops" r="56" cx="64" cy="64" transform="rotate(90,64,64)" />
                                                        <circle className="lp__worm" r="56" cx="64" cy="64" transform="rotate(-90,64,64)" />
                                                    </g>
                                                    <g stroke="hsl(215.48deg 70.55% 31.96%)" mask="url(#mask1)">
                                                        <polyline className="lp__fall-line" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay1" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay2" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay3" points="64,8 64,120" />
                                                        <polyline className="lp__fall-line lp__fall-line--delay4" points="64,8 64,120" />
                                                        <circle className="lp__drops" r="56" cx="64" cy="64" transform="rotate(90,64,64)" />
                                                        <circle className="lp__worm" r="56" cx="64" cy="64" transform="rotate(-90,64,64)" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_drop col-lg-12 col-12">
                                <p className="sty_pop">In the meantime, provide your Date of Birth to ensure accuracy in our records.</p>
                                <div className="row Birth_detail animate__animated animate__fadeInUp animate__delay-.5s">
                                    <label htmlFor="">Date of Birth</label>
                                    <div className="col-lg-4 col-md-4 col-12 date_here">
                                        <label htmlFor="DobDay">Day</label>

                                        <SelectBox
                                            className="form-select"
                                            OptionValue={DobDay}
                                            defaultValue={dob_day}
                                            name="DobDay"
                                            id="DobDay"
                                            myRef={validation({
                                                required: "Please Select Date",
                                            })}
                                        />
                                         {validationMsg.DobDay && (
                                        <span className="error_msg " id="email_err">
                                            {validationMsg.DobDay.message}
                                        </span>
                                    )}
                                    </div>
                                   

                                    <div className="col-lg-4 col-md-4 col-12 month_here">
                                        <label htmlFor="DobMonth">Month</label>
                                        <SelectBox
                                            className="form-select"
                                            OptionValue={DobMonth}
                                            defaultValue={dob_month}
                                            name="DobMonth"
                                            id="DobMonth"
                                            myRef={validation({
                                                required: "Please Select Month",
                                            })}
                                        />
                                           {validationMsg.DobMonth && (
                                        <span className="error_msg " id="email_err">
                                            {validationMsg.DobMonth.message}
                                        </span>
                                    )}
                                    </div>
                                 

                                    <div className="col-lg-4 col-md-4 col-12 year_here">
                                        <label htmlFor="DobYear">Year</label>

                                        <SelectBox
                                            className="form-select"
                                            OptionValue={DobYear}
                                            defaultValue={dob_yr}
                                            name="DobYear"
                                            id="DobYear"
                                            myRef={validation({
                                                required: "Please Select Year",
                                            })}
                                        />
                                        {validationMsg.DobYear && (
                                        <span className="error_msg " id="email_err">
                                            {validationMsg.DobYear.message}
                                        </span>
                                    )}
                                    </div>
                                    
                                    <p className="tiny_note mb-0">
                                        <img src="dist/img/info.png" className="pe-1" alt="" />To be eligible to claim you need to be over 25 years old.
                                    </p>
                                </div>
                                {notDobValid && (
                                    <span id="dob_final_err" className="error_msg">
                                        Invalid date of birth provided.
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                            <div className="bg-1">
                                <input type="button" className="button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice" id="nextPlate_007"
                                    value="Continue"
                                    name="DateBirth"
                                    onClick={nextSlide}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );

};

export default DateOfBirth;
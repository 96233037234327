

import React from "react";


const SubmitLoader = ({ submitLoader }) => {
    return (
        <>
                <div className={`loader-div loaderresult loader-fix ${submitLoader}`}>
                    <div className="text-center">
                        <img height="110" src="/assets/TGG_V2/img/loader03.svg" alt="" />
                    </div>
                </div>
        </>
    )

}

export default SubmitLoader;
import React, { useState } from "react";
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../../Components/UI/SelectBox";
import useValidDOB from "../../../../Hooks/useValidDOB";

const DateOfBirth = ({
  slideChange,
  className,
  setLoader1,
  backClick,
  textDob,
  trigger,
  validation,
  validationMsg,
  dob_day,
  dob_month,
  dob_yr,
}) => {
  const [notDobValid, setNotDobValid] = useState(false);
  const { validateDateOfBirth } = useValidDOB();

  const nextSlide = async (e) => {
    let errorFlag = 0;

    var dobdayResult = await trigger("DobDay");
    var DobMonthResult;
    var DobYearResult;
    var dobday = document.getElementById("DobDay").value;
    var dobmonth = document.getElementById("DobMonth").value;
    var dobyear = document.getElementById("DobYear").value;
    if (dobdayResult) {
      DobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (DobMonthResult) {
      DobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (DobYearResult) {
      const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);

      if (!dobIsValid) {
        document.getElementById("DobDay").focus();
        setNotDobValid(true);
        return false;
      } else {
        setNotDobValid(false);
      }
    } else {
      document.getElementById("DobYear").focus();
    }
    if (!dobdayResult || !DobMonthResult || !DobYearResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      setLoader1("show");
      const timer = setTimeout(() => {
        setLoader1("hide");
        window.scrollTo(0, 0);
        slideChange(e);
      }, 1000);
    }
  };
  return (
    <>
      <div className={`step ${className}`} id="step5_1">
        <div className="form-right bounce-y top-up d-block d-md-none">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-none fw-bold">Possible Claim Value</p>
              <h2 className="margin-none fw-bold">£5,318*</h2>
            </div>
          </div>
        </div>
        <div className="form-left">
          <p className="h5 margin-15">
            Provide your Date of Birth to ensure accuracy in our records.
          </p>
          <div className="field padded">
            <label className="h5 margin-10">Date of birth</label>
            <div className="field three">
              <div className="field margin-15">
                <SelectBox
                  className="date_of_birth_select"
                  OptionValue={DobDay}
                  defaultValue={dob_day}
                  name="DobDay"
                  id="DobDay"
                  myRef={validation({
                    required: "Please Select Date",
                  })}
                  validationMsg={
                    validationMsg.DobDay && validationMsg.DobDay.message
                  }
                />
              </div>
              <div className="field margin-15">
                <SelectBox
                  className="date_of_birth_select"
                  OptionValue={DobMonth}
                  defaultValue={dob_month}
                  name="DobMonth"
                  id="DobMonth"
                  myRef={validation({
                    required: "Please Select Month",
                  })}
                  validationMsg={
                    validationMsg.DobMonth && validationMsg.DobMonth.message
                  }
                />
              </div>
              <div className="field margin-15">
                <SelectBox
                  className="date_of_birth_select"
                  OptionValue={DobYear}
                  defaultValue={dob_yr}
                  name="DobYear"
                  id="DobYear"
                  myRef={validation({
                    required: "Please Select Year",
                  })}
                  validationMsg={
                    validationMsg.DobYear && validationMsg.DobYear.message
                  }
                />
              </div>
            </div>
            {notDobValid && (
              <span id="dob_final_err" className="error_msg">
                Invalid date of birth provided.
              </span>
            )}
          </div>
          <div className="form-navigation">
            <ul>
              <li>
                <button
                  type="button"
                  className="button next"
                  name="dateofbirth"
                  id="nextInto6"
                  onClick={nextSlide}
                >
                  Next{" "}
                  <img
                    src="/assets/TGG_V1/img/arrow-right.png"
                    name="dateofbirth"
                    className="px-2"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="button back"
                  id="BackInto5"
                  name="name"
                  onClick={backClick}
                >
                  <img
                    src="/assets/TGG_V1/img/arrow-left.png"
                    className="px-2"
                    name="name"
                  />{" "}
                  Back
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="form-right bounce-y top-up d-none d-md-block">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-none fw-bold">Possible Claim Value</p>
              <h2 className="margin-none fw-bold">£5,318*</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateOfBirth;

const TitleBasedOnDomain = () => {
  const domainName = window.location.hostname;
  let title;
  if (
    domainName === "dev.lookupyourname.co.uk" ||
    domainName === "pre.lookupyourname.co.uk" ||
    domainName === "lookupyourname.co.uk"
  ) {
    title = "Lookup Your Name | Free PCP Check";
  } else if (
    domainName === "dev.freepcpcheck.com" ||
    domainName === "pre.freepcpcheck.com" ||
    domainName === "freepcpcheck.com"
  ) {
    title = "Free PCP Check";
  } else if (
    domainName === "dev.mycarfinance.claims" ||
    domainName === "pre.mycarfinance.claims" ||
    domainName === "mycarfinance.claims"
  ) {
    title = "My Car Finance | Free PCP Check";
  } else {
    title = "TGG-PCP | Free PCP Check";
  }
  return title;
};

export default TitleBasedOnDomain;

import React, {useContext} from "react";
import {Api} from '../api/Api';
import {useErrorWebhook} from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";

export const useSkippedLeads = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid=visitorParameters.visitor_parameters.uuid;
  const { ErrorWebhookTrigger } = useErrorWebhook();
  
    const skippedLeads = async (type,uuid,split_name) => {
      const response = await Api.post("/v1/skipped-leads", {
        uuid,
        type,
        split_name
      }).catch(function (error) {
        const erroMessage = {
          serviceName : 'SKIPPED LEADS',
          errorReason:error.message,
          errorCode:error.code,
          uuid:uuid
        }
        ErrorWebhookTrigger(erroMessage);
      });
      return response;
    };
    return { skippedLeads};
  };
  
import React from "react";
import Header from "../TGG_V1_OLD/Header";


const LenderHeader = () => {
  return (
    <>
      <div className="position-sticky top_mob_sticky d-block d-md-none">
        <div className="banner text-weight-600">
          <img src="/assets/TGG_V1/img/warning.png" /> £16 billion estimated to be paid out
        </div>
      </div>
      <div className="progress progress_main d-none">
        <div className="progress-bar">
          <div id="progressBarInner" style={{ width: '0%' }} />
        </div>
      </div>
      <Header/>
    </>
  );
};

export default LenderHeader;
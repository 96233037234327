import "../../assets/SoftSearch/scss/main.scss";
import notice from "../../assets/SoftSearch/img/notice.png";
import hero from "../../assets/SoftSearch/img/bg-float2.png";
import SelectBox from "../UI/SelectBox";
import { Salutation } from "../../Constants/Questions";
import Postcode from "../Includes/Layouts/SoftSearch/Postcode";
import { FormProvider, useForm } from "react-hook-form";
import { DobMonth, DobYear, Months } from "../../Constants/Constants";
import ErrorMsg from "../Includes/Layouts/SoftSearch/ErrorMsg";
import InputTag from "../Includes/Layouts/SoftSearch/Input";
import { usePhoneValidation } from "../../Hooks/usePhoneValidation";
import useValidDOB from "../../Hooks/useValidDOB";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useContext, useEffect, useRef, useState } from "react";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { queryString } from "../../Utility/QueryString";
import { urlParams } from "../../Utility/QueryString";
import { Api } from "../../api/Api";
import Footer from "../Includes/Layouts/TGG_V1/Footer";
import Header from "../Includes/Layouts/SoftSearch/Header";
import { DateOfBirth } from "../Includes/Layouts/SoftSearch/Constant";
import { useHistory } from "react-router-dom";
import Loader from "../Includes/Layouts/LenderTGGV1/Loader";
import GtmDataLayer from "../../Utility/GtmDataLayer";

export default function SoftSearch() {
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { register, errors, clearErrors, setError, getValues, trigger, reset } =
    formMethods;
  const { phoneValidation } = usePhoneValidation();
  const { validateDateOfBirth } = useValidDOB();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion } = useDataIngestion();
  const updateForm = useRef(null);
  const dateBirth=useRef(null)
  const history=useHistory()
  const values = getValues();
  const uuid = visitorParameters.visitor_parameters.uuid ? visitorParameters.visitor_parameters.uuid : urlParams.get("uuid");
  let splitName=localStorage.getItem('split_name');

const [oldPostcode, setOldPostcode]=useState('');
const [addressID, setAddressID]=useState('');
const [date, setDate]=useState('');
const[analyseModel,setAnalyseModel]=useState('hide')
let count = 0;



  const isNullOrEmpty = (obj) => {
    return (
      obj === null ||
      (Object.keys(obj).length === 0 && obj.constructor === Object)
    );
  };
  const splitString = (string, splitValue = "/") => {
    return string.split(splitValue);
  };

const LeadApiRetry = async () => {
  count += 1;
  try {
    const response = await Api.post(`v1/lead-retry-api?uuid=${uuid}`);

    if (!response.data.response || response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    await Api.post("api-count", {
      uuid : visitorParameters.visitor_parameters.uuid,
      api_type: "LeadRetryApi",
      count: count,
      source: "live"
    });
    
    if (response.data.response) {
      if ("FAIL" === response?.data?.response?.creditCheckResult) {
        setAnalyseModel("hide");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        history.push(`/pcp/softsearch?uuid=${uuid}`);
      } else {
        // if ("PASS" === response?.data?.response?.creditCheckResult) 
        setAnalyseModel("hide")
        if (splitName !== '' && 'TGG_V2' == splitName) {
          history.push(
            `/pcp/lendersTGG_V2?uuid=${uuid}`
          );
        } else {
          history.push(
            `/lendersTGG_V1?uuid=${uuid}`
          );
        }
      }
    }
  } catch (error) {
    console.error("Retry failed error:", error);
    try {
      await Api.post("api-count", {
        uuid : visitorParameters.visitor_parameters.uuid,
        api_type: "LeadRetryApi",
        count: count,
        source: "live"
      });
    } catch (e) {
      console.log("Error updating the API count:", e);
    }

    if (count < 3) {
      // Increment count and retry LeadSendApi
      await LeadApiRetry();
    }
    if(count >= 3){
      history.push(`/lendersTGG_V1?uuid=${uuid}`);
    }
  }
};


  useEffect(() => {
    saveDataIngestion(
      visitorParameters.visitor_parameters,
      {},
      "soft_search_user_store",
      queryString,
      visitorParameters.data
    );
  }, []);



  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");

    if (isNullOrEmpty(storedFormData)) {
      reset(JSON.parse(storedFormData));
    } else {
      if (uuid) {
        Api.post(`/v1/user-info?uuid=${uuid}`)
          .then((response) => {
            const {
              title,
              first_name,
              last_name,
              telephone,
              user_dob,
              postcode,
              address_line1,
              address_line2,
              town,
              county,
              country,
              address_id,
              page_name
            } = response?.data?.response[0];
            
            const [dateOfBirth, monthofBirth, yearofBirth] =
            splitString(user_dob);
            setOldPostcode(postcode.toLowerCase());
            setAddressID(address_id);
            setDate(dateOfBirth);
            const defaultValues = {
              lstSalutation: title,
              txtFName: first_name,
              txtLName: last_name,
              txtPostCode: postcode.toLowerCase(),
              txtHouseNumber: address_line1,
              txtAddress2: address_line2,
              txtCounty: county,
              txtTown: town,
              txtCountry: country,
              DobDay: dateOfBirth,
              DobMonth: Months[parseInt(monthofBirth) - 1],
              DobYear: yearofBirth,
              txtPhone: telephone,
            };
            reset(defaultValues);
            if(splitName){
              splitName=page_name;
            }
          })
          .catch((error) => {
            console.error("Error fetching data from API:", error);
          });
      }
    }
  }, [reset, uuid]);


  const phoneValidCheck = async () => {
    const phone = values["txtPhone"];

    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    let lstSalutationResult = await trigger("lstSalutation");

    let txtFNameResult;
    let txtLNameResult;
    let lstDobDayResult;
    let lstDobMonthResult;
    let lstDobYearResult;
    let lstPostResult;
    let address1Result;
    let txtPhoneResult;
  
    let dobday = document.getElementById("DobDay").value;
    let dobmonth = document.getElementById("DobMonth").value;
    let dobyear = document.getElementById("DobYear").value;

    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);

      if (!dobIsValid) {
        document.getElementById("DobDay").focus();
        setError("DobDay", {
          type: "manual",
          message: "Invalid date of birth provided",
        });
        return false;
      } else {
        
        txtPhoneResult = await trigger("txtPhone");
      }
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    if (txtPhoneResult) {
      lstPostResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (lstPostResult) {
      address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (!address1Result) {
      document.getElementById("address1").focus();
      return false;
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !lstPostResult ||
      !txtPhoneResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = async (e) => {
    const validcheck = await basicDetailsValidation();

    if (validcheck) {
      formSubmit();
    }
  };

  const formSubmit = async () => {
    GtmDataLayer({
      question: "soft search details",
    });
    const form = updateForm.current;
    const formData = getValues();

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtDistrict = form["txtDistrict"].value;
      formData.txtDistrict = txtDistrict;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }
    formData.page_name = "softsearch";
    formData.pCode_manual = form["pCode_EntryType"].value;
    formData.txtTownprev = "";
    formData.txtUdprn = "";
    formData.user_sign = "";
    formData.txtPreName = "";
    formData.txtPrevPostCode = "";
    formData.txtEmail = "";
    formData.txtStreet = "";
    formData.txtCountyprev = "";
    formData.txtCountryprev = "";
    formData.txtAddress3prev = "";
    formData.addressprev1 = "";
    formData.Previousname = "";


    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("fName", formData.txtFName);
      localStorage.setItem("lName", formData.txtLName);
      localStorage.setItem("pCode", formData.txtPostCode);
      localStorage.setItem("addressLine1", formData.txtHouseNumber);
      localStorage.setItem("dobYr", formData.DobYear);
      localStorage.setItem("dobMon", formData.DobMonth);
      localStorage.setItem("dobDy", formData.DobDay);
      localStorage.setItem("addressLine2", formData.txtAddress2);
      localStorage.setItem("postTown", formData.txtTown);
      localStorage.setItem("country", formData.txtCounty);
      localStorage.setItem("title", formData.lstSalutation);
      localStorage.setItem("addressLine3", formData.txtAddress3);
      localStorage.setItem("county", formData.txtCounty);
      localStorage.setItem(
        "district",
        formData.txtDistrict ? formData.txtDistrict : ""
      );
      let UcFname =
        formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);

      saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "soft_search_data_store",
        queryString,
        visitorParameters.data
      );
      setAnalyseModel("show")
      setTimeout(()=>{
        LeadApiRetry();

      },1000)

    } else {
      console.log("Fail");
      return "Fail";
    }
  };


  return (
    <FormProvider {...formMethods}>
      <GetVisitorsParams />
      <div className="soft_search">
        <div className="TGGtcg">
          <div className="bg_grade">
          <Header/>
            <section className="section_create">
              <div className="container-md top_notch">
                <form id="form" ref={updateForm}>
                  <div className="row p-0 m-0">
                    <div className="col-lg-7 col-12">
                      <div className="card">
                        <img src={notice} alt="notice" />
                        <h2>Oops! Soft Search failed</h2>
                        <p className="card-text">
                          We Can't Verify your details....
                        </p>
                        <p className="card-text">
                          We need you to submit your details below in order to
                          confirm your claim.
                        </p>
                        <span>Average Claim: £5,318.25*</span>
                      </div>
                      <h1 className="text-lg-start animate__animated animate__fadeInUp  animate__delay-.5s   cs_fs_94 ">
                        Please enter &amp; check your details below
                      </h1>
                      <h2 className="banner-subtext animate__animated animate__fadeInUp animate__delay-.5s">
                        (Once this step is complete, we will be able to confirm
                        your claim)
                      </h2>
                      <div className="form_drop col-lg-12 col-12">
                        <div className="sect_bt pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                          <div className="row name_detail animate__animated animate__fadeInUp animate__delay-.5s">
                            <div className="col-lg-4 col-md-4 col-12 pb-2">
                              <label htmlFor="format2">Title</label>
                              <SelectBox
                                className="form-select form-control"
                                OptionValue={Salutation}
                                name="lstSalutation"
                                id="lstSalutation"
                                onChange={() => clearErrors("lstSalutation")}
                                clearErrors={clearErrors}
                                myRef={register({
                                  required: "Please select your title",
                                })}
                              />
                              {errors?.lstSalutation && (
                                <ErrorMsg
                                  message={errors?.lstSalutation?.message}
                                />
                              )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 pb-2">
                              <InputTag
                                type="text"
                                id="txtFName"
                                placeholder="First name"
                                styleClass="form-control"
                                title="First Name"
                                aria-label="First name"
                                name="txtFName"
                                myref={register({
                                  required: "Please enter your first name",
                                  minLength: {
                                    value: 3,
                                    message: "Please enter valid first name",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message:
                                      "Your name is invalid. Please recheck",
                                  },
                                })}
                              />
                              {errors?.txtFName && (
                                <ErrorMsg message={errors?.txtFName?.message} />
                              )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 pb-2">
                              <InputTag
                                type="text"
                                id="txtLName"
                                placeholder="Last name"
                                styleClass="form-control"
                                title="Last name"
                                aria-label="Last name"
                                name="txtLName"
                                myref={register({
                                  required: "Please enter your last name",
                                  minLength: {
                                    value: 3,
                                    message: "Please enter valid last name",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message:
                                      "Your name is invalid. Please recheck",
                                  },
                                })}
                              />
                              {errors?.txtLName && (
                                <ErrorMsg message={errors?.txtLName?.message} />
                              )}
                            </div>
                            <div
                            //   className="col-lg-4 col-md-4 col-12 pb-2 w-100"
                            >
                              <fieldset
                               
                                className="p-2 px-3 d-block"
                              >
                                <legend className="px-2 m-0">
                                  Date Of Birth
                                </legend>
                                <div className="row">
                                  <div className="col-4 mb-2">
                                    <SelectBox
                                      className="form-select form-control"
                                      OptionValue={DateOfBirth}
                                      name="DobDay"
                                      id="DobDay"
                                      onChange={() => clearErrors("DobDay")}
                                      clearErrors={clearErrors}
                                      myRef={register({
                                        values:date,
                                        required: "Please select day",

                                      })}
                                    />
                                    {errors?.DobDay && (
                                      <ErrorMsg
                                        message={errors?.DobDay?.message}
                                      />
                                    )}
                                  </div>

                                  <div className="col-4 mb-2">
                                    <SelectBox
                                      className="form-select form-control"
                                      OptionValue={DobMonth}
                                      name="DobMonth"
                                      id="DobMonth"
                                      onChange={() => clearErrors("DobMonth")}
                                      clearErrors={clearErrors}
                                      myRef={register({
                                        required: "Please select month",
                                      })}
                                    />
                                    {errors?.DobMonth && (
                                      <ErrorMsg
                                        message={errors?.DobMonth?.message}
                                      />
                                    )}
                                  </div>

                                  <div className="col-4 mb-2">
                                    <SelectBox
                                      className="form-select form-control"
                                      OptionValue={DobYear}
                                      name="DobYear"
                                      id="DobYear"
                                      onChange={() => clearErrors("DobYear")}
                                      clearErrors={clearErrors}
                                      myRef={register({
                                        required: "Please select year",
                                      })}
                                    />
                                    {errors?.DobYear && (
                                      <ErrorMsg
                                        message={errors?.DobYear?.message}
                                      />
                                    )}
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            <div className="col-12 pb-2">
                              <InputTag
                                type="tel"
                                id="txtPhone"
                                styleClass="form-control"
                                title="Mobile Number:"
                                placeholder="Mobile Phone Number"
                                aria-label="Phone Number"
                                aria-describedby="Phone-Number"
                                name="txtPhone"
                                myref={register({
                                  minLength: {
                                    value: 10,
                                    message:
                                      "Please enter a valid phone number",
                                  },
                                  pattern: {
                                    value: /^[0-9]*$/i,
                                    message:
                                      "Please enter a valid phone number",
                                  },
                                  required: "Please enter a valid phone number",
                                })}
                                maxLength="11"
                                onBlur={phoneValidCheck}
                              />
                              {errors?.txtPhone && (
                                <ErrorMsg message={errors?.txtPhone?.message} />
                              )}
                            </div>
                          </div>
                        </div>
                        <Postcode oldPostcode={oldPostcode} addressID={addressID}/>
                        <p className="margin-none my-4 text-end">
                          <button
                            type="button"
                            className="main-btn p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky pulse2"
                            onClick={handleSubmit}
                          >
                            <span> Submit</span>
                          </button>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-12 text-center d-none d-lg-block">
                      <div className="img_float animate__animated animate__fadeInUp ">
                        <div className="banner-wrap">
                          <img src={hero} alt="Hero" className=" " />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
          <div className="mt-3">
          <Footer/>
          </div>
        </div>       
      </div>
      <div className="TGG_V1">
      <Loader LoaderLender={analyseModel} />
      </div>
      </FormProvider>
  );
}

import React, { useContext, useState } from "react";
import { Salutation } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";

const Name = ({
  slideChange,
  className,
  backClick,
  setLoader1,
  validation,
  validationMsg,
  clearErrors,
  setError,
  trigger,
}) => {
  let bank = localStorage.getItem("bankName");
  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");
    var txtFNameResult;
    var txtLNameResult;
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (!lstSalutationResult || !txtFNameResult || !txtLNameResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      window.scrollTo(0, 0);
      setLoader1("show");
      const timer = setTimeout(() => {
        setLoader1("hide");
        slideChange(e);
      }, 1000);
    } else {
      return false;
    }
  };
 
  return (
    <>
      <div className={`step ${className}`} id="step_5">
        <div className="form-right bounce-y top-up d-block d-md-none">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-none fw-bold">Possible Claim Value</p>
              <h2 className="margin-none fw-bold">£5,318*</h2>
            </div>
          </div>
        </div>
        <div className="form-left">
          <p className="h5 margin-15">
            To better assist you and personalize your experience! may we know
            your name?
          </p>
          <div className="field padded">
            <div className="field margin-15">
              <label className="h5 margin-10">Title</label>
              <SelectBox
                className="bordercls"
                OptionValue={Salutation}
                name="lstSalutation"
                id="lstSalutation"
                autocomplete="off"
                onChange={() => clearErrors("lstSalutation")}
                clearErrors={clearErrors}
                myRef={validation({ required: "Please Select Title" })}
                validationMsg={
                  validationMsg.lstSalutation &&
                  validationMsg.lstSalutation.message
                }
              ></SelectBox>
            </div>
            <div className="field margin-15">
              <label className="h5 margin-10">First name</label>
              <div className="field-icon">
                <TextField
                  type="text"
                  className="capitalised-input"
                  placeholder="First Name"
                  name="txtFName"
                  id="txtFName"
                  autocomplete="off"
                  validation={validation({
                    required: "Please Enter First Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid First Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}
                ></TextField>
                {validationMsg.txtFName && (
                  <span className="error_msg " id="email_err">
                    {validationMsg.txtFName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="field margin-15">
              <label className="h5 margin-10">Last name</label>
              <div className="field-icon">
                <TextField
                  type="text"
                  className="capitalised-input"
                  placeholder="Last Name"
                  name="txtLName"
                  id="txtLName"
                  autocomplete="off"
                  validation={validation({
                    required: "Please Enter Last Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid Last Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}
                ></TextField>
                {validationMsg.txtLName && (
                  <span className="error_msg " id="email_err">
                    {validationMsg.txtLName.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-navigation">
            <ul>
              <li>
                <button
                  type="button"
                  className="button next"
                  name="name"
                  id="nextInto5_1"
                  onClick={basicDetailsValidation}
                >
                  Next{" "}
                  <img
                    src="/assets/TGG_V1/img/arrow-right.png"
                    name="name"
                    className="px-2"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="button back"
                  name="postcode"
                  id="BackInto4"
                  onClick={backClick}
                >
                  <img
                    src="/assets/TGG_V1/img/arrow-left.png"
                    name="postcode"
                    className="px-2"
                  />{" "}
                  Back
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="form-right bounce-y top-up d-none d-md-block">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-none fw-bold">Possible Claim Value</p>
              <h2 className="margin-none fw-bold">£5,318*</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Name;

import logoLookupyourname from "../../../assets/TGG_V1_OLD/img/lookupname-black-blue.png";
import logoFreepcpcheck from "../../../assets/TGG_V1_OLD/img/freepcpcheck-blue-black.png";
import logoMycarfinance from "../../../assets/TGG_V1_OLD/img/mycar-finance-black-blue.png";

const LogoBasedOnDomain = (props) => {
  const {
    className,
    // bg,
    style = null,
    // verticalAlign = null,
    // width = null,
    height = null,
  } = props;
  const domainName = window.location.hostname;
  let logo;
  if (
    domainName === "localhost" ||
    domainName === "dev.lookupyourname.co.uk" ||
    domainName === "pre.lookupyourname.co.uk" ||
    domainName === "lookupyourname.co.uk"
  ) {
    logo = logoLookupyourname;
  } else if (
    domainName === "dev.freepcpcheck.com" ||
    domainName === "pre.freepcpcheck.com" ||
    domainName === "freepcpcheck.com"
  ) {
    logo =logoFreepcpcheck;
  } else if (
    domainName === "dev.mycarfinance.claims" ||
    domainName === "pre.mycarfinance.claims" ||
    domainName === "mycarfinance.claims"
  ) {
    logo = logoMycarfinance;
  } else {
    logo = logoLookupyourname;
  }
  const imgProps = {
    src: logo,
    // alt: "",
    className: className,
    ...(style && { style }),
    // ...(verticalAlign && { verticalAlign }),
    // ...(width && { width }),
    ...(height && { height }),
  };
  return <img {...imgProps} />;
};

export default LogoBasedOnDomain;

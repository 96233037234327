import React from "react";
import fileUrl from "../../../assets/pdf/terms.pdf";

const TermsPdf = () => {
  return (
    <>
      <div className="TermsPdf">
        <iframe
          src={fileUrl}
          width="100%"
          height="800"
          style={{ border: "none" }}
          title="Terms and Conditions"
        />
      </div>
    </>
  );
};

export default TermsPdf;

import { useEffect, useState } from "react";

import demoUpload from "../../../../assets/img/upload-demo.jpg";

const preventScroll = () => {
  document.body.style.overflow = "hidden";
};

const UploadPopUp = ({
  close,
  submitName,
  savedocument,
  setSelectedFileType,
  setBase64Image,
}) => {

  const [imageLoder, setImageLoader] = useState("none");
  const [fileText, setFileText] = useState(
    "JPG, PNG, or PDF, smaller than 10MB"
  );
  const [chooseItems, setChooseItems] = useState("show");
  const [choosedFilebody, setChoosedFilebody] = useState(false);
  const [reupload, setReupload] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    preventScroll();
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const handleChange = (event) => {
    setImageLoader("");
    setFileText("Image Uploading...");
    setChooseItems("d-none");

    setTimeout(() => {
      setImageLoader("none");
      setChoosedFilebody(true);
      setReupload(true);
      
      let imageFile = event.target.files[0];
      if (imageFile) {
      setImagePreview(URL.createObjectURL(imageFile));

      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;

      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setErrorMessage(
          "The uploaded ID type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
      }

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    }
  }, 2000);
  };

  const reUpload = () => {
    setFileText("JPG, PNG, or PDF, smaller than 10MB");
    setChoosedFilebody(false);
    setReupload(false);
    setChooseItems("show");
    setImagePreview("");
    setErrorMessage("");
  };

  return (
    <>
      <div className="modal-open">
        <div
          className="modal fade-scale view show"
          id="uploadpop"
          data-bs-backdrop="static"
          data-bs-keyboard={false}
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-modal={true}
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body file-upload-body ">
                <h2 className="d-flex  title pt-2 ">
                  Upload your files
                  <div
                    data-bs-dismiss="modal"
                    className="close-btn"
                    onClick={close}
                  >
                    <i className="bi bi-x-circle" />
                  </div>
                </h2>
                {/* Uploads Box */}
                <div className="file-uploads-box text-center p-3 my-3">
                  {choosedFilebody ? (
                    <div className="choosed-filebody">
                      {errorMessage !== "" ? (
                        <img src={demoUpload} alt="demo upload" />
                      ) : (
                        <img
                          src={imagePreview}
                          alt="uploadPreview"
                          style={{ width: "100%", padding: "0px 69px" }}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="choose-filebody">
                      <input
                        type="file"
                        className="iput-file"
                        name
                        id="choosefile"
                        onChange={handleChange}
                        accept="image/jpeg,image/png"
                      />
                      <div>
                        <i className="bi bi-cloud-arrow-up" />
                      </div>
                      <div className="file-text">{fileText}</div>
                      <div
                        className="text-center my-1 imgloader"
                        style={{ display: imageLoder }}
                      >
                        <div
                          className="progress"
                          role="progressbar"
                          aria-label="Animated striped example"
                          aria-valuenow={75}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        >
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <div className={`my-3 choose-items ${chooseItems} view`}>
                        <span className="choose-file p-2 px-3">
                          Choose File
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {errorMessage !== "" && (
                  <p className="error-msg mt-2">{errorMessage}</p>
                )}
                <div className="row ">
                  <div className="col-6 pe-0">
                    {reupload ? (
                      <button
                        id
                        className=" com-button px-1 takephoto reupload w-100 skipbtn "
                        onClick={reUpload}
                      >
                        <i className="bi bi-arrow-repeat me-2 ms-0" /> Re Upload
                      </button>
                    ) : (
                      <button
                        id
                        className=" com-button px-1 takephoto  w-100 skipbtn "
                      >
                        <label>
                          <i className="bi bi-camera-fill me-2 ms-0" /> Take
                          Photo
                          <input
                            type="file"
                            accept="image/*"
                            capture="camera"
                            name="IDCard_doc_cam"
                            onChange={handleChange}
                            onClick={(e) => (e.target.value = null)}
                            style={{
                              opacity: 0,
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              cursor: "pointer",
                            }}
                          />
                        </label>
                      </button>
                    )}

                    <button
                      id
                      className=" com-button px-1 takephoto reupload  w-100 skipbtn "
                      style={{ display: "none" }}
                    >
                      <i className="bi bi-arrow-repeat me-2 ms-0" /> Re Upload
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      id="fileuplSubmit"
                      className="com-button  w-100"
                      name={submitName}
                      disabled={
                        (imagePreview === "" && errorMessage === "") ||
                        errorMessage !== ""
                      }
                      onClick={savedocument}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" />
    </>
  );
};

export default UploadPopUp;

import React, { useState, useEffect } from "react";
import CheckBox from "../../../UI/CheckBox";
import { useSkippedLeads } from "../../../../Hooks/useSkippedLeads";
import { useHistory } from "react-router-dom";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const FormLenderTGGV1_NEW = ({ setLenderSelectedList, LenderSelectedList, SaveLenderFn, uuid, split_name }) => {
    const { skippedLeads } = useSkippedLeads();
    const history = useHistory();
    const [checkboxes, setCheckboxes] = useState({});
    const [errorMsg, setErrorMessage] = useState('');
    const [lenderList, setLenderList] = useState([]);
    const [UserSelectedArray, setUserSelectedArray] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);



    // useEffect(() => {

    //     const ApiLenders = JSON.parse(localStorage.getItem('ApiLenders')) || [];
    //     const storedUnselectedLenders = JSON.parse(localStorage.getItem('UnselectedLender')) || [];
    //     const storedLenderArray = JSON.parse(localStorage.getItem('Lenders')) || [];

    //     console.log("UserSelectedLender ", storedLenderArray);
    //     console.log("APILender :: ", ApiLenders);

    //     // Combine UserSelected and ApiLenders arrays
    //     const combinedArray = [...storedLenderArray, ...ApiLenders];

    //     // Create a map to track unique lender names
    //     const uniqueLendersMap = new Map();

    //     // Add lenders to uniqueLendersMap, skipping duplicates based on lenderName
    //     combinedArray.forEach(lender => {
    //         if (!uniqueLendersMap.has(lender.lenderName)) {
    //             uniqueLendersMap.set(lender.lenderName, lender);
    //         }
    //     });

    //     const uniqueLenders = Array.from(uniqueLendersMap.values());

    //     console.log("uniqueLenders", uniqueLenders);


    //     // Filter storedUnselectedLenders for names not in combinedArray

    //     const getRandomElements = (array, count) => {
    //         const shuffled = array.sort(() => 0.5 - Math.random());
    //         return shuffled.slice(0, count);
    //     };

    //     const filteredUnselectedLenders = storedUnselectedLenders.filter(lender => !uniqueLendersMap.has(lender));
    //     console.log("filteredUnselectedLenders", filteredUnselectedLenders);
    //     // const randomLenderNames = getRandomElements(filteredUnselectedLenders, 3)
    //     // .map(lender => ({  lender}));

    //     const randomLenderNames = getRandomElements(filteredUnselectedLenders, 3)
    //         .map((lender) => ({
    //             id: lender.id,
    //             lenderName: lender.lenderName,
    //         }));

    //     console.log("randomLenderName s", randomLenderNames);
    //     const finalLenderList = [...uniqueLenders, ...randomLenderNames];

    //     setLenderList(finalLenderList);
    //     const initialCheckboxes = {};
    //     finalLenderList.forEach(lender => {
    //         initialCheckboxes[lender.lenderName] = false;
    //     });
    //     setCheckboxes(initialCheckboxes);
    //     setUserSelectedArray(storedLenderArray);
    // }, []);


    useEffect(() => {
        let ApiLenders;
        const storedApiLenders = localStorage.getItem('ApiLenders');

        if (storedApiLenders) {
        try {
            ApiLenders = JSON.parse(storedApiLenders);
        } catch (error) {
            console.error("Error parsing JSON from localStorage:", error);
            ApiLenders = [];
        }
        } else {
        ApiLenders = [];
        }
        // Retrieve data from localStorage
        //const ApiLenders = JSON.parse(localStorage.getItem('ApiLenders')) || [];
        const storedLenderArray = JSON.parse(localStorage.getItem('Lenders')) || [];
        const storedUnselectedLenders = JSON.parse(localStorage.getItem('UnselectedLender')) || [];
    
        console.log("UserSelectedLender ", storedLenderArray);
        console.log("APILender :: ", ApiLenders);
    
        // Create a set of lender names from storedLenderArray for quick lookup
        const storedLenderNamesSet = new Set(storedLenderArray.map(lender => lender.lenderName));
    
        // Filter ApiLenders to keep only those not in storedLenderArray
        const filteredApiLenders = ApiLenders.filter(lender => !storedLenderNamesSet.has(lender.lenderName));
        
        console.log("Filtered API Lenders: ", filteredApiLenders);

        const combinedLenderNamesSet = new Set([
            ...storedLenderArray.map(lender => lender.lenderName),
            ...ApiLenders.map(lender => lender.lenderName)
        ]);
    
        // Function to get random elements from an array
        const getRandomElements = (array, count) => {
            const shuffled = array.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, count);
        };

        
    
        // Get 3 random lenders from storedUnselectedLenders
        const randomLenderNames = getRandomElements(storedUnselectedLenders, 3)
            .map(lender => ({
                id: lender.id,
                lenderName: lender.lenderName,
            }));
            
    
        console.log("randomLenderNames", randomLenderNames);
    
        // Combine filtered ApiLenders with randomLenderNames
        const finalLenderList = [...filteredApiLenders, ...randomLenderNames];
    
        console.log("finalLenderList", finalLenderList);
    
        // Set the final lender list and initialize checkboxes
        setLenderList(finalLenderList);
        const initialCheckboxes = {};
        finalLenderList.forEach(lender => {
            initialCheckboxes[lender.lenderName] = false;
        });
        setCheckboxes(initialCheckboxes);
        setUserSelectedArray(storedLenderArray);
    }, []);
    


    const handleCheckboxChange = (e) => {
        const selectedLenderName = e.target.name;
        const selectedLenderValue = e.target.value;
        const updatedCheckboxes = { ...checkboxes, [selectedLenderName]: e.target.checked };
        setCheckboxes(updatedCheckboxes);

        let updatedList = [...LenderSelectedList];
        if (e.target.checked) {
            updatedList = [...LenderSelectedList, { lenderName: selectedLenderName, lender_value: selectedLenderValue }];
        } else {
            updatedList = LenderSelectedList.filter(option => option.lenderName !== selectedLenderName);
        }
        setLenderSelectedList(updatedList);

        // const updatedLenderList = lenderList.map(lender =>
        //     lender.lenderName === selectedLenderName
        //         ? { ...lender, checked: e.target.checked }
        //         : lender
        // );
        // setLenderList(updatedLenderList);

        // const unselectedLenders = updatedLenderList
        //     .filter(lender => !lender.checked)
        //     .map(lender => lender.lenderName);
        // localStorage.setItem('UnselectedLender', JSON.stringify(unselectedLenders));

        let final_array = (UserSelectedArray.concat(updatedList));
        localStorage.setItem('Lenders', JSON.stringify(final_array));
    };

    const handleSubmit = (e) => {
        let selectedCount = LenderSelectedList.length;
        if (selectedCount === 0) {
            setErrorMessage('Please Choose an Option');
        } else {
            setErrorMessage('');
            setIsDisabled(true);
            SaveLenderFn(e);
        }
    };

    const SkipLender = async (e) => {
        GtmDataLayer({
            question: "skip lender",
          });
        const skippedLeadsResponse = await skippedLeads("skipLender", uuid, split_name);
        if (skippedLeadsResponse.data.status === "Success") {
            history.push("/register-car-v1?uuid=" + uuid);
        }
    }

    // const SkipLenderDummy = async(e) =>{
    //     history.push("/register-car-v1?uuid=" + uuid);
    // }

    return (
        <>
            <div className="form-left">
                <div className="field">
                    <label className="h5 margin-5">Many of our customers have financed their cars with these popular lenders. Please select any that apply to you.</label>
                    <p className="text-size-smaller fst-italic margin-15"><i>(If this doesn't apply to you, please proceed by clicking the skip button)</i></p>
                    <ul className="options two lender">
                        {lenderList.map((lender, index) => (
                            <li key={index}>
                                {/* <label htmlFor={lender.lenderName}>
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        labelClassName={lender.lenderName}
                                        CheckBoxText={lender.lenderName}
                                        labelName={lender.lenderName}
                                        dataId={lender.lenderName}
                                        checked={checkboxes[lender.lenderName]}
                                        name={lender.lenderName}
                                        value={lender.lenderName}
                                        onChange={handleCheckboxChange}
                                    />
                                </label> */}
                                <label htmlFor={lender.lenderName}>
                                    <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        id={lender.lenderName}
                                        checked={checkboxes[lender.lenderName]}
                                        name={lender.lenderName}
                                        value={lender.lenderName}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className={lender.lenderName} htmlFor={lender.lenderName} dangerouslySetInnerHTML={{ __html: lender.lenderName }} />
                                </label>
                            </li>
                        ))}
                    </ul>
                    {errorMsg && (
                        <label id="refund_previously-error" className="mt-2 error_msg text-center" htmlFor="refund_previously">
                            {errorMsg}
                        </label>
                    )}
                </div>
                <div className="form-navigation">
                    <ul>
                        <li>
                            <button type="button"
                                className="button next"
                                id="nextIntoId"
                                onClick={handleSubmit}
                                data-quest-id={11}
                                disabled={isDisabled} 
                            >
                                Next
                                <img src="/assets/TGG_V1/img/arrow-right.png" alt className="px-2" />
                            </button>
                        </li>
                        <li>
                            <button type="button" name="skippedLender" onClick={SkipLender} className="button back ps-0 ps-md-3" id="BackIntosign">
                                Skip
                                <img src="/assets/TGG_V1/img/skip.png" alt className />
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="form-right bounce-y top-up d-none d-md-block">
                    <div className="badge">
                        <div className="content">
                            <p className="fs-6 mb-0 text-weight-600 d-flex justify-content-center text-start">
                                <img src="/assets/TGG_V1/img/information-button.png" alt className="w-auto mt-1" />&nbsp; Additional information required to
                            </p>
                            <p className="fs-6 margin-5 text-weight-600 d-flex justify-content-center text-start">
                                <strong>expedite</strong> &nbsp;your claim process.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormLenderTGGV1_NEW;

import React from "react";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Loader1 = ({ loader1, loader2 }) => {
  return (
    <>
      <div className={`loading-step ${loader1}`}>
        <div className="loading-spinner"></div>
        <div className="overlay"></div>
      </div>

      <div className={`loading-step ${loader2}`}>
        <div className="loading-spinner">
          {/* <img src="/assets/TGG_V1/img/logo.png" className="loader_logo" /> */}
          <LogoBasedOnDomain className="loader_logo"/>
        </div>
        <div className="overlay">
          <p className="fw-bold showBefore_add">
            Verifying your answers in accordance with our qualifying criteria.
          </p>
        </div>
      </div>
    </>
  );
};

export default Loader1;

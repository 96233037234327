import React from "react";
import "../../../assets/NewTerms/css/style1.css";
import terms from "../../../assets/pdf/PcpCourmacs LOA _DBA.pdf";

const Terms = () => {
  return (
    <>
      <div className="NewTerms">
        <div>
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <div className="row">
                <div className="offset-md-3 col-6 ">
                  <h4 className="my-4"> LETTER OF AUTHORITY </h4>
                </div>
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <img
                    className="logo-img"
                    src="../../assets/NewTerms/img/logo.jpg"
                    alt
                  />
                </div>
              </div>
              <p>
                This letter of Authority will be sent to your finance provider
                to inform them that you give Courmacs Legal Limited authority to
                act on your behalf in relation to your car finance complaint(s).
                It’s very important that it is completed in full by you and
                signed by you and any additional account holder where
                applicable.
              </p>
              <table className="table table-striped table-1">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}> Client Name:</td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      Previous Name: <br />
                      (if applicable)
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td> Date of Birth:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Current Address: </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 1:</td>
                    <td />
                  </tr>
                  <tr>
                    <td> Finance Provider:</td>
                    <td />
                  </tr>
                  <tr>
                    <td> Agreement Number:</td>
                    <td />
                  </tr>
                </tbody>
              </table>
              <p className="text-decoration-underline">
                <b> Instructions &amp; Declarations </b>
              </p>
              <p>
                I authorise Courmacs Legal Limited as my/our sole representative
                to deal with my car finance complaint(s) as noted above. I
                confirm that Courmacs Legal Limited is instructed to pursue all
                aspects of my complaint(s) as they consider necessary in
                connection with your organisation(s) and have my complete and
                absolute authority to submit a complaint (s) by way of DISP
                and/or to the Financial Ombudsman Service on my behalf in
                respect of this matter. I have read, understand, and agree to
                Courmacs Legal Limited’s Terms and Conditions. I understand that
                Courmacs Legal Limited will charge a fee, to me, of 45%
                including value-added tax in respect of their legal charges in
                respect of my complaint(s) and have authorised Courmacs Legal
                Limited to deduct this sum from any redress monies I receive. I
                give them full authority to act on my behalf to pursue all
                aspects they deem necessary in relation to my complaint(s). I
                authorise you to accept any signatures on documents sent to you
                by Courmacs Legal Limited which have been obtained
                electronically (e-signed).
              </p>
              <p>
                I confirm that the information given in this letter and the
                accompanying letter(s) of complaint(s) to include any evidence/
                documentation presented in support thereof is to the best of my
                knowledge and belief accurate, truthful, and correct.
              </p>
              <div className="row">
                <div className="col-4 d-flex flex-column  align-items-center">
                  <p className="violet_p m-0"> Phone number</p>
                  <p className="gray_p"> 0330 341 0481 </p>
                </div>
                <div className="col-4 d-flex flex-column  align-items-center">
                  <p className="violet_p m-0"> Offices address </p>
                  <p className="gray_p">
                    Alexander House, Beehive Trading Park Haslingden Rd,
                    Blackburn, BB1 2EE
                  </p>
                </div>
                <div className="col-4 d-flex flex-column  align-items-center">
                  <p className="violet_p m-0"> Mail</p>
                  <p className="gray_p"> info@courmacslegal.co.uk</p>
                </div>
              </div>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-2 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>I hereby authorise and instruct:</p>
              <p> You to: -</p>
              <ol>
                <li>
                  Liaise exclusively with Courmacs Legal Limited in respect of
                  all aspects of my complaint(s).
                </li>
                <li>
                  Immediately release to Courmacs Legal Limited any
                  information/documentation relating my complaint(s) which may
                  be requested by them either by telephone or in writing
                  (including fax or email). This includes information in
                  response to a request made under Section 45 of the Data
                  Protection Act 2018 and Article 15 of the General Data
                  Protection Regulations.
                </li>
                <li>
                  Contact Courmacs Legal Limited in relation to whatever further
                  information they may require.
                </li>
                <li>
                  Release any monies recovered on my behalf directly to Courmacs
                  Legal Limited by way of a BACS payment or Cheque as my
                  instructed solicitors and for the amount due to me to be
                  deposited into Courmacs Legal Limited designated Client
                  Account below.
                  <p className="ms-3 mt-3">
                    {" "}
                    <b> Account Name:</b> Client Deposit Account Courmacs Legal
                    Ltd
                  </p>
                  <p className="ms-3">
                    {" "}
                    <b> Bank or Building Society:</b> HSBC UK Bank Plc
                  </p>
                  <p className="ms-3">
                    {" "}
                    <b>Sort Code:</b> 40-11-18
                  </p>
                  <p className="ms-3">
                    <b> Account Number:</b> 43678571
                  </p>
                </li>
              </ol>
              <p>
                {" "}
                I confirm that I have not previously made a complaint(s) in
                relation to the matter to which this Letter of Authority
                relates.
              </p>
              <p>Please sign in the section below:</p>
              <table className="table table-striped table-1">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}> Name: </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Signed: </td>
                    <td />
                  </tr>
                  <tr>
                    <td> Dated:</td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-3 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <div className="text-end">
                <img
                  className="logo-img"
                  src="../../assets/NewTerms/img/logo.jpg"
                  alt
                />
              </div>
              <div className="text-center">
                <h5>
                  {" "}
                  DAMAGES BASED AGREEMENT <br /> (DBA) EXPLANATION
                </h5>
                <p style={{ color: "#5a2b86" }}>
                  THIS IS A SUMMARY OF THE RELEVANT INFORMATION YOU MUST READ
                  THE ENTIRE <br />
                  DOCUMENT BEFORE AGREEING AND SIGNING.
                </p>
                <div className="d-flex">
                  <div className="img-div">
                    {" "}
                    <img
                      className="w-100"
                      src="../../assets/NewTerms/img/image-1.png"
                      alt
                    />
                  </div>
                  <div className="violet_bg">
                    <p>
                      The Damages Based Agreement (DBA) can also be described as
                      a No Win <br />
                      No Fee agreement.
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="img-div">
                    {" "}
                    <img
                      className="w-100"
                      src="../../assets/NewTerms/img/image-2.png"
                      alt
                    />
                  </div>
                  <div className="violet_bg">
                    <p>
                      Courmacs Legal Ltd only get paid for the work carried out
                      on your behalf If they win your claim.
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="img-div">
                    {" "}
                    <img
                      className="w-100"
                      src="../../assets/NewTerms/img/image-3.png"
                      alt
                    />
                  </div>
                  <div className="violet_bg">
                    <p>
                      {" "}
                      IF YOUR CLAIM IS <b>NOT</b> SUCCESSFUL
                    </p>
                    <p className="text-start">
                      You do not have to pay <b> Courmacs Legal Ltd</b> anything
                      for the work they have done <b>if you have not</b>:
                    </p>
                    <ul>
                      <li className="text-start"> Misled them; or</li>
                      <li className="text-start">
                        Failed to cooperate with them (i.e., failing to provide
                        instructions when required)
                      </li>
                    </ul>
                    Provided that you have complied with your obligations under
                    the DBA:
                    <ul>
                      <li className="text-start">
                        {" "}
                        <b>Courmacs Legal Ltd</b> will not be paid for the work
                        done on your behalf.
                      </li>
                      <li className="text-start">
                        You will not have to pay anything.
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="img-div">
                    {" "}
                    <img
                      className="w-100"
                      src="../../assets/NewTerms/img/image-4.png"
                      alt
                    />
                  </div>
                  <div className="violet_bg">
                    <p>
                      {" "}
                      IF YOUR CLAIM <b>IS</b> SUCCESSFUL
                    </p>
                    <p>
                      <b>Courmacs Legal Ltd</b> will receive the relevant % +
                      VAT of the financial benefit obtained from your opponent
                      as set out in the attached letter.
                    </p>
                    <p className="text-start">
                      {" "}
                      You will pay from the financial benefit obtained:{" "}
                    </p>
                    <ul>
                      <li className="text-start">
                        {" "}
                        The relevant % + VAT of the financial benefit obtained
                        from your opponent.
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="img-div">
                    {" "}
                    <img
                      className="w-100"
                      src="../../assets/NewTerms/img/image-5.png"
                      alt
                    />
                  </div>
                  <div className="violet_bg">
                    <p>
                      <b> EXPENSES </b>
                    </p>
                    <p>
                      Although we do not expect to incur any third-party
                      expenses if we do, we will not expect you to pay these.
                    </p>
                    <p />
                  </div>
                </div>
                <h4 className="mt-4"> TERMS OF ENGAGEMENT</h4>
                <div className="row">
                  <div className="col-4 d-flex flex-column  align-items-center">
                    <p className="violet_p m-0"> Phone number</p>
                    <p className="gray_p"> 0330 341 0481 </p>
                  </div>
                  <div className="col-4 d-flex flex-column  align-items-center">
                    <p className="violet_p m-0"> Offices address </p>
                    <p className="gray_p">
                      Alexander House, Beehive Trading Park Haslingden Rd,
                      Blackburn, BB1 2EE
                    </p>
                  </div>
                  <div className="col-4 d-flex flex-column  align-items-center">
                    <p className="violet_p m-0"> Mail</p>
                    <p className="gray_p"> info@courmacslegal.co.uk</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-4 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <table className="table table-striped table-1">
                <tbody>
                  <tr>
                    <td style={{ width: "30%", height: 68 }}> Client Name:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}>
                      {" "}
                      Previous Name: <br />
                      (if applicable)
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Date of Birth:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Current Address: </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 1:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 2:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 3:</td>
                    <td />
                  </tr>
                </tbody>
              </table>
              <p>
                <b> RE: YOUR CLAIM FOR MIS-SOLD CAR FINANCE </b>
              </p>
              <p>
                Thank you for instructing Courmacs Legal Ltd (“CLL”) to act for
                you. We are required by our regulatory body to confirm certain
                matters to you. As a result, we apologise if some of this Client
                Care letter seems quite formal.
              </p>
              <p className="text-decoration-underline">
                <b> Your Instructions </b>
              </p>
              <p>
                You have instructed us to pursue a claim for losses arising from
                Finance Arrangements you have entered into in relation to Car
                Finance – HP/PCP.
              </p>
              <p>
                To succeed in your claim, we will be saying that the
                relationship with the defendant was legally unfair. We will be
                making your claim directly against the lender.
              </p>
              <p>
                This is a process you can use yourself without any charge.
                However, we have to advise you that this may change before the
                end of the year.
              </p>
              <p>
                A consultation is taking place which could result in a fee being
                paid to make such a complaint. Although the level of the fee has
                not been set it has been suggested it could be as much as £250.
                It is not clear whether this fee is something that can be
                deducted from your overall financial benefit when a claim is
                successful or not. It has also been suggested that although the
                fee to bring a claim could be as much as £250 and will be paid
                by us, the actual amount that would be charged would be £75 with
                the Financial Services Ombudsman repaying the excess fee to us.
                It is possible that the £75 fee may be payable by you from the
                financial benefit you receive, and we felt it
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-5 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                sensible to let you know this may happen. As soon as the rules
                have been made and it is clear what the impact on you will be we
                will of course let you know.
              </p>
              <p>
                If you want to make a claim directly then, please tell us now
                and do not sign any documents. If you do want us to deal with
                your claim, then we will represent you under the terms of our
                no-win no no-fee agreement.
              </p>
              <p>
                We consider, based on the information that we currently have,
                that your claim for compensation has a reasonable prospect of
                success.
              </p>
              <p className="text-decoration-underline">
                <b> No Win No Fee Agreement </b>
              </p>
              <p>
                As your claim has been assessed as having a reasonable prospect
                of success, we would propose to act for you under a No Win No
                Fee Agreement, called a Damages Based Agreement (DBA). There are
                other ways to pursue claims, and these can be found in our terms
                of business on our website:
                <a
                  style={{ color: "#5a2b86" }}
                  href="https://courmacslegal.co.uk/policies/terms-and-conditions/"
                >
                  courmacslegal.co.uk/policies/terms-and-conditions
                </a>
                . If you need a hard copy, please tell us.
              </p>
              <ul>
                <li> Damages Based Agreement (DBA)</li>
              </ul>
              <p>
                The booklet on our website explains how the DBA works. The DBA
                and this letter will form the agreement between us.
              </p>
              <p>
                We are only able to make your claim under the terms of our DBA,
                if you do not agree to those terms then we cannot proceed with
                the matter for you. Under the terms of the damages-based
                agreement you will agree to share with us an agreed percentage
                of the overall financial benefit you received as a result of us
                taking the matter on for you, this will include any repayments
                of money you have paid and the value of any future payments that
                are written off or reduced.
              </p>
              <p>
                New rules have been put in place by our regulator which caps the
                level of fee we can charge. The cap is determined by the value
                of the financial benefit you obtain, as we cannot confirm that
                figure to you, we have set out below full details of the fees
                that may be paid depending on the outcome of your claim.
              </p>
              <table className="custom-table">
                <thead>
                  <tr>
                    <th className="custom-th">Band</th>
                    <th className="custom-th">
                      Redress awarded for a claim (£)
                    </th>
                    <th className="custom-th">
                      The maximum percentage rate of charge
                    </th>
                    <th className="custom-th">The maximum total charge (£)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="custom-td">1</td>
                    <td className="custom-td">1-1,499</td>
                    <td className="custom-td">30%</td>
                    <td className="custom-td">420</td>
                  </tr>
                  <tr>
                    <td className="custom-td">2</td>
                    <td className="custom-td">1,500-9,999</td>
                    <td className="custom-td">28%</td>
                    <td className="custom-td">2,500</td>
                  </tr>
                  <tr>
                    <td className="custom-td">3</td>
                    <td className="custom-td">10,000-24,999</td>
                    <td className="custom-td">25%</td>
                    <td className="custom-td">5,000</td>
                  </tr>
                  <tr>
                    <td className="custom-td">4</td>
                    <td className="custom-td">25,000-49,999</td>
                    <td className="custom-td">20%</td>
                    <td className="custom-td">7,500</td>
                  </tr>
                  <tr>
                    <td className="custom-td">5</td>
                    <td className="custom-td">50,000 or above</td>
                    <td className="custom-td">15%</td>
                    <td className="custom-td">10,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-6 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                If your claim is successful, you agree that we will be paid in
                accordance with the relevant band plus VAT of the overall
                financial benefit you receive.
              </p>
              <p>
                The reasons for calculating the DBA Payment at this level are
                set out in the Schedule 1 to this DBA (which is a non-exhaustive
                list). There is no extra charge for the postponement of payment
                of our time Costs or Expenses.
              </p>
              <p>
                Your continuing instructions confirm acceptance of these terms.
                However, to make matters clear, please sign and return the
                enclosed declaration, as well as one copy of the Damages Based
                Agreement, to confirm that you would like us to start work
                immediately.
              </p>
              <p className="text-decoration-underline">
                {" "}
                <b> Funding Your Claim</b>
              </p>
              <p> Your claim has come to us via an introducer. </p>
              <p>
                A scheme has been set up to assist people to pursue these
                claims.{" "}
              </p>
              <p>
                The scheme involves financial institutions and solicitors. We
                are one of the Solicitors who are part of the scheme.
              </p>
              <p>
                We will act for you in relation to your claim. The financial
                institution (the funder) has agreed to lend us money to pay for
                the expenses involved in pursuing your claim (as described
                below).
              </p>
              <p>
                <i>
                  There are other ways of pursuing these cases involving others.
                  If you have any doubts about this scheme, you should take
                  independent legal advice before going any further. <br />
                  If you wish to use an Alternative Method of Funding, we will
                  not act for you. We will only act for you on the terms of this
                  scheme as described below.
                </i>
              </p>
              <p className="text-decoration-underline">
                {" "}
                <b> Work In Progress Funding </b>
              </p>
              <p>
                The introducer recommended our services to you. You do not have
                to instruct us, but you may not be able to use the Scheme that
                has been put in place for these claims if you do not instruct
                us.
              </p>
              <p>
                Although we do not have a full work-in-progress funding
                arrangement in place, we must tell you that under the terms of
                the financial arrangement with the investment funder, they have
                agreed that a certain sum is assigned to each claim for the
                expenses we expect to incur.
              </p>
              <p>
                Any payment under this agreement with the funder will not affect
                the way we will deal with your claim.
              </p>
              <p className="text-decoration-underline">
                <b> Scope Of Work</b>
              </p>
              <p>
                {" "}
                The scope of the work we will undertake for you will include the
                following-
              </p>
              <ul>
                <li>
                  We can only take on your claim under a Damages Based
                  Agreement. If you want to pursue the claim in any other way,
                  we cannot help you.
                </li>
                <li>
                  Sending details of your claim to the lender under their
                  complaint’s procedure. They will then investigate your claim
                  and confirm their interest in the matter.
                </li>
                <li>Dealing with all enquiries made by the lender.</li>
                <li>Advising you in relation to any settlement offer made.</li>
              </ul>
              <p className="text-decoration-underline">
                <b>Risk in Making a Claim</b>
              </p>
              <p>
                Even though we consider that your claim has a reasonable
                prospect of success, all claims carry a risk. If rules are not
                strictly complied with this may be that your claim will fail.
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-7 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                You need to be aware that if you provide us with false, late, or
                inadequate information so that we fail to comply with the rules,
                then your claim may be disallowed.
              </p>
              <p>
                If any of the above happens or it is discovered that you have
                failed to tell us something relevant when asked, then we reserve
                the right to withdraw from the Damages Based Agreement. This
                could mean that you would have to pay the cost of the claim.
              </p>
              <p className="text-decoration-underline">
                <b>Costs</b>
              </p>
              <p>
                As explained, we cannot claim our costs from your opponent. We
                are required to tell you how our fees are calculated. Our fees
                are based on an hourly rate for the work undertaken. This will
                include the time spent with you, on the claim, and all
                communications. The rates (exclusive of expenses and VAT) are as
                follows: -
              </p>
              <ul>
                <li>Partners and Equivalent: £350 per hour</li>
                <li>Other Senior Solicitors: £280 per hour</li>
                <li>Experienced Legal Executives: £240 per hour</li>
                <li>
                  Other solicitors or legal executives and fee earners of
                  equivalent experience £177 per hour
                </li>
                <li>Paralegals and Trainees: £126 per hour</li>
              </ul>
              <p>
                We review our hourly rates annually and you will be notified of
                any changes.{" "}
              </p>
              <p>
                We are obliged to give you details of our hourly rates as these
                will apply to the work done on your behalf and only be payable
                by you if you breach the terms of the DBA. No hourly fees are
                payable if you abide by the terms of the DBA
              </p>
              <p>
                The enclosed booklet contains more information on the costs of
                your claim. The enclosed document entitled ‘DBA: What You Need
                to Know’ explains how the Damages Based Agreement works
              </p>
              <p className="text-decoration-underline">
                <b>Estimated Expenses</b>
              </p>
              <table className="table-2">
                <tbody>
                  <tr style={{ borderBottom: "4px solid #7541a5" }}>
                    <td style={{ width: "40%" }} className="p-1">
                      {" "}
                      <b> Type of Expense</b>
                    </td>
                    <td className="p-1"> Amount (£)</td>
                  </tr>
                  <tr>
                    <td>Case Acquisition (payment to Introducer) </td>
                    <td>£200+VAT</td>
                  </tr>
                  <tr>
                    <td>Operating Expenses</td>
                    <td>£171</td>
                  </tr>
                  <tr>
                    <td> Credit Protection Insurance</td>
                    <td>£42 Including Insurance Premium Tax</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-8 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                We do not require you to pay for any expenses upfront. We will
                pay for your expenses by way of a loan payable by this firm at
                no additional cost to you.
              </p>
              <p>
                The Credit Protection Insurance is a policy put in place to
                repay the funder the money they have lent us to pursue the claim
                in the event your claim is unsuccessful.
              </p>
              <p>
                We will repay the loan from the fees we receive from a
                successful claim
              </p>
              <p className="text-decoration-underline">
                <b>Fee Share</b>
              </p>
              <p>
                We are obliged to tell you that we have entered into a
                fee-sharing arrangement with the funder ECA Alternative
                Investments a Luxembourg société copérative organisée comme une
                SA, registered under number B238123, with registered office at
                52 rue d'Anvers, 1130, Luxembourg, Acting through its
                Compartment “PCP Claims 2024” under the terms of this agreement
                we will split our net profit costs equally with the funder. This
                arrangement in no way impacts how we approach your claim or our
                duties to you as our client. We will share information about
                your claim with the funder who funds it.
              </p>
              <p className="text-decoration-underline">
                <b>Timescale</b>
              </p>
              <p>
                We will do all we can to complete the matter as soon as
                possible. The initial complaint to your opponent should be dealt
                with in 8 weeks. However, at present, the Finical Conduct
                Authority has introduced a pause on this time frame which is
                currently due to expire on 25 September 2024. Unless the FCA
                change the position the 8 weeks will start to run from that
                date. If they need more information, it could take longer. If we
                recommend that we make a complaint to the Financial Ombudsman
                Service that could take 18 months as they have a significant
                backlog of matters.
              </p>
              <p className="text-decoration-underline">
                <b>Sharing Information About Your Claim</b>
              </p>
              <p>Your claim has come to us via a third-party introducer</p>
              <p>
                Although you have a relationship with the third-party
                introducer, we do need you to confirm that we can share
                information about your claim with them. If you have any concerns
                or would like to discuss this further, please get in touch as
                soon as possible
              </p>
              <p>
                We will also share information with the funder who has funded
                your claim.
              </p>
              <p>
                We will take you signing the Damages Based Agreement as
                confirmation that we can provide information to the introducer
                and funder. If you do not want this to happen, then you should
                not sign the Damages Based Agreement and we will close our file
              </p>
              <p>
                No information you give us will be disclosed to any third party
                other than as detailed above without your agreement. We cannot
                think of any reason at present why this arrangement would not be
                in your best interests.
              </p>
              <p>
                Our regulators the Solicitors Regulatory Authority (SRA) state,
                we are under a duty not to acquire clients because of marketing
                or publicity or other activities which would be
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-9 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                in breach of Chapter 8 of the SRA Code of Conduct. This means
                that you must not, for example, have been cold called or been
                the subject of other unsolicited approaches in person, including
                for example being approached in the street, or approached by
                people conducting a survey which involves collecting contact
                details of potential customers. Please let us know if you have
                any concerns about the way, you have been put in touch with us
                or if you believe any of the publicity or marketing materials,
                we use are misleading or inaccurate.
              </p>
              <p className="text-decoration-underline">
                <b>Our Liability</b>
              </p>
              <p>
                The total liability of Courmacs Legal Ltd for any claim in
                respect of services provided to you (whether in contract,
                negligence or otherwise) shall in no circumstances exceed
                £3,000,000.00 (three million pounds).
              </p>
              <p>
                Further details regarding our liability can be found in our
                Terms of Business.
              </p>
              <p className="text-decoration-underline">
                <b>Money Laundering and Your Identity.</b>
              </p>
              <p>
                Our Terms of Business contain information about our obligations
                under anti-money laundering legislation. In order to comply with
                those obligations, we have to obtain evidence identifying our
                clients. This information should come to us when we are first
                made aware of your claim. If we need further information, we
                will contact you.
              </p>
              <p className="text-decoration-underline">
                <b>Other Solicitors or Claims Handlers</b>
              </p>
              <p>
                Although you will have been asked if you have instructed someone
                else to make a claim for you it is essential that you tell us
                now if you may have signed with someone else to make a claim for
                you. We need to know this so we can see whether we can help you
                or not. If you think you have or may have instructed someone
                else before us, we need to see whatever documents you have from
                them before we do anything else. Unless you tell us that it is
                possible that you have instructed someone else then we will
                assume that we can continue with your claim.
              </p>
              <p className="text-decoration-underline">
                <b>Client Care</b>
              </p>
              <p>
                We always aim to provide an outstanding service to our clients.
                Whilst we hope you will have no cause to complain, if, for any
                reason, you are unhappy with any aspect of the service we
                provide to you, please raise the matter with Darren Smith.
              </p>
              <p>
                In the unlikely event that an issue arises that you feel that
                you cannot raise with Darren Smith, please contact Gerard
                Donaldson, who is responsible for complaints handling within our
                practice. A copy of our complaint’s procedure is available on
                request.
              </p>
              <p>
                Please note that a reference to Courmacs Legal Ltd also refers
                to any successor business or businesses that continue to carry
                on the legal practice of Courmacs Legal Ltd.
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-10 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                If you are not satisfied with our handling of your complaint,
                you may be able to ask the Legal Ombudsman to investigate. There
                are limitations to the availability of this service, which are
                detailed on their website,{" "}
                <a
                  style={{ color: "#5a2b86" }}
                  href="www.legalombudsman.org.uk."
                >
                  www.legalombudsman.org.uk.
                </a>{" "}
                Further details of how to access this service and the time
                limits which apply can be found at paragraph 6.9 of the Terms of
                Business available on our website.
              </p>
              <p className="text-decoration-underline">
                <b> What You Need to Do Now</b>
              </p>
              <p>
                We hope that this letter explains how we will proceed with your
                claim. To enable us to start work straight away, could you
                please:
              </p>
              <ol>
                <li>
                  Sign and return the enclosed declaration and authority form
                </li>
                <li>
                  Sign and return the enclosed authority to enable us to obtain
                  details regarding your dealings with the opponent.
                </li>
                <li>
                  Once you have read the enclosed information if you would like
                  us to act under a Damages Based Agreement (DBA), please sign
                  and return one copy of the enclosed DBA.
                </li>
                <li>
                  Sign the Form of assignment which gives the benefit of the CPI
                  policy to the funder if a claim is made.
                </li>
              </ol>
              <p>
                Once we receive the documents, we will obtain the further
                documents we need to pursue your claim. We will be in contact
                with you to explain the next steps we will take on your behalf.
                We expect to be in contact with you in 4 to 6 weeks from receipt
                of your signed DBA.
              </p>
              <p>
                Once you have become our client anything to do with your claim
                should come through us. This means that if you are contacted by
                the opponent or someone on their behalf to discuss your claim
                you should politely tell them to contact us.
              </p>
              <p>
                If you have any questions about this letter or your claim,
                please do not hesitate to contact us.
              </p>
              <p>We look forward to working with you.</p>
              <p>Yours faithfully,</p>
              <img
                style={{ width: "24%" }}
                src="../../assets/NewTerms/img/sign.jpg"
                alt
              />
              <p>Courmacs Legal Ltd</p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-11 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p className="text-center">
                <b>DBA: WHAT YOU NEED TO KNOW</b>
              </p>
              <p>
                <b>1. General Terms</b>
              </p>
              <div className="d-flex">
                <div> 1.1</div>
                <div className="ps-3">
                  If any of the following events occur, you will break the
                  Agreement and you will be liable to pay our fees:
                  <ol style={{ listStyleType: "lower-roman" }}>
                    <li className="mt-1"> You fail to co-operate with us.</li>
                    <li className="mt-1">
                      You fail to attend any pre-arranged appointment (e.g.,
                      expert examination)
                    </li>
                    <li className="mt-1">
                      You fail to give us necessary instructions when we ask for
                      them; or
                    </li>
                    <li className="mt-1">
                      You withdraw instructions from us for any reason.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="d-flex">
                <div> 1.2</div>
                <div className="ps-3">
                  In the event of your death, your claim can survive subject to
                  your Personal Representatives agreeing to instruct us under
                  the terms of a new conditional fee agreement.
                </div>
              </div>
              <div className="d-flex">
                <div> 1.3</div>
                <div className="ps-3">
                  If your Personal Representatives do not provide such
                  instructions, this agreement will be deemed terminated
                  pursuant to section above and we may seek recovery of our
                  basic charges and expenses up to the date of your death from
                  your estate.
                </div>
              </div>
              <p>
                <b>2. Procedure</b>
              </p>
              <div className="d-flex">
                <div>2.1</div>
                <div className="ps-3">
                  If your claim is successful, you will receive a settlement
                  award
                </div>
              </div>
              <div className="d-flex">
                <div>2.2</div>
                <div className="ps-3">
                  After the award has been paid you will pay us the agreed sum
                  under the terms of the DBA.
                </div>
              </div>
              <p>
                <b>3. Basic Charges</b>
              </p>
              <div className="d-flex">
                <div>3.1</div>
                <div className="ps-3">
                  These are our charges for the work we do, based on the rate we
                  charge which is set out in our terms of business on our
                  website:
                  <a
                    style={{ color: "#5a2b86" }}
                    href="courmacslegal.co.uk/policies/terms-and-conditions"
                  >
                    courmacslegal.co.uk/policies/terms-and-conditions
                  </a>
                </div>
              </div>
              <p>
                <b>4. Expenses</b>
              </p>
              <div className="d-flex">
                <div>4.1</div>
                <div className="ps-3">
                  These are our expenses and fees that have to be paid on your
                  behalf, by us, to others involved in the case. For example,
                  experts’ fees.
                </div>
              </div>
              <p>
                <b>5. What happens if you win</b>
              </p>
              <div className="d-flex">
                <div>5.1</div>
                <div className="ps-3">
                  You will pay to us the agreed % of the overall financial
                  benefit you receive from your opponent.
                </div>
              </div>
              <div className="d-flex">
                <div>5.2</div>
                <div className="ps-3">
                  We have agreed a fee share agreement with ECA Alternative
                  Investmants a Luxembourg société copérative organisée comme
                  une SA, registered under number B238123, with registered
                  office at 52 rue d'Anvers, 1130, Luxembourg,
                </div>
              </div>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-12 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p className="ps-5">
                Acting through its Compartment “PCP Claims 2024 under which we
                will split our net profit costs equally with them.
              </p>
              <p>
                <b>6. What Happens if You Lose</b>
              </p>
              <div className="d-flex">
                <div> 6.1 </div>
                <div className="ps-3">
                  {" "}
                  You do not have to pay any of our charges.
                </div>
              </div>
              <p>
                <b>7. Termination of this Agreement</b>
              </p>
              <div className="d-flex">
                <div> 7.1 </div>
                <div className="ps-3">
                  {" "}
                  We may end the Agreement if:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      {" "}
                      You reject our opinion about making a settlement with your
                      opponent.
                      <ol style={{ listStyleType: "lower-roman" }}>
                        <li>
                          {" "}
                          If this happens, we can obtain a second opinion from
                          an independent solicitor or barrister on the merits of
                          the proposed settlement (this shall be at your
                          expense).
                        </li>
                      </ol>
                    </li>
                    <li>
                      We believe that you are unlikely to succeed with your
                      claim.
                    </li>
                    <li>
                      We believe you have not kept to your responsibilities.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="d-flex">
                <div> 7.2 </div>
                <div className="ps-3">
                  You may end this agreement at any time. However, we then have
                  the right to decide whether you must pay our basic charges and
                  our expenses when we ask for them or pay our basic charges and
                  our expenses and success fee if you go on to succeed with your
                  claim.
                </div>
              </div>
              <p>
                <b> 8. Value Added Tax (VAT)</b>
              </p>
              <div className="d-flex">
                <div> 8.1 </div>
                <div className="ps-3">
                  We add VAT at the rate that applies when the work is done, to
                  the total of the Basic Costs
                </div>
              </div>
              <p>
                <b>9. Win/Lose</b>
              </p>
              <div className="d-flex">
                <div> 9.1 </div>
                <div className="ps-3">
                  Win means your claim is finally decided in your favour,
                  whether by FOS or an agreement to resolve the dispute.
                  'Finally,' means that your opponent: <br />
                  a) Is not allowed to appeal against the decision; or <br />
                  b) Has not appealed in time; or <br />
                  c) Has lost any appeal
                </div>
              </div>
              <div className="d-flex">
                <div> 9.2 </div>
                <div className="ps-3">
                  Lose, means that FOS has dismissed your claim or you have
                  stopped it on our advice.
                </div>
              </div>
              <div className="d-flex">
                <div> 10.</div>
                <div className="ps-3">
                  This agreement complies with the requirements of the Access to
                  Justice Act 1999, section 58 of the Courts and Legal Services
                  Act 1990 (as amended) and the SRA Handbook.
                </div>
              </div>
              <p>
                <b>11. Our responsibilities - we must:</b>
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-13 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <div className="d-flex">
                <div> 11.1 </div>
                <div className="ps-3">
                  {" "}
                  Always act in your best interests, subject to our professional
                  duties as set by theSRA.
                </div>
              </div>
              <div className="d-flex">
                <div> 11.2 </div>
                <div className="ps-3">
                  {" "}
                  Explain to you the risks and benefits of making a claim.
                </div>
              </div>
              <div className="d-flex">
                <div> 11.3 </div>
                <div className="ps-3">
                  {" "}
                  Give you our best advice about whether to accept any offer of
                  settlement
                </div>
              </div>
              <div className="d-flex">
                <div> 11.4 </div>
                <div className="ps-3">
                  {" "}
                  Give you the best information possible about the likely costs
                  of your case.
                </div>
              </div>
              <div className="d-flex">
                <div> 11.5 </div>
                <div className="ps-3">
                  {" "}
                  Provide you with a proper standard of service.
                </div>
              </div>
              <p>
                <b>12. Your responsibilities - you must:</b>
              </p>
              <div className="d-flex">
                <div> 12.1 </div>
                <div className="ps-3">
                  {" "}
                  Give us instructions that allow us to do our work properly
                </div>
              </div>
              <div className="d-flex">
                <div> 12.2 </div>
                <div className="ps-3">
                  {" "}
                  Not ask us to work in an improper or unreasonable way
                </div>
              </div>
              <div className="d-flex">
                <div> 12.3 </div>
                <div className="ps-3"> Not deliberately mislead us.</div>
              </div>
              <div className="d-flex">
                <div> 12.4 </div>
                <div className="ps-3"> Co-operate with us</div>
              </div>
              <div className="d-flex">
                <div> 12.5 </div>
                <div className="ps-3">
                  {" "}
                  Preserve documents relevant to your claim even where harmful
                  to it. We are duty-bound to inform you that you are obliged to
                  ensure that all hard copies and electronic documentation that
                  may be relevant to this matter are preserved and not
                  destroyed. Should you have any questions in respect of the
                  deletion or destruction of electronic and/or hard copy
                  documentation then please do speak with the solicitor dealing
                  with the case before taking any action.
                </div>
              </div>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-14 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                <b>CANCELLATION NOTICE</b>
              </p>
              <p>This only applies if you sign the Damages Based Agreement:</p>
              <p className="ps-2">
                (i) At your home, workplace or at someone else’s home; or
              </p>
              <p className="ps-2">
                (ii) At our offices but following a visit by us (or by someone
                acting on our behalf) to your home, workplace, or someone else’s
                home; or
              </p>
              <p className="ps-2">
                {" "}
                (iii) At our offices but following a meeting between us away
                from our offices.
              </p>
              <p>
                You have the right to cancel this contract if you wish and can
                do so by delivering or sending (including electronic mail) a
                cancellation notice to the person mentioned below at any time
                within 14 days starting with the day of receipt of this Notice.
              </p>
              <p>
                If you wish to cancel the contract, you{" "}
                <b>MUST DO SO IN WRITING</b> and deliver personally or send
                (which may be by electronic mail) the notice to the person named
                below. You may use this form if you want to, but you do not have
                to.
              </p>
              <p>
                COMPLETE, DETACH AND RETURN THIS FORM ONLY IF YOU WISH TO CANCEL
                THE CONTRACT.
              </p>
              <p className="text-center dots_p">
                ------------------------------------------- Cut Here
                -----------------------------------------------
              </p>
              <p>
                NB: THIS CANCELLATION NOTICE DOES NOT APPLY TO ANY CPI POLICY
                YOU MAY HAVE TAKEN OUT.
              </p>
              <p className="my-3">To:</p>
              <p>Mr Darren Smith</p>
              <p>
                <b>Courmacs Legal Ltd</b>
              </p>
              <p className="my-3">
                Alexander House, Beehive Trading Park, Haslingden Road,
                Blackburn, BB1 2EE
              </p>
              <p>
                I hereby give notice that I wish to cancel my Damages Based
                Agreement under your reference.
              </p>
              <p className="dots_p">Signed: ………………………………………….</p>
              <p className="dots_p">Print Name: …………………………………………</p>
              <p className="dots_p">
                Address:…………………………………………………………………………………………
                …………………………………………………………………………………………………… …………………………………………
              </p>
              <p className="dots_p">Dated: ...…………….………………………..</p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-15 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p className="text-center">
                <b>YOUR DECLARATION AND AUTHORITY FORM</b>
              </p>
              <table className="table table-striped table-1">
                <tbody>
                  <tr>
                    <td style={{ width: "30%", height: 68 }}> Client Name:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}>
                      {" "}
                      Previous Name: <br />
                      (if applicable)
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Date of Birth:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Current Address: </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 1:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 2:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 3:</td>
                    <td />
                  </tr>
                </tbody>
              </table>
              <p>
                I acknowledge receipt of your Client Care Letter and confirm
                that I understand and accept those terms and wish for Courmacs
                Legal Ltd to start work on my claim straight away
              </p>
              <p>
                I authorise any cheques in settlement of my claim to be issued
                in the name of Courmacs Legal Ltd and understand that any monies
                paid in respect of my claim will be paid into your client
                account even if the cheque is made payable to me. I hereby
                authorise you to deduct and retain from those monies any sums
                that fall due to Courmacs Legal Ltd by way of your legal costs
                and expenses in accordance with the provisions of the
                aforementioned Terms of Business. I also hereby confirm that any
                monies paid to you on my behalf may be retained by you and
                utilised as a lien as to your costs in the event that I breach
                these terms and conditions.
              </p>
              <p>
                I understand that you may instruct different experts and
                agencies to assist with progressing my claim. These will include
                the likes of Enquiry Agents, Costs Draftsmen, and Barristers but
                this is not an exhaustive list. I hereby consent to my personal
                data being provided to them and to any other person deemed
                necessary by my solicitor to assist with the progress of my
                claim.
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-16 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                I also consent to the disclosure of my personal data to
                third-party managed databases used to help prevent fraud and to
                regulatory bodies for the purpose of monitoring and/or enforcing
                your compliance with any regulatory rules/codes. I also
                understand that it will be used to undertake credit and claims
                history searches on me. By signing this document, I am providing
                my consent for this to occur. I also understand that independent
                organisations that gather reviews on the services provided may
                be provided with anonymised information to enable them you
                collect reviews and that this is detailed in the privacy notice
                available online.
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-17 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p className="text-center">
                <b> CANCELLATION RIGHTS</b>
              </p>
              <p>
                <b> IMPORTANT – PLEASE READ</b>
              </p>
              <p style={{ color: "#5a2b86" }}>
                Where cancellation rights apply (please see our ‘Termination’
                clause of our Terms of Business), we will not start work for a
                period of 14 days from the ‘Agreement Date’ unless you expressly
                ask us to. This is because there are regulations in force that
                now prevent us from doing so. If you would like our service to
                start within 14 days of the Agreement Date, please tick the box
                below to confirm your instructions, sign, and date the
                declaration and send a copy to us.
              </p>
              <p>
                <b> I wish to provide the following instructions:</b>
              </p>
              <div className="d-flex">
                <div>A.</div>
                <div className="ps-3">
                  {" "}
                  Please start work on my matter straight away. I understand
                  that by instructing the firm to start work before the 14-day
                  (no obligation) cancellation period expires, I will become
                  liable for any costs and expenses incurred during this time. I
                  accept that this liability exists if I then choose to cancel
                  this contract. I also understand that where, on my
                  instruction, the full service agreed has been performed within
                  the cancellation period, my cancellation rights will cease to
                  exist in accordance with the Consumer Contracts (Information,
                  Cancellation and Additional Charges) Regulations 2013, as set
                  out in the ‘Termination’ clause of the firm’s Terms of
                  Business.
                </div>
              </div>
              <p>
                <b> Confirmation of Consent to Send Marketing Materials</b>
              </p>
              <div className="d-flex">
                <div>B.</div>
                <div className="ps-3">
                  {" "}
                  We promise to respect the data we hold on to you. Your
                  acceptance of our terms of Business authorises us to keep your
                  details on our database so that we can provide you with legal
                  services as well as for administration and accounting
                  purposes. We will never pass on any personal data to other
                  parties without your permission. We would also be grateful if
                  you would provide us with express permission to send your
                  relevant information about our services, legal updates and
                  events that interest you by text, telephone, email and through
                  the post.
                </div>
              </div>
              <p>
                <b>
                  {" "}
                  You may withdraw your consent at any time by emailing us at
                  the email address at the top of this letter.
                </b>
              </p>
              <p>
                {" "}
                C. I consent to receive information about your products and
                services.
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-18 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p className="text-center">
                <b>AUTHORITY TO DISCLOSE DOCUMENTS HELD BY THIRD PARTIES</b>
              </p>
              <table className="table table-striped table-1">
                <tbody>
                  <tr>
                    <td style={{ width: "30%", height: 68 }}> Client Name:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}>
                      {" "}
                      Previous Name: <br />
                      (if applicable)
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Date of Birth:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Current Address: </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 1:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 2:</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Previous Address 3:</td>
                    <td />
                  </tr>
                </tbody>
              </table>
              <p style={{ color: "#7541a5" }}>
                TO WHOM IT MAY CONCERN - THIS AUTHORITY RELATES TO ALL
                INFORMATION PERTAINING TO ALL ACCOUNTS ARRANGED BY OR HELD WITH
                YOU, MY LENDER, AS LISTED IN APPENDIX 1.
              </p>
              <p>
                I authorise and request release to my solicitors Courmacs Legal
                Ltd copies of all documents held relating to myself. This will
                include but is not limited to: -
              </p>
              <ul>
                <li>
                  {" "}
                  Correspondence between myself and the finance provider.
                </li>
                <li>
                  Correspondence between myself and the finance broker who acted
                  as the agent or broker in relation to my finance.
                </li>
              </ul>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-19 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p className="text-center">
                <b>AUTHORITY TO DISCLOSE DOCUMENTS HELD BY THIRD PARTIES</b>
              </p>
              <ul>
                <li>
                  Records and/or transcripts of telephone conversations between
                  myself and the broker, which referred me to the finance
                  provider and between myself and the finance provider.
                </li>
                <li>
                  Correspondence and documents between the intermediary, myself
                  and the finance provider relating to the finance provided
                  including details of interest rates and any commission paid or
                  received.
                </li>
                <li>
                  Copies of any or all documents created in relation to the
                  agreement number whether provided to me or internal documents
                  created by either the intermediary and or the finance
                  provider.
                </li>
              </ul>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-20 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <h5 className="text-center mb-4">
                <b> YOUR SIGNATURE</b>
              </h5>
              <p>
                The terms of the Damages Based Agreement (“DBA”) and
                accompanying documents have been explained to me as set out on
                the previous pages of this document and I have had an
                opportunity to ask questions about this.
              </p>
              <p>
                I also confirm that I have read the cancellation rights and
                other information on page 15 and that by signing the DBA I agree
                to the steps set out in A, B and C on that page.
              </p>
              <p>I understand that:</p>
              <p>
                If I mislead the solicitors, do not act on their advice, or keep
                in contact with them, I may have to pay my solicitors' costs and
                any expenses that have been spent on my behalf.
              </p>
              <table className="table table-striped table-1 mt-3">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}> Name: </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ height: 68 }}> Signed: </td>
                    <td />
                  </tr>
                  <tr>
                    <td> Dated:</td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-21 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <h5 className="text-center mb-4">
                <b> APPENDIX 1</b>
              </h5>
              <p>
                The Terms of Engagement I have signed to instruct Courmacs Legal
                Ltd pertains to the following lenders:
              </p>
              <table className="table table-striped table-1 mt-3">
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        height: 680,
                        verticalAlign: "middle",
                      }}
                    >
                      {" "}
                      Lenders:{" "}
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-22 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <h5 className="text-center mb-4">
                <b> FORM OF ASSIGNMENT</b>
              </h5>
              <p>
                <b> This Assignment</b> is made on
              </p>
              <p>
                <b> Between</b>
              </p>
              <p>
                of <br /> <b> (“Claimant/Assignor”);</b>
              </p>
              <p> and</p>
              <p>
                {" "}
                ECA Alternative Investments a Luxembourg société copérative
                organisée comme une SA, registered under number B238123, with
                registered office at 52 rue d'Anvers, 1130, Luxembourg, Acting
                through its Compartment “PCP Claims 2024” (“funder/Assignee”)
                which definition shall include its assignees and successors).
              </p>
              <p>
                <b> Whereas</b>
              </p>
              <div className="d-flex">
                <div> A.</div>
                <div>
                  {" "}
                  You are the claimant, or intended claimant, in relation to a
                  Mis-sold car finance claim.
                </div>
              </div>
              <div className="d-flex">
                <div> B.</div>
                <div>
                  You have agreed to assign the proceeds awarded to you, (the
                  “Claim Proceeds”) in respect of a CPI policy with Millennium
                  Insurance Company Limited (the “Policy”) as set out below.
                </div>
              </div>
              <p>
                <b> 1. Assignment</b>
              </p>
              <p>
                {" "}
                In consideration of the Assignee providing funding to Courmacs
                Legal Limited in respect of certain of the expenses becoming
                payable in respect of the Assignor’s claim, the Assignor with
                full title guarantee hereby assigns absolutely to the Assignee
                all of its past, present and future rights, title, benefits and
                interests in the Claim Proceeds together with the rights to make
                demand, commence, issue and continue proceedings against the
                insurer (together with its successors and assigns) in respect of
                the Claim Proceeds.
              </p>
              <p>
                <b>2. Assignment by parties and notices</b>
              </p>
              <div className="d-flex">
                <div> 2.1</div>
                <div>
                  The Assignee may at any time assign or otherwise transfer all
                  or any part of its rights under this agreement without the
                  Assignor’s consent. The Assignor may not assign or otherwise
                  transfer any of its rights or obligations under this
                  agreement.
                </div>
              </div>
              <div className="d-flex">
                <div> 2.2</div>
                <div>
                  The Assignor consents to the Assignee notifying the insurers
                  underwriting the Policy of this agreement and providing a copy
                  of this agreement to such persons.
                </div>
              </div>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-23 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>
                <b> 3. Counterparts</b>
              </p>
              <p>
                This agreement may be executed in any number of counterparts and
                all such counterparts taken together shall be deemed to
                constitute one and the same document.
              </p>
              <p>
                {" "}
                <b> 4. Governing Law &amp; Jurisdiction</b>
              </p>
              <p>
                This Assignment shall be construed and governed according to
                English law. The courts of England have exclusive jurisdiction
                to settle any dispute or claim arising out of or in connection
                with this Assignment.
              </p>
              <p>
                This agreement has been entered into on the date stated at the
                beginning of this agreement.
              </p>
              <p>Signed (Assignor): ………………………………………………</p>
              <p className="mt-4 dots_p"> Print Name: ………………………………………………</p>
              <p className="mt-4 dots_p"> Date: ………………………………………………</p>
              <p className="mt-4 dots_p">
                <b> Signed for and on behalf of the Assignee:</b>
              </p>
              <p className="mt-4 dots_p">Name: ………………………………………………</p>
              <p className="mt-4 dots_p">Date: ………………………………………………</p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-23 */}
          <section className="container-1 my-5">
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <p>SCHEDULE 1</p>
              <p> Reasons for the level of the DBA Payment</p>
              <p>
                Our DBA Payment percentage set out in this DBA reflects the
                following:
              </p>
              <p>
                (a) the risk of the Claim failing and that we will not recover
                any payment for the time spent by us
              </p>
              <p>
                (b) It is still not certain what the FCA review of these types
                of claims will conclude nor that the review will be concluded by
                September 2024.
              </p>
              <p>
                (c) the risk that the lender will raise unforeseen issues in
                relying to the Claim.
              </p>
              <p>
                (d) that certain legal issues in the Claim are untested in the
                English High Court.
              </p>
              <p>
                (e) the relative factual complexity in particular that it will
                involve and require disclosure of documents held by the
                Defendant.
              </p>
              <p>
                (f) the fact that we will cover the cost of any CPI insurance
                out of our DBA Payment
              </p>
              <p>
                (g) the fact that we will pay any fee imposed to make a
                complaint out of our DBA payment
              </p>
            </div>
            <div className="footer_sec">
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
          {/* page-24 */}
          <section className="container-1 my-5" style={{ paddingBottom: 200 }}>
            <div className="header-1">
              <img
                className="header-img"
                src="../../assets/NewTerms/img/bg__img_top.jpg"
                alt
              />
            </div>
            <div className="content_sectn">
              <div className="row">
                <div className="offset-md-3 col-6 "></div>
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <img
                    className="logo-img"
                    src="../../assets/NewTerms/img/logo.jpg"
                    alt
                  />
                </div>
              </div>
              <p className="mt-4">
                I, [Client's Full Name and address], hereby declare that the
                statements made below are true and correct.
              </p>
              <table className="table ">
                <tbody>
                  <tr>
                    <td
                      className="border-0"
                      style={{ width: "10%", verticalAlign: "middle" }}
                    >
                      <div className="square-input"> </div>
                    </td>
                    <td className="border-0">
                      <p className="mb-0">
                        {" "}
                        I confirm that I was not aware that the Dealer would
                        receive any form of commission for arranging my finance
                        agreement.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="border-0"
                      style={{ width: "10%", verticalAlign: "middle" }}
                    >
                      <div className="square-input"> </div>
                    </td>
                    <td className="border-0">
                      <p className="mb-0">
                        {" "}
                        I confirm that had the Dealer told me they were being
                        paid a commission I would not have entered into the
                        finance agreement.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="border-0"
                      style={{ width: "10%", verticalAlign: "middle" }}
                    >
                      <div className="square-input"> </div>
                    </td>
                    <td className="border-0">
                      <p className="mb-0">
                        {" "}
                        I confirm that I was not told that a lower interest rate
                        was available to me in respect of the finance agreement.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="border-0"
                      style={{ width: "10%", verticalAlign: "middle" }}
                    >
                      <div className="square-input"> </div>
                    </td>
                    <td className="border-0">
                      <p className="mb-0">
                        {" "}
                        I confirm that had the Dealer told me I was not being
                        offered the lowest interest rate available to me, I
                        would not have entered into the finance agreement.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-0" colSpan={2}>
                      {" "}
                      <p>
                        By signing below, I acknowledge that I have read and
                        understood the above statements, and I affirm that they
                        are accurate and truthful.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: "54%" }}>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "middle", width: "26%" }}>
                      {" "}
                      <p className="mb-0">
                        {" "}
                        <b> Signature:</b>
                      </p>
                    </td>
                    <td>
                      {" "}
                      <div className="square-input-2"> </div>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "middle", width: "26%" }}>
                      {" "}
                      <p className="mb-0">
                        {" "}
                        <b> Dated:</b>
                      </p>
                    </td>
                    <td style={{ paddingTop: 20 }}>
                      {" "}
                      <div className="square-input-2" style={{ height: 50 }}>
                        {" "}
                      </div>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer_sec">
              <div className="row">
                <div className="col-4 d-flex flex-column  align-items-center">
                  <p className="violet_p m-0"> Phone number</p>
                  <p className="gray_p"> 0330 341 0481 </p>
                </div>
                <div className="col-4 d-flex flex-column  align-items-center">
                  <p className="violet_p m-0"> Offices address </p>
                  <p className="gray_p">
                    Alexander House, Beehive Trading Park Haslingden Rd,
                    Blackburn, BB1 2EE
                  </p>
                </div>
                <div className="col-4 d-flex flex-column  align-items-center">
                  <p className="violet_p m-0"> Mail</p>
                  <p className="gray_p"> info@courmacslegal.co.uk</p>
                </div>
              </div>
              Courmacs Legal Limited is a law firm authorised and regulated by
              the Solicitors Regulation Authority (Reg No: 819044). Courmacs
              Legal Limited is registered in England and Wales. Company Number
              13185687. Registered address: Alexander House, Beehive Trading
              Park, Haslingden Rd, BB1 2EE. A list of Directors can be seen on
              our website.
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Terms;

import React from "react";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Footer = () => {
  let domainName = window.location.hostname;
  if (domainName.startsWith("dev.") || domainName.startsWith("pre.")) {
    domainName = domainName.replace(/^(dev|pre)\./, "");
  }
  return (
    <>
      <footer>
        <div className="wrap larger text-size-smallest">
          <div className="container">
            <div className="logo smaller">
              <LogoBasedOnDomain />
            </div>
            <div className="content">
              <p className="margin-5">
                {domainName}&nbsp; is a trading style of Courmacs Legal Limited.
                Courmacs Legal Limited is a law firm authorised and regulated by
                the Solicitors Regulation Authority (Reg No: 819044) and
                Information Commissioner's Office (ICO Reg No: ZB558466).
                Company Number: 13185687 Registered Office: Alexander House,
                Beehive Trading Park, Haslingden Road,Blackburn, BB1 2EE
              </p>
              <p className="margin-5">
                {/* * This figure is our average claim value as of 29/05/2024 */}
                *£5,318.25 is our average claim value as of 29/05/2024
              </p>
              <p className="margin-5">
                {/* ^ This information has been sourced from the findings of the
                Financial Conduct Authority's (FCA) comprehensive review of the
                motor finance sector */}
                *£10,446.46 is our largest claim value as of 29/05/2024
              </p>
              <nav>
                <ul className="d-flex">
                  <li className="pe-2">
                    <a href={"/terms_and_conditions"} target="_blank">
                      <span className="link terms_and_conditions_modal">
                        Terms &amp; conditions
                      </span>
                    </a>
                  </li>
                  <li className="pe-2">
                    <a href={"/complaintsTGG"} target="_blank">
                      <span className="link complaints_procedure_modal">
                        Complaints procedure
                      </span>
                    </a>
                  </li>
                  <li className="pe-2">
                    <a href={"/privacyTGG"} target="_blank">
                      <span className="link privacy_policy_modal">
                        Privacy policy
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useState, useEffect, useContext } from "react";
import "../../../assets/analyse/css/analyse.scss";
import LogoBasedOnDomain from "../../Includes/Common/LogoBasedOnDomain";
import { useHistory } from "react-router-dom"
import { useDuplicateCheck } from "../../../Hooks/useDuplicateCheck";
import { UseLeadApi } from "../../../Hooks/UseLeadApi";
import { urlParams } from "../../../Utility/QueryString";
import { Api } from "../../../api/Api";


const Analyze = () => {
  const [liOne, setLiOne] = useState("hide");
  const [liTwo, setLiTwo] = useState("hide");
  const [liThree, setLiThree] = useState("hide");
  const [liFour, setLiFour] = useState("hide");

  const [loaderOne, setLoaderOne] = useState("show");
  const [loaderTwo, setLoaderTwo] = useState("show");
  const [loaderThree, setLoaderThree] = useState("show");
  const [loaderFour, setLoaderFour] = useState("show");

  const { duplicateCheck } = useDuplicateCheck();
  const { LeadApiIn } = UseLeadApi();
  const domainName = `${window.location.protocol}//${window.location.host}`;
  const history = useHistory();

  const query_uuid = urlParams.get("uuid");
  const local_storage_uuid = localStorage.getItem("uuid");
  var uuid = query_uuid ? query_uuid : local_storage_uuid;
  const splitName = localStorage.getItem("split_name");
  const DuplicateCheckResult = localStorage.getItem("DuplicateCheck");
  const LeadInApiResult = localStorage.getItem("LeadInApi");
  // const [count, setCount] = useState(0);
  let count = 0;
  let countDuplicate = 0;

  useEffect(() => {
    setTimeout(() => {
      setLiOne("show");
    }, 500);

    setTimeout(() => {
      setLoaderOne("hide");
      setLiTwo("show");
      userDuplicateCheck();
    }, 1500);
  }, []);

  const userDuplicateCheck = async () => {

    countDuplicate += 1;

    const formParameters = JSON.parse(localStorage.getItem("formData"));

    let email = formParameters.txtEmail;
    let mobile = formParameters.txtPhone;
    let postcode = formParameters.txtPostCode;
    let fName = formParameters.txtFName;
    let lName = formParameters.txtLName;
    let dobYr = formParameters.DobYear;
    let dobMnth = formParameters.DobMonth;
    let doDay = formParameters.DobDay;
    let dob = convertDate(dobYr + '-' + dobMnth + '-' + doDay);
    let response;
    
    try {
      if (DuplicateCheckResult) {
        response = JSON.parse(localStorage.getItem("DuplicateCheck"));
      } else {
        response = await duplicateCheck(uuid, email, mobile, postcode, fName, lName, dob);
        localStorage.setItem("DuplicateCheck",JSON.stringify(response));
      }

      if (!response.data.response || response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await Api.post("api-count", { uuid, api_type: "DuplicateCheck", count: countDuplicate, source: "live" });


      if (response.data.response.isDuplicate === "yes") {
        setLoaderTwo("hide");
        window.scrollTo(0, 0);
        history.push(
          "/pcp/unqualified?split_name=TGG_V1&uuid=" + uuid
        );
      } else {
        setTimeout(() => {
          setLoaderTwo("hide");
          setLiThree("show");
        }, 2000);
        setTimeout(() => {
          LeadSendApi();
        }, 3000);
      }
    } catch {
      try {
        await Api.post("api-count", { uuid, api_type: "DuplicateCheck", count: countDuplicate, source: "live" });
      }
      catch (e) {
        console.log(e);
      }
      if (countDuplicate < 3) {
        await userDuplicateCheck();
      } else {
        setTimeout(() => {
          setLoaderTwo("hide");
          setLiThree("show");
        }, 2000);
        setTimeout(() => {
          LeadSendApi();
        }, 3000);
      }

    }
  };

  const convertDate = (dateString) => {
    // Split the input string
    const [year, month, day] = dateString.split('-');

    // Create a map of month names to their numerical equivalents
    const monthMap = {
      'January': '01',
      'February': '02',
      'March': '03',
      'April': '04',
      'May': '05',
      'June': '06',
      'July': '07',
      'August': '08',
      'September': '09',
      'October': '10',
      'November': '11',
      'December': '12'
    };

    // Get the numerical month
    const monthNumber = monthMap[month];

    // Ensure the day is two digits
    const dayPadded = day.padStart(2, '0');

    // Return the formatted date
    return `${year}-${monthNumber}-${dayPadded}`;
  };

  const LeadSendApi = async () => {
    count += 1;
    let responseLeadApi;
    try {
      let signatureDataURL = localStorage.getItem("userSign");

      if (LeadInApiResult) {
        responseLeadApi = JSON.parse(localStorage.getItem("LeadInApi"));
      } else {
        responseLeadApi = await LeadApiIn(uuid, signatureDataURL, domainName);
        localStorage.setItem("LeadInApi",JSON.stringify(responseLeadApi));
      }
      if (!responseLeadApi.data.response || responseLeadApi.status !== 200) {
        throw new Error(`HTTP error! status: ${responseLeadApi.status}`);
      }

      await Api.post("api-count", { uuid, api_type: "LeadInApi", count: count, source: "live" });

      if (responseLeadApi.data.response) {
        const ApilenderNames = responseLeadApi.data.response?.lenders?.map(
          (lender, index) => ({
            id: index + 1, // Assigning a unique id starting from 1
            lenderName: lender.lenderName,
          })
        );

        // Convert array to JSON string
        const ApiLenders = JSON.stringify(ApilenderNames);

        // Store in localStorage
        localStorage.setItem("ApiLenders", ApiLenders);
        if ("FAIL" == responseLeadApi.data.response.creditCheckResult || "Fail" == responseLeadApi.data.response.creditCheckResult) {
          history.push(`/pcp/softsearch?uuid=${uuid}`)
        } else {

          setTimeout(() => {
            pageChange();
          }, 1000);
        }

      } else {
        throw new Error("Response data is null");
      }
    } catch (error) {
      // console.error("Error making the API call:", error);
      try {
        await Api.post("api-count", { uuid, api_type: "LeadInApi", count: count, source: "live" });
      }
      catch (e) {
        console.log(e);
      }

      if (count < 3) {
        await LeadSendApi();
      } else {
        setTimeout(() => {
          pageChange();
        }, 1000);
      }
    }
  };

  const pageChange = async () => {
    setLoaderThree('hide');
    setLiFour('show');
    setTimeout(() => {
      setLoaderFour('hide');
    }, 1000);
    setTimeout(() => {
      // history.push(
      //   "/lendersTGG_V1?uuid=" + uuid
      // );
      if (splitName === "TGG_V1" || splitName === "TGG_V1_A" || splitName === "TGG_V1_B") {
        history.push("/lendersTGG_V1?uuid=" + uuid);
      } else if (splitName === "TGG_V2") {
        history.push("/pcp/lendersTGG_V2?uuid=" + uuid);
      } else {
        history.push("/lendersTGG_V1?uuid=" + uuid);
      }
    }, 1500);
  }

  return (
    <>
      <div className="AnalyzePage">
        <div className="container">
          <div className="text-center d-flex justify-content-center ">
            <div>
              <div className="my-5">
                <LogoBasedOnDomain style={{ height: 50 }} />
              </div>
              <div>
                <span className="loader-span"></span>
              </div>
              <h4>Please wait...</h4>

              <ul className="cmpt_list m-0 p-3  shadow-sm rounded mt-3 list-unstyled">
                <li className={`gap-4 p-2 py-3 ${liOne}`} id="li1">
                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="text-start">Reviewing your responses</div>
                    <div className="right-col">
                      <img
                        height="30"
                        className={loaderOne}
                        id="loader1"
                        src="../../assets/analyze/img/loader03.svg"
                        alt=""
                      />
                      <img
                        src="../../assets/analyze/img/tick2.png"
                        className={`tickimg ${loaderOne == "show" ? "hide" : "show"
                          }`}
                        id="tick1"
                        alt=""
                      />
                    </div>
                  </div>
                </li>

                <li className={`gap-4 p-2 py-3 ${liTwo}`} id="li2">
                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="text-start">Validating your details</div>
                    <div className="right-col">
                      <img
                        height="30"
                        id="loader3"
                        className={loaderTwo}
                        src="../../assets/analyze/img/loader03.svg"
                        alt=""
                      />
                      <img
                        src="../../assets/analyze/img/tick2.png"
                        className={`tickimg ${loaderTwo == "show" ? "hide" : "show"
                          }`}
                        id="tick3"
                        alt=""
                      />
                    </div>
                  </div>
                </li>

                <li className={`gap-4 p-2 py-3 ${liThree}`} id="li3">
                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="text-start">
                      Gathering your lender information
                    </div>
                    <div className="right-col">
                      <img
                        height="30"
                        id="loader4"
                        className={loaderThree}
                        src="../../assets/analyze/img/loader03.svg"
                        alt=""
                      />
                      <img
                        src="../../assets/analyze/img/tick2.png"
                        className={`tickimg ${loaderThree == "show" ? "hide" : "show"
                          }`}
                        id="tick4"
                        alt=""
                      />
                    </div>
                  </div>
                </li>

                <li className={`gap-4 p-2 py-3 ${liFour}`} id="li4">
                  <div className="d-flex  align-items-center justify-content-between">
                    <div className="text-start">
                      Completing your claims qualification process
                    </div>
                    <div className="right-col">
                      <img
                        height="30"
                        id="loader5"
                        className={loaderFour}
                        src="../../assets/analyze/img/loader03.svg"
                        alt=""
                      />
                      <img
                        src="../../assets/analyze/img/tick2.png"
                        className={`tickimg ${loaderFour == "show" ? "hide" : "show"
                          }`}
                        id="tick5"
                        alt=""
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analyze;

import React from "react";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Footer = () => {
    let domainName = window.location.hostname;
    if (domainName.startsWith("dev.") || domainName.startsWith("pre.")) {
        domainName = domainName.replace(/^(dev|pre)\./, "");
    }

    return (

        <>
            <div className="footer py-lg-5 py-md-5 py-sm-4">
                <div className="wrap larger text-size-smallest">
                    <div className="container">
                        <div className="ftloog  smaller mb-3">
                            <div className="mb-2 mb-1">
                                <a href="">
                                    {/* <img height={40} src="/assets/TGG_V2/img/logo.png" alt="" /> */}
                                    <LogoBasedOnDomain height={40} />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-10 col-12 ps-lg-4 ps-md-4 ps-sm-1 ps-1">
                            <p className="margin-5">
                                {domainName} is a trading style of Courmacs Legal Limited.
                                Courmacs Legal Limited is a law firm authorised and regulated by
                                the Solicitors Regulation Authority (Reg No: 819044) and
                                Information Commissioner's Office (ICO Reg No: ZB558466).
                                Company Number: 13185687 Registered Office: Alexander House,
                                Beehive Trading Park, Haslingden Road,Blackburn, BB1 2EE
                            </p>
                            {/* <p className="mb-1">
                                * This figure is our average claim value as of 29/05/2024
                            </p>
                            <p className="mb-1">
                                ^ This information has been sourced from the findings of the
                                Financial Conduct Authority's (FCA) comprehensive review of the
                                motor finance sector
                            </p> */}
                                <p className="mb-1">
                                *£5,318.25 is our average claim value as of 29/05/2024
                                </p>
                                <p className="mb-1">
                                *£10,446.46 is our largest claim value as of 29/05/2024
                                </p>
                            <div className="copyright">
                                <ul>
                                    <li>
                                        <a href={"/TermsTgg"} target="_blank">
                                            <span className="link terms_and_conditions_modal">
                                                Terms &amp; conditions
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/complaintsTGG"} target="_blank">
                                            <span className="link complaints_procedure_modal">
                                                Complaints procedure
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/privacyTGG"} target="_blank">
                                            <span className="link privacy_policy_modal">
                                                Privacy policy
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

};

export default Footer;
import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useContext,
} from "react";
import { useLenderList } from "../../../../Hooks/LendersLists";
import Select from "react-select";

const Lenders = ({
  slideChange,
  className,
  backClick,
  isLenders,
  setIsLenders,
  setLenderSelectedList,
  setLenderValidationMsg,
  LenderValidationMsg,
}) => {
  const [lendersloop, setLendersloop] = useState({});
  const [lendersAddloop, setAddLendersloop] = useState({});
  const { lenderList } = useLenderList();
  const [LenderSelectedValue, setLenderSelectedValue] = useState([]);
  const [selectedAddOptions, setSelectedAddOptions] = useState([]);
  const [additionalLenders, setAdditionalLenders] = useState();

  const nextSlide = async (e) => {
    if (isLenders && LenderSelectedValue.length >= 1) {
      setLenderValidationMsg(false);
      window.scrollTo(0, 0);
      localStorage.setItem("Lenders", JSON.stringify(LenderSelectedValue));
      setLenderSelectedList(LenderSelectedValue);
      let lendersArray = lendersloop.lenders || [];
      let additionalLendersArray = lendersAddloop.additional_lenders || [];
      let combinedLenders = lendersArray.concat(additionalLendersArray);

      let selectedIds = LenderSelectedValue.map((lender) => lender.lenderName);

      let unselectedlenders = combinedLenders.filter(
        (lender) => !selectedIds.includes(lender.lenderName)
      );
      localStorage.setItem(
        "UnselectedLender",
        JSON.stringify(unselectedlenders)
      );
      slideChange(e);
    } else {
      // if (LenderSelectedValue.length <= 0) {
        setIsLenders(false);
        // setLenderValidationMsg("Please select at least one lender");
      // }
      setLenderValidationMsg("Please select at least one lender");
      return false;
    }
  };
  const onSelectTopLender = (e) => {
    const { id, name, value, checked } = e.target;
    if (checked) {
      setIsLenders(true);
      setLenderValidationMsg(false);
      setLenderSelectedValue((prevSelected) => [
        ...prevSelected,
        {
          id: id,
          lenderName: name,
          lender_value: value,
        },
      ]);
    } else {
      setLenderSelectedValue((prevSelected) =>
        prevSelected.filter((lender) => lender.id !== id)
      );
      setSelectedAddOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === id ? { ...option, label: name } : option
        )
      );
    }
  };

  const handleSelectChange = (selectedOptions) => {
    if (
      !selectedAddOptions.some((option) => option.id === selectedOptions.id)
    ) {
      setSelectedAddOptions([...selectedAddOptions, selectedOptions]);
    }
  };
  // passing values to first checkbox
  let lendersOption;
  useEffect(() => {
    (async () => {
      const lenders = await lenderList();
      setLendersloop({ lenders: lenders.data.response.lenders });
    })();
  }, []);

  if (lendersloop.lenders) {
    lendersOption = lendersloop.lenders.map((LenderData, index) => {
      return (
        <li className={`additional_lender`} key={index}>
          <input
            type="checkbox"
            className={`custom-checkbox`}
            onChange={onSelectTopLender}
            name={`${LenderData.lenderName}`}
            id={`${LenderData.id}`}
            value={LenderData.value}
          />
          <label htmlFor={`${LenderData.id}`}>{LenderData.lenderName}</label>
        </li>
      );
    });
  }
  // passing value to selectbox
  let Additionallendersoption;
  useEffect(() => {
    (async () => {
      const additional_lenders = await lenderList();
      setAddLendersloop({
        additional_lenders: additional_lenders.data.response.additional_lenders,
      });
    })();
  }, []);
  if (lendersAddloop.additional_lenders) {
    Additionallendersoption = lendersAddloop.additional_lenders.map(
      (LenderData, index) => ({
        id: LenderData.id,
        value: LenderData.value,
        label: LenderData.lenderName,
      })
    );
  }
  // passing value from selectbox to second checkbox
  useEffect(() => {
    const Additionallendersoptiondis = selectedAddOptions.map(
      (LenderData, index) => (
        <li className={`additional_lender`} key={index}>
          <input
            type="checkbox"
            className={`custom-checkbox`}
            onChange={onSelectTopLender}
            name={`${LenderData.label}`}
            id={`${LenderData.id}`}
            value={LenderData.value}
          />
          <label htmlFor={`${LenderData.id}`}>{LenderData.label}</label>
        </li>
      )
    );
    setAdditionalLenders(Additionallendersoptiondis);
  }, [selectedAddOptions]);
  return (
    <>
      <div className={`step ${className}`} id="step_2">
        <div className="form-right bounce-y top-up d-block d-md-none">
          <div className="badge">
            <div className="content">
              <p className="h6 margin-5 text-weight-600 d-flex text-start">
                <img
                  src="/assets/TGG_V1/img/information-button.png"
                  className="w-auto mt-1"
                />
                &nbsp; Additional information required to <br /> determine your
                potential refund amount.
              </p>
            </div>
          </div>
        </div>
        <div className="form-left">
          <div className="field">
            <label className="h5 margin-5">
              Select the lender(s) that you used to finance your vehicle(s):
            </label>
            <p className="text-size-smaller margin-15">
              Select all that apply below
            </p>
            <ul className="options two lender">{lendersOption}</ul>

            <div className={`additional_lenders_1}`}>
              <span>Others</span>
              <Select
                className={`additional_lender`}
                placeholder="Select Lender Name"
                options={Additionallendersoption}
                name={lendersAddloop}
                onChange={handleSelectChange}
                isSearchable={true}
              />
              <ul className="options two lender mt-3">{additionalLenders}</ul>
            </div>
          </div>
          {LenderValidationMsg ? (
            <label className={`error_msg`}>{LenderValidationMsg}</label>
          ) : (
            ""
          )}
          <div className="form-navigation">
            <ul>
              <li>
                <button
                  type="button"
                  className="button next"
                  id="nextInto3"
                  name="lenders"
                  onClick={nextSlide}
                >
                  Next
                  <img
                    src="/assets/TGG_V1/img/arrow-right.png"
                    className="px-2"
                    name="lenders"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  name="carFinance"
                  className="button back"
                  onClick={backClick}
                  id="BackInto1"
                >
                  <img
                    src="/assets/TGG_V1/img/arrow-left.png"
                    name="carFinance"
                    className="px-2"
                  />
                  Back
                </button>
              </li>
            </ul>
          </div>
          <div className="form-right bounce-y top-up d-none d-md-block">
            <div className="badge">
              <div className="content">
                <p className="h6 margin-5 text-weight-600 d-flex text-start">
                  <img
                    src="/assets/TGG_V1/img/information-button.png"
                    className="w-auto mt-1"
                  />
                  &nbsp; Additional information required to <br /> determine
                  your potential refund amount.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lenders;

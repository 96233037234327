import { Api } from "../api/Api";

export const UseLeadApi = () => {
  const LeadApiIn = async (
    uuid,
    signature,
    postUrl,
    skipDuplicate=0
  ) => {
    const response = await Api.post("v1/leadin-api", {
        uuid,
        signature,
        postUrl,
        skipDuplicate
    });
    return response;
  };

  const LeadUpdateApi = async (uuid,poid) => {
    const response = await Api.post("v1/leadupdate-api", {
      uuid,
      poid
    });
    return response;
  };

  return { LeadApiIn,LeadUpdateApi };
};

import React, {
    useState,
    useReducer,
    useRef,
    useContext,
    useEffect,
} from "react";
import LendersV2 from "../Includes/Layouts/TGG_V2/Lenders";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PersonalDetails from "../Includes/Layouts/TGG_V2/PersonalDetails";
import ContactDetails from "../Includes/Layouts/TGG_V2/ContactDetails";
import DateOfBirth from "../Includes/Layouts/TGG_V2/DateofBirth";
import LoaderV2 from "../Includes/Layouts/TGG_V2/LoaderV2";
import PostCode from "../Includes/Layouts/TGG_V2/PostCode";
import QueryStringContext from "../../Contexts/QueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useDuplicateCheck } from "../../Hooks/useDuplicateCheck";
import VisitorParamsContext from "../../Contexts/VisitorParams";

const FormTGG_V2 = ({SetSubmitLoader}) => {

    const initialState = {
        lenders: "show",
        personal_details: "hide",
        contactdetails: "hide",
        dateofbirth: "hide",
        post_code: "hide"
    };
    
    const FormReducer = (state, action) => {
        switch (action.type) {
            case "showSlide": {
                if (action.payload.clickedSlide.slide === "lenders") {
                    return {
                        ...state,
                        lenders: "hide",
                        personal_details: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "personalDetails") {
                    return {
                        ...state,
                        personal_details: "hide",
                        contactdetails: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "contactDetails") {
                    return {
                        ...state,
                        contactdetails: "hide",
                        dateofbirth: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "DateBirth") {
                    return {
                        ...state,
                        dateofbirth: "hide",
                        // name: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "Postcodeslide") {
                    return {
                        ...state,
                        post_code: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "dateofbirth") {
                    return {
                        ...state,
                        dateofbirth: "hide",
                        // contactdetails: "show",
                    };
                } else {
                    return {
                        ...state,
                    };
                }
            }
            
            default: {
            }
        }
    };



    const splitForm = useRef(null);
    const scrollfrmv2div = useRef([]);
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState([]);
    const [previousSlide, setPreviousSlide] = useState([]);
    const history = useHistory();
    const [LenderSelectedList, setLenderSelectedList] = useState([]);
    const [loader1, SetLoaderV2] = useState("hide");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { queryString } = useContext(QueryStringContext);
    const { formDataIngestion } = useDataIngestion();
    const { duplicateCheck } = useDuplicateCheck();
    const field = "pid";

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });
    const slideChange = (e) => {
        setTimeout(() => {
            scrollfrmv2div.current.scrollIntoView({
                behavior: "smooth",
                top: 0
            });
        }, 50);
        setClickedSlide({ slide: e.target.name });
    };

    const slideShowLoader = (e) => {
        setClickedSlide({ slide: e.target.name });
        SetLoaderV2("eligibi-claim-loader text-center");
        setTimeout(() => {
            SetLoaderV2("hide");
            setClickedSlide({ slide: "Postcodeslide" });
        }, 1000);
    };

    const slideChangeAddress = () => {
        setTimeout(() => {
            scrollfrmv2div.current.scrollIntoView({
                behavior: "smooth",
                top: 0
            });
        }, 50);
        setClickedSlide({ slide: "address" });
    };

    const previousSlideChange = (e) => {
        setTimeout(() => {
            scrollfrmv2div.current.scrollIntoView({
                behavior: "smooth",
                top: 0
            });
        }, 50);
        setPreviousSlide({ slide: e.target.getAttribute("name") });
    };
    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "showSlide", payload: { clickedSlide } });
        }
    }, [clickedSlide]);


    // useEffect(()=>{
    //     setTimeout(() => {
    //         scrollfrmv2div.current.scrollIntoView({
    //             behavior: "smooth",
    //             top: 0
    //         });
    //     }, 50);
    // },[])

    const formSubmit = async () => {
        SetSubmitLoader("showform");
        const form = splitForm.current;
        const values = getValues();
        const formData = values;
    
        if (formData.address1 !== "") {
          const txtUdprn = form["txtUdprn"].value;
          const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
          const txtPz_mailsort = form["txtPz_mailsort"].value;
          const txtStreet = form["txtStreet"].value;
          const txtAddress3 = form["txtAddress3"].value;
          const txtDistrict = form["txtDistrict"].value;
          formData.txtDistrict = txtDistrict;
          formData.txtAddress3 = txtAddress3;
          formData.txtUdprn = txtUdprn;
          formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
          formData.txtPz_mailsort = txtPz_mailsort;
          formData.txtStreet = txtStreet;
        }
    
        formData.lenders = LenderSelectedList;
    
        formData.page_name = "TGG_V2";
        formData.pCode_manual = form["pCode_EntryType"].value;
        if (
          visitorParameters.data !== null ||
          visitorParameters.data !== undefined
        ) {
          localStorage.setItem("fName", formData.txtFName);
          localStorage.setItem("lName", formData.txtLName);
          localStorage.setItem("pCode", formData.txtPostCode);
          localStorage.setItem("addressLine1", formData.txtHouseNumber);
          localStorage.setItem("dobYr", formData.DobYear);
          localStorage.setItem("dobMon", formData.DobMonth);
          localStorage.setItem("dobDy", formData.DobDay);
          localStorage.setItem("addressLine2", formData.txtAddress2);
          localStorage.setItem("postTown", formData.txtTown);
          localStorage.setItem("country", formData.txtCountry);
          localStorage.setItem("title", formData.lstSalutation);
          localStorage.setItem("addressLine3", formData.txtAddress3);
          localStorage.setItem("county", formData.txtCounty);
          localStorage.setItem(
            "district",
            formData.txtDistrict ? formData.txtDistrict : ""
          );
    
          let UcFname =
            formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);
    
          localStorage.setItem("UcFname", UcFname);
    
          localStorage.setItem("formData", JSON.stringify(formData));
          localStorage.setItem("queryString", queryString);
          const formSubmitResult = await formDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "split_form_submit",
            "TGG_V2",
            queryString,
            visitorParameters.data
          );
          let uuid = visitorParameters.visitor_parameters.uuid;
          let email = formData.txtEmail;
          let mobile = formData.txtPhone;
          let postcode = formData.txtPostCode;
          let fName=formData.txtFName;
            let lName = formData.txtLName;
            let dobYr = formData.DobYear;
            let dobMnth = formData.DobMonth;
            let doDay = formData.DobDay;
            const date = new Date(`${dobMnth} ${doDay}, ${dobYr}`);
            let dob = `${dobYr}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${doDay}`;


            if (formSubmitResult.data.status === "Success") {

                try {

                    const response = await duplicateCheck(uuid, email, mobile, postcode, fName, lName, dob);

                    if ("yes" == response?.data?.response?.isDuplicate) {
                        //   setLoaderTwo("hide");
                        window.scrollTo(0, 0);
                        history.push(
                            `/pcp/unqualified?split_name=TGG_V2&uuid=${uuid}`
                        );
                    }else {

                        if (queryString != null) {
                            if (queryString.indexOf("&" + field + "=") !== -1) {
                                if (EnvConstants.AppConversionPixel === "true") {
                                    history.push("/fbpixel?split_name=TGG_V2"); // fb fixel firing
                                } else {
                                    window.scrollTo(0, 0);
                                    history.push(`/pcp/signatureTGGV2?split_name=TGG_V2&uuid=${uuid}`);
                                }
                            } else {
                                window.scrollTo(0, 0);
                                history.push(`/pcp/signatureTGGV2?split_name=TGG_V2&uuid=${uuid}`);
                            }
                        } else {
                            window.scrollTo(0, 0);
                            history.push(`/pcp/signatureTGGV2?split_name=TGG_V2&uuid=${uuid}`);
                        }
                    }
                } catch (error) {
                    console.error("error ocured:", error);
                }



            }
        }
        };

  


    return (
        <>
            <section className="cs_hero cs_style_1 sec_design section_design1">
                <div className="cs_hero_wrap cs_bg_filed">
                    <div className="container-md">
                        <form
                            ref={splitForm}
                            name="split_form"
                            id="user_form"
                            method="POST"
                            autoComplete="off"
                        >
                            <div ref={scrollfrmv2div}>
                                <LendersV2
                                    className={`${state.lenders}`}
                                    setLenderSelectedList={setLenderSelectedList}
                                    slideChange={slideChange}
                                />
                                <PersonalDetails
                                    className={`${state.personal_details}`}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                    clearErrors={clearErrors}
                                    setError={setError}
                                    getValues={getValues}
                                    slideChange={slideChange}
                                />
                                <ContactDetails
                                    className={`${state.contactdetails}`}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                    clearErrors={clearErrors}
                                    setError={setError}
                                    getValues={getValues}
                                    slideChange={slideChange}
                                />
                                <DateOfBirth
                                    className={`${state.dateofbirth}`}
                                    slideShowLoader={slideShowLoader}
                                    textDob="Date Of Birth"
                                    trigger={trigger}
                                    validation={register}
                                    validationMsg={errors}
                                    SetLoaderV2={SetLoaderV2}
                                />
                                <LoaderV2
                                    loader1={loader1}
                                />
                                <PostCode
                                    className={`${state.post_code}`}
                                    slideChange={slideChange}
                                    validation={register}
                                    validationMsg={errors}
                                    splitForm={splitForm}
                                    trigger={trigger}
                                    clearErrors={clearErrors}
                                    setError={setError}
                                    getValues={getValues}
                                    formSubmit={formSubmit}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </>

    );

};

export default FormTGG_V2;
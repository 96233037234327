import React from "react";

const Section1 = () => {
  const buttontotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <section className="section_design2 py-5">
        <div className="container-fluid mt-0 pt-0 ">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-4 p-0">
              <div className="icon_blip">
                <img
                  src="/assets/TGG_V1/img/car-online.png"
                  className="sml_img"
                />
                <p>Completely Online</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4 p-0">
              <div className="icon_blip">
                <img src="/assets/TGG_V1/img/easy.png" className="sml_img" />
                <p>Fast &amp; Easy</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4 p-0">
              <div className="icon_blip">
                <img src="/assets/TGG_V1/img/nowin.png" className="sml_img" />
                <p>NO WIN, NO FEE</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="background-blue">
        <div className="container">
          <div className="row justify-content-evenly align-items-center text-white margin-30">
            <div className="col-12 col-md-4 d-flex justify-content-center">
              <img
                src="/assets/TGG_V1/img/cars-phone.png"
                className="section_img"
              />
            </div>
            <div className="col-12 col-md-6">
              <h3 className="text-white">
                Have you ever wondered about the concept of 'mis-sold' PCP
                finance?
              </h3>
              <p>
                Well, it turns out that a significant number of UK drivers might
                be owed financial compensation. The FCA's investigation
                uncovered instances where individuals were being charged
                excessively in their monthly finance payments
                <span className="text-size-pence">^</span>
              </p>
              <p className="h6 text-white">
                <strong>
                  Have you had a car on finance between 2007 and 2021?
                </strong>
              </p>
              <a className="button smaller" onClick={buttontotop}>
                Check my claim <img src="/assets/TGG_V1/img/arrow-right.png" />
              </a>
            </div>
          </div>
          <p className="text-align-center text-white margin-none">
            You can claim for free, without using a CMC, first to your lender or
            to the FOS / FSCS
          </p>
        </div>
      </section>
    </>
  );
};

export default Section1;

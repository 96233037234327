import React from "react";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Loader = ({LoaderLender}) => {

    return (
        <>
            <div className={`loading-step ${LoaderLender}`}>
                <div className="loading-spinner">
                    <LogoBasedOnDomain className="loader_logo"/>
                </div>
                <div className="overlay">
                    <p className="fw-bold showBefore_add">Analysing your results
                    </p>
                </div>
            </div>
        </>
    );

};

export default Loader;
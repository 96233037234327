import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import "../../assets/TGG_V1_OLD/css/TGG_V1_OLD.scss";
import Header from "../Includes/Layouts/TGG_V1_OLD/Header";
import { useHistory } from "react-router-dom";
import Footer from "../Includes/Layouts/TGG_V1_OLD/Footer";
import SignatureCanvas from "react-signature-canvas";
import { useSignature } from "../../Hooks/useSignature";
import { signCheckText } from "../../Constants/Constants";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../Utility/CheckUUID";
import GtmDataLayer from "../../Utility/GTMDataLayer";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import {UseLeadApi} from "../../Hooks/UseLeadApi";
const initialState = {
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const SignatureTGGV1 = () => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const signatureWrapRef = useRef(null);
  const message_type = "signature_store";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const history = useHistory();
  const domainName = window.location.hostname;
  const {LeadApiIn} = UseLeadApi();

  const handleClick = async (e) => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        // history.push(
        //   "/lendersTGG_V1?uuid=" + visitorParameters.visitor_parameters.uuid
        // );
        setDisabled(true);
        const responsesign = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type
        );

        // GtmDataLayer({
        //   question: "Signature",
        // });


        const responseLeadApi = await LeadApiIn(
          visitorParameters.visitor_parameters.uuid,
          signPad.getCanvas().toDataURL("image/png"),
          domainName
        )

        console.log(responseLeadApi.data.status,"responseLeadApi");

        if (responsesign.data.status === "Success") {
          if(responseLeadApi.data.status === "Success"){
            console.log( responseLeadApi.data.response);
            // const ApilenderNames =responseLeadApi.data.response.lenders.map(lender => lender.lenderName);

            const ApilenderNames = responseLeadApi.data.response.lenders.map((lender, index) => ({
              id: index + 1, // Assigning a unique id starting from 1
              lenderName: lender.lenderName
          }));

            // Convert array to JSON string
            const ApiLenders = JSON.stringify(ApilenderNames);

            // Store in localStorage
            localStorage.setItem('ApiLenders', ApiLenders);
         
          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == "") {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            localStorage.setItem("formData", JSON.stringify(formdata));
          }

          let splitName = localStorage.getItem("split_name")
            ? localStorage.getItem("split_name")
            : null;

          if (splitName === null) {
            const urlParams = new URLSearchParams(window.location.search);
            splitName = urlParams.get("split_name");
          }
            history.push(
              "/lendersTGG_V1?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };

  return (
    <>
        <GetVisitorsParams />
      <div className="TGG_V1">
        <Header />
        <section className="form">
          <div>
            <form
              className="main_form"
              action="lenders.html"
              method="get"
              id="step_signature_form"
            >
              <div className="wrap larger">
                <div className="container">
                  <div className="step start" id="sign_pad">
                    <div className="form-left">
                      <h1 className="margin-5">Your signature</h1>
                      <div className="field">
                        <label className="h6 margin-15">
                          Keep it fully contained within the box below
                        </label>
                        <div id="signature-pad" className="signature-pad">
                          <div className="signature-pad--body">
                            <div
                              className="signature-wrap"
                              ref={signatureWrapRef}
                            >
                              <SignatureCanvas
                                canvasProps={{
                                  id: "myID",
                                  width: signatureWrapWidth,
                                  height: signatureWrapHeight,
                                }}
                                ref={signPadRef}
                                onEnd={() => {
                                  if (!signPadRef.current.isEmpty()) {
                                    dispatch({
                                      type: "signPadChange",
                                      payload: { value: false },
                                    });
                                    dispatch({
                                      type: "validSignature",
                                      payload: { value: false },
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="signature-pad--footer pt-1">
                          {(state.isCanvasEmpty && state.isValid) &&(
                              <div
                                className="text-center error_msg mb-3"
                                id="signatures_required"
                              >
                                Signature is Required!
                              </div>
                            )}
                            {(state.isCanvasEmpty && !(state.isValid)) && (
                              <div
                                className="text-center error_msg mb-3"
                                id="signatures_required"
                              >
                                Signature is Required!
                              </div>
                            )}
                            {(!(state.isCanvasEmpty) && state.isValid) && (
                              <div
                                className="text-center error_msg mb-3"
                                id="signatures_valid"
                              >
                                Draw Valid Signature!
                              </div>
                            )}
                            <div className="signature-pad--actions margin-15">
                              <button
                                type="button"
                                id=""
                                name=""
                                className="text-size-smaller button smallest background-red clear_signature"
                                value="Clear"
                                onClick={clearSign}
                              >
                                <img
                                  src="/assets/TGG_V1/img/eraser.png"
                                  className="pe-2"
                                />
                                Clear
                              </button>
                            </div>
                            <p className="text-size-smaller margin-5">
                              <strong>
                                Click here to view the{" "}
                                <a href={"/terms_and_conditions"} target="_blank">
                                <span className="link doc_preview_modal">
                                  terms &amp; conditions
                                </span>
                                </a>
                              </strong>
                            </p>
                            <p className="text-size-smaller margin-5">
                              I agree that I have read, understand and accept
                              the Courmacs Legal Limited terms of business and
                              that the check is free. I also agree to instruct
                              Courmacs Legal Limited to send a data subject
                              access request (DSAR) to find out if my PCP or HP
                              agreements were mis-sold
                            </p>
                            <p className="text-size-smaller margin-none">
                              By clicking{" "}
                              <strong>'Submit &amp; complete'</strong> you agree
                              that Courmacs Legal Limited will carry out a soft
                              credit check to validate your case and verify your
                              identity. I understand this check will NOT affect
                              my credit score
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="form-navigation">
                        <ul>
                          <li>
                            <button
                              className="button submit"
                              type="button"
                              disabled={disabled}
                              onClick={handleClick}
                              value="Submit"
                            >
                              {" "}
                              Submit &amp; complete{" "}
                              <img
                                src="/assets/TGG_V1/img/arrow-right.png"
                                className="px-2"
                              />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-right bounce-y top-up d-none d-md-block">
                      <div className="badge">
                        <div className="content">
                          <p className="h6 margin-none fw-bold">
                            Posible Claim Value
                          </p>
                          <h2 className="margin-none fw-bold">£5,318*</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="map">
              <img src="/assets/TGG_V1/img/uk-map.svg" />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default SignatureTGGV1;

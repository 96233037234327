import React, {useState,useRef,useEffect} from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import Header from "../../Includes/Layouts/TGG_V2/Header.js";
import Footer from "../../Includes/Layouts/TGG_V2/Footer.js";
import Section1 from "../../Includes/Layouts/TGG_V2/Section1.js";
import AccordSectionV2 from "../../Includes/Layouts/TGG_V2/AccordianSectionV2.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import FormTGG_V2 from "../../Forms/FormTGG_V2.js";
import "../../../assets/TGG_V2/CSS/TGG_V2.scss";
import "../../../assets/TGG_V2/CSS/animate.min.css";
import SubmitLoader from "../../Includes/Layouts/TGG_V2/SubmitLoader.js";
const TGG_V1 = () => {
  const [submitLoader,SetSubmitLoader]=useState("hide");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [showCookie, setShowCookie] = useState("hide");


  const cookieHide = () =>{
    setShowCookie("hide")
  }

  useEffect(() => {
    setTimeout(() => {
      setShowCookie("show")
    }, 3000);
  }, [])

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="TGG_V2" />
      <FbViewPixel />
      <div className="TGG_V2">
      <div className="__PCPtcg">
        <Header ref1={ref1} ref2={ref2}/>
       <div className="bg_lol">
      {/* ///Form? */}
      <FormTGG_V2
      SetSubmitLoader={SetSubmitLoader}
      />
      </div>
      <Section1 ref1={ref1}/>
      <AccordSectionV2 ref2={ref2}/>
      <Footer/>
      <div
            className={`sticky-footer p-lg-4 p-md-4 p-sm-2 p-2 ${showCookie}`}
          >
            <div className="d-flex">
              <div>
                <p className="text-start mb-0">
                  By continuing to use this site, I agree to the{" "}
                  <a href="/cookie-policy" className="link" target="_blank">
                    cookie policy.
                  </a>{" "}
                  Necessary cookies are used to ensure the site functions
                  properly. When applying with us or our clients, interacting
                  with this page indicates acceptance of our cookie policy. If
                  you do not wish to use cookies, please exit the page.
                </p>
              </div>
              <div>
                <div>
                  <button
                    type="button"
                    className=" cookieclose border-0 rounded-1"
                    onClick={() => cookieHide("hide")}
                  >
                    <i className="bi bi-x-lg"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
      <SubmitLoader submitLoader={submitLoader}/>
    </>
  );
};

export default TGG_V1;

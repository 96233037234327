import React, { useState } from "react";
import "../../../assets/TGG_V1_OLD/css/TGG_V1_OLD.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Header from "../../Includes/Layouts/TGG_V1_OLD/Header";
import FormTGG_V1_OLD from "../../Forms/FormTGG_V1_OLD";
import Section1 from "../../Includes/Layouts/TGG_V1_OLD/Section1";
import AccordionSection from "../../Includes/Layouts/TGG_V1_OLD/AccordionSection";
import Footer from "../../Includes/Layouts/TGG_V1_OLD/Footer";
import FbViewPixel from "../../../Utility/FbViewPixel";
import Loader1 from "../../Includes/Layouts/TGG_V1_OLD/Loader1";
import ProgressBar from "../../Includes/Layouts/TGG_V1_OLD/ProgressBar";
const TGG_V1_OLD = () => {
  const [loader1, setLoader1] = useState("hide");
  const [loader2, setLoader2] = useState("hide");
  const [progressBar, setProgressBar] = useState({status: 'hide', width: '0%'});

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="TGG_V1_OLD" />
      <FbViewPixel />
      <div className="TGG_V1_OLD">
        <ProgressBar progressBar={progressBar}/>
        <Header />
        <section className="form select-inc" id="firstform">
          <FormTGG_V1_OLD
            setLoader1={setLoader1}
            setLoader2={setLoader2}
            setProgressBar={setProgressBar}
          />
        </section>
        <Section1 />
        <AccordionSection />
        <Footer />
        <Loader1 loader1={loader1} loader2={loader2} />
      </div>
    </>
  );
};
export default TGG_V1_OLD;

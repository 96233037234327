import { useState } from "react";
import { LenderList } from "../Constants/Lender";

export const useLenderList = ()=>{
    // const [isLoanListLoading, setIsLoanListLoading] = useState(false);
    const lenderList = async()=>{
      return LenderList;
    }
    
    return {lenderList}

} 
import React, { useState } from "react";
import { Salutation } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import ContactDetails from "../TGG_V1_A/ContactDetails";
import Sign from "../TGG_V1_A/Sign";
import PostCode from "../TGG_V1_A/PostCode";
import { useFormContext } from "react-hook-form";
import Input from "../TGG_V1_A/Input";
import DateOfBirth from "../TGG_V1_A/DateOfBirth";
import PrevAddressPopup from "../TGG_V1_A/PrevAddressPopup";
import useValidDOB from "../../../../Hooks/useValidDOB";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";


const PersonalDetails = ({
  formSubmit,
  pageChange,
  divShow,
  setSignatureDataURL,
  setPreviousAddressData,
  setAnalyseModel }) => {
  const {
    trigger,
    clearErrors,
    register,
    getValues,
    errors,
    setError,
  } = useFormContext();
  // const form = splitForm.current;
  const question_3 = getValues();
  const [showRegisterPop, setShowRegisterPop] = useState(false);
  const [titleQuest, setTitleQuest] = useState(false);
  const [notDobValid, setNotDobValid] = useState(false);
  const { validateDateOfBirth } = useValidDOB();
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");

    var salutation = question_3.lstSalutation;
    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    var lstPostResult;
    var address1Result;
    var txtPhoneResult;
    var txtEmailResult;
    var dobday = document.getElementById("DobDay").value;
    var dobmonth = document.getElementById("DobMonth").value;
    var dobyear = document.getElementById("DobYear").value;
    var phone = document.getElementById("txtPhone").value;
    var email = document.getElementById("txtEmail").value;

    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstPostResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstPostResult) {
      address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (address1Result) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("address1").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        document.getElementById("txtPhone").focus();
        return false;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        document.getElementById("txtPhone").focus();
        return false;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        document.getElementById("txtPhone").focus();
        return false;
      } else {
        txtPhoneResult = true;
      }
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    
    if (txtPhoneResult) {
      txtEmailResult = await trigger("txtEmail");
      if(txtEmailResult){
        const getEmailValidation = await emailValidation(email);
        if (getEmailValidation.data.status_code === 1) {
          txtEmailResult = true;
        } else {
          setError("txtEmail", {
            type: "manual",
            message: getEmailValidation.data.message,
          });
          document.getElementById("txtEmail").focus();
          return false;
        }
      }else{
        document.getElementById("txtEmail").focus();
        return false;
      }
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }
    if (txtEmailResult) {
      const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);

      if (!dobIsValid) {
        document.getElementById("DobDay").focus();
        setNotDobValid(true);
        return false;
      } else {
        setNotDobValid(false);
      }
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !lstPostResult ||
      !txtPhoneResult ||
      !txtEmailResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      if (salutation == "Mrs" || salutation == "Ms" || salutation == "Miss" || salutation == "Dame" || salutation == "Lady") {
        setTitleQuest(true);
      }
      else {
        setTitleQuest(false);
      }
      return true;
    } else {
      return false;
    }
  };
  const handleClose = () => {
    setShowRegisterPop(false);
  };

  const openPrevPopup = async () => {
    setShowRegisterPop(true);
  };
  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is invalid. Please recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };
  return (
    <>

      <div className={`sidesection py-2 pt-0 ${divShow}`}>
        <div>
          <div className="form-style form-body p-4 pb-5">
            <h2 className="mb-3  title">Register Your Claim Now</h2>
            <p>
              <span><img src="/assets/TGG_V1_A/img/tick.png" className="width-3"/>{" "} 15 - 20 Million Vehicles Affected</span><br />
              <span><img src="/assets/TGG_V1_A/img/tick.png" className="width-3"/>{" "} £16 Billion est. to be Paid Out</span><br />
              <span><img src="/assets/TGG_V1_A/img/tick.png" className="width-3"/>{" "} £5,318* Average Refund</span>
            </p>
            <div className="row">
              <div className="col-lg-2 mb-2">
                <SelectBox
                  className="form-select select1"
                  OptionValue={Salutation}
                  name="lstSalutation"
                  id="lstSalutation"
                  onChange={() => clearErrors("lstSalutation")}
                  clearErrors={clearErrors}
                  myRef={register({ required: "Please select your title" })}
                  validationMsg={
                    errors.lstSalutation && errors.lstSalutation.message
                  }
                ></SelectBox>
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <div className="input-group">
                    <Input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                      name="txtFName"
                      id="txtFName"
                      rules={{
                        required: "Please enter your first name",
                        minLength: {
                          value: 3,
                          message: "Please enter valid first name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your name is invalid. Please recheck",
                        },
                      }}
                      transform={(value) =>
                        value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <div className="input-group">
                    <Input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                      name="txtLName"
                      id="txtLName"
                      rules={{
                        required: "Please enter your last name",
                        minLength: {
                          value: 3,
                          message: "Please enter valid last name",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "Your name is invalid. Please recheck",
                        },
                      }}
                      transform={(value) =>
                        value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                      }
                    />
                  </div>
                </div>
              </div>
              <PostCode />
            </div>
            <PrevAddressPopup
              show={showRegisterPop}
              handleClose={handleClose}
              formSubmit={formSubmit}
              setPreviousAddressData={setPreviousAddressData}
              titleQuest={titleQuest}
            />

            <DateOfBirth
              textDob="Date Of Birth"
              notDobValid={notDobValid}
            />

            <ContactDetails />

            <Sign
              formSubmit={formSubmit}
              pageChange={pageChange}
              basicDetailsValidation={basicDetailsValidation}
              openPrevPopup={openPrevPopup}
              titleQuest={titleQuest}
              setSignatureDataURL={setSignatureDataURL}
              setShowRegisterPop={setShowRegisterPop}
              setAnalyseModel={setAnalyseModel}
            />

          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;

import React, { useRef, useState, useContext, useReducer, useEffect } from "react";
import Testimonial from "../Includes/Layouts/TGG_V1/Testimonial.js";
import AccordSection from "../Includes/Layouts/TGG_V1/AccordSection";
import Footer from "../Includes/Layouts/TGG_V1/Footer";
import "../../assets/TGG_V1_OLD/css/CarRegistrationV1.scss";
import "../../assets/TGG_V1_OLD/css/Car_Custom.scss";
import FormCarRegistration from "../Includes/CarReg_V1/FormCarRegistration";
import LoaderCar from "../Includes/CarReg_V1/LoaderCar";
import { useForm } from "react-hook-form";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import VisitorParamsContext from "../../Contexts/VisitorParams"
import { queryString } from "../../Utility/QueryString";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { FormData } from "../../Utility/FormData";
import Header from "../Includes/Layouts/TGG_V1_OLD/Header";
const CarRegistration = () => {

    const [carLoader, SetCarLoader] = useState("show");
    const [VeichleModal, SetVeichleAddModal] = useState("hide");
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    const query_string = localStorage.getItem("querystring");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const { visitorParameters } = useContext(VisitorParamsContext);
    const splitForm = useRef(null);
    const [splitName, setSplitName] = useState();
    const divRefOne = useRef(null);
    const split_name =localStorage.getItem("split_name");

    useEffect(() => {
        const timer = setTimeout(() => {
            SetCarLoader("hide");
            scrollToDivWithDelay();
        }, 3000);
    }, []);



    const scrollToDivWithDelay = () => {
        setTimeout(() => {
            divRefOne.current.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
    };


    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });


    const getUuid = () => {
        const visitorData = visitorParameters.visitor_parameters;
        if (visitorData != "") {
            var uuid = visitorData.uuid;
        } else {
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem("uuid");
            var uuid = query_uuid ? query_uuid : local_storage_uuid;
        }
        return uuid;
    };
    const uuid = getUuid();

    return (
        <>
            <GetVisitorsParams />
            <div className="LenderTGGV1" id="maincarV1">
                <div className="position-sticky top_mob_sticky d-block d-md-none">
                    <div className="banner text-weight-600">
                        <img src="/assets/TGG_V1/img/warning.png" alt /> £16 billion estimated to be paid out
                    </div>
                </div>
                <div className="progress progress_main d-none">
                    <div className="progress-bar">
                        <div id="progressBarInner" style={{ width: '0%' }} />
                    </div>
                </div>
                
                <Header/>
                <section className="form">
                    <form
                        ref={splitForm}
                        name="split_form"
                        id="user_form"
                        autoComplete="off"
                        className="main_form"
                    >
                        <div className="wrap larger">
                            <div className="container">
                                <div className="step start" id="step_lender">
                                    <div className="form-right bounce-y top-up d-block d-md-none">
                                        <div className="badge">
                                            <div className="content">
                                                <p className="fs-6 mb-0 text-weight-600 d-flex justify-content-center text-start">
                                                    <img src="/assets/TGG_V1/img/information-button.png" alt className="w-auto mt-1" />&nbsp; Additional information required to
                                                </p>
                                                <p className="fs-6 margin-5 text-weight-600 d-flex justify-content-center  text-start">
                                                    <strong>expedite</strong> &nbsp;your claim process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-left" ref={divRefOne}>
                                        <h1 className="margin-10">
                                            Car Registration
                                        </h1>
                                        <h4 className="margin-10">Providing your car registration number will help us expedite your claim process.</h4>
                                        <div className="field">
                                            <FormCarRegistration
                                                validation={register}
                                                validationMsg={errors}
                                                getValues={getValues}
                                                trigger={trigger}
                                                setError={setError}
                                                SetVeichleAddModal={SetVeichleAddModal}
                                                uuid={uuid}
                                                split_name={split_name}
                                            />
                                        </div>
                                        <div className="form-right bounce-y top-up d-none d-md-block">
                                            <div className="badge">
                                                <div className="content">
                                                    <p className="fs-6 mb-0 text-weight-600 d-flex justify-content-center text-start">
                                                        <img src="/assets/TGG_V1/img/information-button.png" alt className="w-auto mt-1" />&nbsp; Additional information required to
                                                    </p>
                                                    <p className="fs-6 margin-5 text-weight-600 d-flex justify-content-center  text-start">
                                                        <strong>expedite</strong> &nbsp;your claim process.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="map">
                        <img src="/assets/TGG_V1/img/uk-map.svg" alt />
                    </div>
                </section>

                <Testimonial />
                <AccordSection />
                <Footer />
                <LoaderCar carLoader={carLoader} />




            </div>



        </>
    );

};

export default CarRegistration;
export const DateOfBirth = [{ label: "DD", value: "" }];
const startDay = 1;
const endDay = 31;
for (var i = startDay; i <= endDay; i++) {
  var objDobDay = {};
  if(i<10){
    objDobDay["label"] = `0${i}`;
  objDobDay["value"] = `0${i}`;
  }else{
  objDobDay["label"] = i;
  objDobDay["value"] = i;
  }
  DateOfBirth.push(objDobDay);
}

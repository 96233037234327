import React, { useEffect, useState, useReducer, useRef, useContext } from "react";
import { useLenderList } from "../../../../Hooks/LendersLists";
import Select from "react-select";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
const Lenders = ({ setLenderSelectedList, className, slideChange }) => {
    const [lendersloop, setLendersloop] = useState({});
    const [lendersTop, setLendersTop] = useState({});
    const [lendersAddloop, setAddLendersloop] = useState({});
    const { lenderList } = useLenderList();
    const [LenderSelectedValue, setLenderSelectedValue] = useState([]);
    const [selectedAddOptions, setSelectedAddOptions] = useState([]);
    const [additionalLenders, setAdditionalLenders] = useState();
    const [isLenders, setIsLenders] = useState(false);
    const [LenderValidationMsg, setLenderValidationMsg] = useState();
    const [otherLenders, setOtherLenders] = useState("hide");
    const [isChecked, setIsChecked] = useState(false);
    const lenderRef = useRef(null);

    const PageChange = async (e) => {
        if (isLenders && LenderSelectedValue.length >= 1) {
            setLenderValidationMsg(false);
            window.scrollTo(0, 0);
            localStorage.setItem("Lenders", JSON.stringify(LenderSelectedValue));
            setLenderSelectedList(LenderSelectedValue);
            let lendersArray = lendersloop.lenders || [];
            let additionalLendersArray = lendersTop.lenders || [];
            let otherLenderArray = lendersAddloop.other_lenders || [];
            let combinedLenders = lendersArray.concat(additionalLendersArray, otherLenderArray);

            let selectedIds = LenderSelectedValue.map((lender) => lender.lenderName);

            let unselectedlenders = combinedLenders.filter(
                (lender) => !selectedIds.includes(lender.lenderName)
            );
            localStorage.setItem(
                "UnselectedLender",
                JSON.stringify(unselectedlenders)
            );
            lenderPopup('hide');
            GtmDataLayer({
                question: "which lender",
              });
            slideChange(e);
        } else {
            lenderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setIsLenders(false);
            setLenderValidationMsg("Please select at least one lender");
            return false;
        }
    };
    const onSelectTopLender = (e) => {
        const { id, name, value, checked } = e.target;
        if (checked) {
            setIsLenders(true);
            setLenderValidationMsg(false);
            setLenderSelectedValue((prevSelected) => [
                ...prevSelected,
                {
                    id: id,
                    lenderName: name,
                    lender_value: value,
                },
            ]);
        } else {
            setLenderSelectedValue((prevSelected) =>
                prevSelected.filter((lender) => lender.id !== id)
            );
            setSelectedAddOptions((prevOptions) =>
                prevOptions.map((option) =>
                    option.id === id ? { ...option, label: name } : option
                )
            );
        }
    };

    const handleSelectChange = (selectedOptions) => {
        if (
            !selectedAddOptions.some((option) => option.id === selectedOptions.id)
        ) {
            setSelectedAddOptions([...selectedAddOptions, selectedOptions]);
        }
    };


    const lenderPopup = (display) => {
        if (display == "show") {
            setLenderValidationMsg(false);
        }
        setIsChecked(!isChecked);
        setOtherLenders(display);
    };

    let lendersOption;
    useEffect(() => {
        (async () => {
            const lenders = await lenderList();
            setLendersloop({ lenders: lenders.data.response.lenders });
        })();
    }, []);

    const customStyles = {
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display: 'none', 
        }),
        control: (provided, state) => ({
          ...provided,
          borderStyle: 'none', 
          border: 'none', 
          boxShadow: 'none', 
        }),
      };

    if (lendersloop.lenders) {
        lendersOption = lendersloop.lenders.map((LenderData, index) => {
            return (
                <div className="col-lg-12 mb-1 text-center" key={index}>
                    <div className="selectoptions2">
                        <div>
                            <input
                                type="checkbox"
                                className={`custom-checkbox`}
                                onChange={onSelectTopLender}
                                name={`${LenderData.lenderName}`}
                                id={`${LenderData.id}`}
                                value={LenderData.value}
                            />
                            <label htmlFor={LenderData.id} className="selected-false"><span className="option_title title_1">{LenderData.lenderName}</span></label>
                        </div>
                    </div>
                </div>
            );
        });
    }

    let AdditionalOption;
    useEffect(() => {
        (async () => {
            const lenders = await lenderList();
            setLendersTop({ lenders: lenders.data.response.additional_lenders });
        })();
    }, []);

    if (lendersTop.lenders) {
        AdditionalOption = lendersTop.lenders.map(
            (LenderData, index) => {
                return (
                    <div className="mb-1" key={index}>
                        <input
                            type="checkbox"
                            className={`test`}
                            onChange={onSelectTopLender}
                            name={`${LenderData.lenderName}`}
                            id={`${LenderData.id}`}
                            value={LenderData.value}
                        />
                        <label htmlFor={LenderData.id} className="selected-false">
                            <span className="option_title title_1">{LenderData.lenderName}</span>
                        </label>
                    </div>
                );
            }
        );
    }


    let Additionallendersoption;
    useEffect(() => {
        (async () => {
            const lenders = await lenderList();
            setAddLendersloop({
                other_lenders: lenders.data.response.other_lenders,
            });
        })();
    }, []);
    if (lendersAddloop.other_lenders) {
        Additionallendersoption = lendersAddloop.other_lenders.map(
            (LenderData, index) => ({
                id: LenderData.id,
                value: LenderData.value,
                label: LenderData.lenderName,
            })
        );
    }
    // passing value from selectbox to second checkbox
    useEffect(() => {
        const Additionallendersoptiondis = selectedAddOptions.map(
            (LenderData, index) => (
                <div className="mb-2" key={index}>
                    <input
                        type="checkbox"
                        className={`custom-checkbox`}
                        onChange={onSelectTopLender}
                        name={`${LenderData.label}`}
                        id={`${LenderData.id}`}
                        value={LenderData.value}
                    />
                    <label htmlFor={`${LenderData.id}`} className="selected-false">
                    <span className="option_title title_1">{LenderData.label}</span>
                    </label>
                </div>
            )
        );
        setAdditionalLenders(Additionallendersoptiondis);
    }, [selectedAddOptions]);
    
    return (

        <>
            <div id="slide9" className={`${className}`}>
                <div className={`home_plate_001`} ref={lenderRef}>
                    <div className="row">
                    <div className="d-block d-md-none">
                                <h1 className="cs_hero_title text-lg-start text-md-center text-sm-center text-center animate__animated animate__fadeInUp  animate__delay-1s   cs_fs_94 ">
                                Taken Out Vehicle Finance Before 2021?
                                </h1>
                                <h2 className="banner-subtext animate__animated animate__fadeInUp animate__delay-1s fs-6 text-lg-start text-md-start text-lg-center text-center">
                                Starting your claim is easy and 100% online. You could recover <b>£5,318<sup>*</sup></b></h2>
                                </div>
                        <div className="cs_hero_text col-lg-6 col-12 order-lg-1 order-md-1 order-md-2 order-sm-2 order-2">
                            <div className="plateOne_anim mt-lg-0 mt-md-5 mt-sm-5 mt-0" id="plate_1" >
                                <div className="d-none d-md-block">
                                <h1 className="cs_hero_title text-lg-start text-md-center text-sm-center text-center animate__animated animate__fadeInUp  animate__delay-1s   cs_fs_94 ">
                                Taken Out Vehicle Finance Before 2021?
                                </h1>
                                <h2 className="banner-subtext animate__animated animate__fadeInUp animate__delay-1s">
                                Starting your claim is easy and 100% online. You could recover <b>£5,318<sup>*</sup></b></h2>
                                </div>
                                <div className="form_drop_1 col-lg-12 col-12">
                                    <div className="sect_bt animate__animated animate__fadeInUp animate__delay-1s">
                                        <p className="png_t">
                                            Which lender(s) did not tell you they would receive a commission against the loan?
                                        </p>

                                        <div className="lenderpopslide2" >
                                            <div className="selectoptions animate__animated animate__fadeInUp animate__delay-1s">
                                                {lendersOption}
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        className="test"
                                                        onChange={() => lenderPopup("show")}
                                                        checked={isChecked}
                                                        name="other"
                                                        id="other"
                                                        value="0"
                                                    />
                                                    <label htmlFor="other" className="selected-false">
                                                        <span className="option_title title_1">OTHER</span>
                                                    </label>
                                                </div>

                                            </div>
                                            <span className={`error_msg mt-2 mb-2 ${otherLenders === 'show' ? 'hide' : 'show'}`}>{LenderValidationMsg}</span>

                                            <div className=" is_sticky box_button sign_Btn_paGe pt-4 animate__animated animate__fadeInUp animate__delay-1s animated-element">
                                                <div className="bg-1 hme_btn">
                                                    <input
                                                        type="button"
                                                        className="button button--moema button--text-thick button--text-upper button--size-s blick_notice pulse"
                                                        id="nextPlate2_001"
                                                        value="Next"
                                                        name="lenders"
                                                        onClick={PageChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ul className="secur-ul d-lg-flex text-center ps-0 ms-0 my-4 animate__animated animate__fadeInUp animate__delay-1s">
                                    <li className="order-lg-2 mb-lg-0 mb-3 ps-lg-3"> <i className="bi bi-clock-history"></i> Takes only 60 Seconds
                                    </li>
                                    <li className="order-lg-1">
                                        <div className="    ">
                                            <img height="45" src="/assets/TGG_V2/img/ssl.png" alt="" />
                                        </div></li>
                                </ul>
                            </div>
                        </div>
                        <div className="img_float animate__animated animate__fadeInUp order-lg-2 order-md-1 order-sm-1 order-1 col-lg-6 col-12 text-center">
                            <div className="banner-wrap  ">
                                {/* <div className="text-cicle">
                                    <img className="rotate" src="/assets/TGG_V2/img/circle-info-text.png" alt="" />
                                    <div className="amount">£ 5,318*</div>
                                </div> */}
                                <div className="shape1"></div>
                                <div className="shape2"></div>
                                <img src="/assets/TGG_V2/img/bg-float2.png" alt="Hero" className=" " />
                            </div>
                        </div>

                    </div>
                </div>

              {/* // Modal Other Lenders */}

                    <div
                        className={`modal fade ${otherLenders} `}
                        id="otherLenders"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content animate__animated animate__zoomIn animate__delay-.5s ">
                                <div className="modal-body selectIndex">
                                    <div className="position-absolute top-0 end-0 m-0">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => lenderPopup("hide")}
                                        ></button>
                                     </div>
                                    <div className="text-center mb-3">
                                        {/* <img height={40} src="dist/img/logo.png" alt="" /> */}
                                        <LogoBasedOnDomain height={40}/>
                                    </div>
                                    <div className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s">
                                        <p className="png_t">
                                            Select the lender(s) that you used to finance your vehicle(s):
                                        </p>
                                        {/* Select Box Lenders*/}
                                        <div className="lenderpopslide2">
                                            <div className="selectoptions animate__animated animate__fadeInUp animate__delay-.5s">
                                                {AdditionalOption}
                                            </div>
                                            <div className="mb-3 mx-0 select_financeProvider animate__animated animate__fadeInUp animate__delay-.5s selectIndex">

                                                <Select
                                                    className={`form-select select1`}
                                                    placeholder="Another lender .."
                                                    options={Additionallendersoption}
                                                    name={lendersAddloop}
                                                    onChange={handleSelectChange}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            {/* option select view lender */}
                                            <div className="col-12 selectoptions animate__animated animate__fadeInUp animate__delay-.5s mb-3 ">
                                                {additionalLenders}
                                            </div>
                                        </div>
                                        {LenderValidationMsg ? (
                                            <span className={`error_msg mb-2 ${otherLenders === 'show' ? 'show' : 'hide'}`}>{LenderValidationMsg}</span>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                </div>
                                <div className="box_button animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="bg-1">
                                        <input
                                            type="button"
                                            className="button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                                            id="nextPlate_005"
                                            defaultValue="Next"
                                            name="lenders"
                                            onClick={PageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

        </>



    );

};

export default Lenders;
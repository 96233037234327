import React, {
    useState,
    useReducer,
    useRef,
    useContext,
    useEffect,
  } from "react";
  import PersonalDetails from "../Includes/Layouts/TGG_V1_A/PersonalDetails.js";
  import Lenders from "../Includes/Layouts/TGG_V1_A/Lenders.js";
  import { FormProvider, useForm } from "react-hook-form";
  import { useHistory } from "react-router-dom";
  import { useDataIngestion } from "../../Hooks/useDataIngestion";
  import QueryStringContext from "../../Contexts/QueryString";
  import VisitorParamsContext from "../../Contexts/VisitorParams";
  import * as EnvConstants from "../../Constants/EnvConstants";
  import { useSignature } from "../../Hooks/useSignature";
  import LogoBasedOnDomain from "../Includes/Common/LogoBasedOnDomain";
  import { useDuplicateCheck } from "../../Hooks/useDuplicateCheck";
  import { UseLeadApi } from "../../Hooks/UseLeadApi";
  import GtmDataLayer from "../../Utility/GtmDataLayer";
  import { useRedirectProbability } from "../../Hooks/useRedirectProbability.js";
  import RedirectModule from "../../Utility/RedirectModule";

  const FormTGG_V1_A = ({setDivShow, divShow,setAnalyseModel}) => {
    const formMethods = useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
    const {
      register,
      errors,
      trigger,
      setError,
      clearErrors,
      getValues,
      watch,
      handleSubmit,
    } = formMethods;
  
    const { visitorParameters } = useContext(VisitorParamsContext);
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const [previousAddressData, setPreviousAddressData] = useState({});
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    const { saveSignature } = useSignature();
    const [signatureDataURL, setSignatureDataURL] = useState("");
    const history = useHistory();
    const splitForm = useRef(null);
    const signPadRef = useRef(null)
    const lenderRef = useRef(null);;
    const [LenderSelectedList, setLenderSelectedList] = useState([]);
    const { duplicateCheck } = useDuplicateCheck();
    const { LeadApiIn } = UseLeadApi();
    const domainName = window.location.hostname;
    const { probablityPage } = useRedirectProbability();

    const field = "pid";
    const { saveDataIngestion, formDataIngestion, isLoading } =
      useDataIngestion();
    const { queryString } = useContext(QueryStringContext);
  
    useEffect(() => {
      if (signatureDataURL !== "") {
        setSignatureDataURL(signatureDataURL);
      }
    }, [signatureDataURL]);
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
  
    const formSubmit = async () => {
      GtmDataLayer({
        question: "signature",
      });
      if (formData.address1 !== "") {
        const txtUdprn = form["txtUdprn"].value;
        const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
        const txtPz_mailsort = form["txtPz_mailsort"].value;
        const txtStreet = form["txtStreet"].value;
        const txtAddress3 = form["txtAddress3"].value;
        const txtDistrict = form["txtDistrict"].value;
  
        formData.txtDistrict = txtDistrict;
        formData.txtAddress3 = txtAddress3;
        formData.txtUdprn = txtUdprn;
        formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
        formData.txtPz_mailsort = txtPz_mailsort;
        formData.txtStreet = txtStreet;
      }
  
      formData.user_sign = signatureDataURL;
      formData.Previousname = form["txtPreName"].value;
      formData.lenders = LenderSelectedList;
  
      formData.page_name = "TGG_V1_A";
      formData.pCode_manual = form["pCode_EntryType"].value;
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {
        // let dobMonth = formData.DobMonth ? MonthNameArray[formData.DobMonth] : '';
        localStorage.setItem("fName", formData.txtFName);
        localStorage.setItem("lName", formData.txtLName);
        localStorage.setItem("pCode", formData.txtPostCode);
        localStorage.setItem("addressLine1", formData.txtHouseNumber);
        localStorage.setItem("dobYr", formData.DobYear);
        localStorage.setItem("dobMon", formData.DobMonth);
        localStorage.setItem("dobDy", formData.DobDay);
        localStorage.setItem("addressLine2", formData.txtAddress2);
        localStorage.setItem("postTown", formData.txtTown);
        localStorage.setItem("country", formData.txtCounty);
        localStorage.setItem("title", formData.lstSalutation);
        localStorage.setItem("addressLine3", formData.txtAddress3);
        localStorage.setItem("county", formData.txtCounty);
        localStorage.setItem(
          "district",
          formData.txtDistrict ? formData.txtDistrict : ""
        );
        let UcFname =
          formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);
  
        localStorage.setItem("UcFname", UcFname);
  
        localStorage.setItem("formData", JSON.stringify(formData));
        localStorage.setItem("queryString", queryString);
  
        const formSUbmitResult = formDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          "TGG_V1_A",
          queryString,
          visitorParameters.data
        );
      } else {
        console.log('Fail');
        return "Fail";
      }
    };
  
    useEffect(() => {
      const handleSaveSignature = async () => {
        if (signatureDataURL !== "") {
          localStorage.setItem("userSign", signatureDataURL);
          const message_type = "signature_store";
          formData.page_name = "TGG_V1_A";
          const response = await saveSignature(
            signatureDataURL,
            visitorParameters.visitor_parameters,
            formData,
            visitorParameters.data,
            queryString,
            message_type
          );
          if (response.data.status === "Success") {
            const probabilityCheck = await probablityPage(uuid);  
              if(probabilityCheck.data.status === "Success"){
                let pageAProbability = probabilityCheck.data.percentage1;
                // pageBProbability = probabilityCheck.data.percentage2;
                console.log("first value",pageAProbability);    
                 pageChange({pageAProbability});
              } else {
                let pageAProbability = "1";
                pageChange({pageAProbability});
              }
          }
        }
      };
  
      handleSaveSignature();
    }, [signatureDataURL]);
  
    const pageChange = async ({pageAProbability}) => {
      
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            // history.push("/fbpixel?split_name=TGG_V1_A");
            history.push(`/fbpixel?split_name=TGG_V1_A&pcp=${pageAProbability}`);
          } else {
            console.log("case1");
            window.scrollTo(0, 0);
            const redirect = RedirectModule({
              splitName: "TGG_V1_A",
              uuid: visitorParameters.visitor_parameters.uuid,
              pageAProbability: pageAProbability,
            });
            history.push(redirect);
          }
        } else {
          console.log("case2");
          window.scrollTo(0, 0);
          const redirect = RedirectModule({
            splitName: "TGG_V1_A",
            uuid: visitorParameters.visitor_parameters.uuid,
            pageAProbability: pageAProbability,
          });
          history.push(redirect);
        }
      } else {
        console.log("case3");
        window.scrollTo(0, 0);
        const redirect = RedirectModule({
          splitName: "TGG_V1_A",
          uuid: visitorParameters.visitor_parameters.uuid,
          pageAProbability: pageAProbability,
        });
        history.push(redirect);
      }
    };
  
    const getUuid = () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData != "") {
        var uuid = visitorData.uuid;
      } else {
        const query = new URLSearchParams(window.location.search);
        const query_uuid = query.get("uuid");
        const local_storage_uuid = localStorage.getItem("uuid");
        var uuid = query_uuid ? query_uuid : local_storage_uuid;
      }
      return uuid;
    };
  
    useEffect(() => {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
  
      if (viewportMeta) {
        viewportMeta.remove();
      }
    }, [])
    
    const uuid = getUuid();
  
    return (
      <>
        <FormProvider {...formMethods}>
          <form
            ref={splitForm}
            name="split_form"
            id="user_form"
            method="POST"
            autoComplete="off"
          >
            <section className="banner">
              <div className="container-fluid caption-top">
                <p>
                <img src="../../assets/TGG_V1_A/img/anno.png" width={38} height={26}/>Act Before the FCA Moratorium Ends in September!
                </p>
              </div>
              <div className="container">
                <div>
                  <div className="brand">
                    <div className="logo pt-3">
                      <LogoBasedOnDomain style={{ width: 200 }} />
                    </div>
                  </div>
                </div>
                <div className="container-xxl">
                  <div className="banner-inner">
                    <div className="row">
                      <div className="col-lg-7 col-md-12 col-sm-12  ">
                        <div className="banner-caption">
                          <div className="overflow-hidden">
                            <h1 className="fw-bold  drop-in tex-lg-start text-md-start text-sm-center text-center">
                              Taken Out Vehicle Finance Before 2021?
                            </h1>
                          </div>
  
                          <div className="overflow-hidden">
                            <p className="py-lg-3 py-md-3 py-sm-3 py-1 pt-0 drop-in-2 mb-0 mb-sm-3 subtext tex-lg-start text-md-start text-sm-center text-center">
                              Starting your claim is easy and 100% online. You could recover <b>£5,318<sup>*</sup></b>
                            </p>
                          </div>
                          <div className="d-block d-lg-none d-md-block d-sm-block py-1">
                            <div className="banner-car   text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                              <div className="review drop-in-3  d-none">
                                <div className="overflow-hidden ">
                                  <img
                                    src="../../assets/TGG_V1_A/img/review-star.png"
                                    className="drop-in-3 "
                                    alt=""
                                  />
                                </div>
                              </div>
                              <img
                                className=" car-img fade-in"
                                src="../../assets/TGG_V1_A/img/banner-img.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div
                            id="slide-1"
                            className="overflow-hidden col-12 top_minus_1 mt-lg-0 mt-md-0 mt-sm-0 mt-3"                        
                          >
                            <p ref={lenderRef} className="fs-2">&nbsp;</p>
                            <Lenders
                              setLenderSelectedList={setLenderSelectedList}
                              setDivShow={setDivShow}
                              lenderRef={lenderRef}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12 col-sm-12  d-lg-block d-md-none d-none">
                        <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                          <div className="review drop-in-3 d-none">
                            <div className="overflow-hidden ">
                              <img
                                src="../../assets/TGG_V1_A/img/review-star.png"
                                className="drop-in-3 "
                                alt=""
                              />
                            </div>
                          </div>
                          <img
                            className="bannerimg fade-in"
                            src="../../assets/TGG_V1_A/img/banner-img.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <PersonalDetails
              formSubmit={formSubmit}
              pageChange={pageChange}
              divShow={divShow}
              setPreviousAddressData={setPreviousAddressData}
              setSignatureDataURL={setSignatureDataURL}
              setAnalyseModel={setAnalyseModel}
            />
          </form>
        </FormProvider>
      </>
    );
  };
  
  export default FormTGG_V1_A;
  
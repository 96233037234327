import React, { useState, useEffect } from "react";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const Header = ({ref1,ref2}) => {
    const [isSticky, setSticky] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setMenuOpen(!isMenuOpen);
    };
    const scrollToElement = (element) => {
        if(element){
        element.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      };
    const buttontotop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <header id="header" className={isSticky ? "sticky-header sticky" : "sticky-header"}>
                <div className={`topnav ${isMenuOpen ? 'responsive' : ''}`} id="myTopnav">
                    <a>
                        {/* <img className="logo" src="/assets/TGG_V2/img/logo.png" alt="" /> */}
                        <LogoBasedOnDomain className="logo"/>
                    </a>
                    <ul className="nav-menu">
                        <li>
                            <a onClick={buttontotop}>Claim Now</a>
                        </li>
                        <li>
                            <a onClick={() => scrollToElement(ref1)}>About Us</a>
                        </li>
                        <li>
                            <a onClick={() => scrollToElement(ref2)}>FAQ</a>
                        </li>
                    </ul>
                    <a
                        style={{ fontSize: 25, marginLeft: "auto" }}
                        className="icon p-0"
                        onClick={handleMenuClick}
                    >
                        ☰
                    </a>
                </div>
            </header>
        </>
    );
};

export default Header;

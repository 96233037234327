import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
// import Split_1 from "./Components/Pages/Splits/Split_1";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Questionnaire from "./Components/Pages/Questionnaire";
import Followup from "./Components/Pages/Splits/Followup";
import Preview from "./Components/Pages/Preview";
import OpenBankingProceed from "./Components/Pages/OpenBankingProceed";
import Unqualified from "./Components/Pages/Unqualified";
// import Signature from "./Components/Pages/Signature";
import Thankyou from "./Components/Pages/Thankyou";
import IdUpload from "./Components/Pages/IdUpload"
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import BudsRedirectPage from "./Components/Pages/BudsRedirectPage";
import Proceed from "./Components/Pages/Proceed";
import PdfView from"./Components/Pages/PdfView";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import TGG_V1_OLD from "./Components/Pages/Splits/TGG_V1_OLD";
import LenderTGG_V1 from "./Components/Pages/LendersTGG_V1";
import CarRegistration from "./Components/Pages/CarRegistration";
import SignatureTGGV1 from "./Components/Pages/SignatureTGGV1";
import Id_upload from "./Components/Pages/Id_upload";
import ThankYouTGG from "./Components/Pages/ThankYouTGG";
import UnqualifiedTGG from "./Components/Includes/Layouts/TGG_V1_OLD/UnqualifiedTGG";
import PrivacyPolicy from "./Components/Includes/Common/PrivacyPolicy";
import ComplaintsPolicy from "./Components/Includes/Common/ComplaintsPolicy";
import Terms from "./Components/Includes/Common/Terms";
import TitleBasedOnDomain from "./Components/Includes/Common/TitleBasedOnDomain";
import EnvDocView from "./Components/Pages/Docs/EnvDocView";
import TGG_V1 from "./Components/Pages/Splits/TGG_V1";
import Analyze from "./Components/Pages/Splits/Analyze";
import TGG_V2 from "./Components/Pages/Splits/TGG_V2";
import SignatureTGGV2 from "./Components/Pages/SignatureTGGV2";
import CarRegistrationTGG_V2 from "./Components/Pages/CarRegistrationTGG_V2";
import LendersTGG_V2 from "./Components/Pages/LendersTGG_V2";
import IdUploadTGGV2 from "./Components/Pages/IdUploadTGGV2";
import ThankyouV2 from "./Components/Pages/ThankyouV2";
import AnalyzeV2 from "./Components/Includes/Layouts/TGG_V2/AnalyzeV2";
import CookiePolicy from "./Components/Includes/Common/CookiePolicy";
import TermsTgg from "./Components/Includes/Common/TermsTgg";
import TGG_V1_A from "./Components/Pages/Splits/TGG_V1_A";
import TGG_V1_B from "./Components/Pages/Splits/TGG_V1_B";
import QuestionnaireTGGV1B from "./Components/Pages/QuestionnaireTGGV1B";
import AnalyzeLead from "./Components/Pages/Splits/AnalyzeLead";
import SoftSearch from "./Components/Pages/SoftSearch";
import TermsPdf from "./Components/Includes/Common/TermsPdf";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    // document.title = EnvConstants.AppTitle;
    document.title = TitleBasedOnDomain();
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              {/* <Route exact path={["/Split_1"]} component={Split_1} /> */}
              {/* <Route exact path={"/questionnaire"} component={Questionnaire} /> */}
              <Route exact path={"/followup"} component={Followup} />
              <Route exact path={["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              {/* <Route exact path={"/unqualified"} component={Unqualified} /> */}
              {/* <Route exact path={"/signature"} component={Signature} /> */}
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/buds-redirect-page"} component={BudsRedirectPage} />
              <Route exact path={["/id-upload"]} component={IdUpload} />
              <Route exact path={["/preview"]} component={PreviewLoa} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/ADV_1"]} component={ADV_1} />
              <Route exact path={["/ADV1/contact"]} component={ADV1_Contact}/>
              <Route exact path={"/proceed-open-banking"} component={OpenBankingProceed} />
              <Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>
              <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
              <Route exact path={"/pcp/TGG_V1_OLD"} component={TGG_V1_OLD} />
              <Route exact path={"/lendersTGG_V1"} component={LenderTGG_V1} />
              <Route exact path={"/register-car-v1"} component={CarRegistration} />
              <Route exact path={"/pcp/signatureTGG"} component={SignatureTGGV1} />
              <Route exact path={"/ID_upload"} component={Id_upload} />
              <Route exact path={"/pcp/thankyou"} component={ThankYouTGG} />
              <Route exact path={"/pcp/unqualified"} component={UnqualifiedTGG} />
              <Route exact path={"/privacyTGG"} component={PrivacyPolicy} />
              <Route exact path={"/complaintsTGG"} component={ComplaintsPolicy} />
              <Route exact path={"/cookie-policy"} component={CookiePolicy} />
              {/* <Route exact path={"/terms_and_conditions"} component={Terms} /> */}
              <Route exact path={"/TermsTgg"} component={TermsTgg} />
              <Route exact path={["/doc/:docType/:filename"]} component={EnvDocView}/>
              <Route exact path={"/pcp/TGG_V1"} component={TGG_V1} />
              <Route exact path={"/pcp/analyze"} component={Analyze} />
              
              <Route exact path={"/pcp/TGG_V2"} component={TGG_V2} />
              <Route exact path={"/pcp/signatureTGGV2"} component={SignatureTGGV2} />
              <Route exact path={"/pcp/lendersTGG_V2"} component={LendersTGG_V2} />
              <Route exact path={"/pcp/register_car_TGG_V2"} component={CarRegistrationTGG_V2} />
              <Route exact path={"/ID_uploadV2"} component={IdUploadTGGV2} />
              <Route exact path={"/pcp/thankyouV2"} component={ThankyouV2} />
              <Route exact path={"/pcp/analyzeV2"} component={AnalyzeV2} />
              <Route exact path={"/pcp/softsearch"} component={SoftSearch} />

              <Route exact path={"/pcp/TGG_V1_A"} component={TGG_V1_A} />

                
              <Route exact path={"/pcp/TGG_V1_B"} component={TGG_V1_B} />
              <Route exact path={"/pcp/questionnaireTGG"} component={QuestionnaireTGGV1B} />
              <Route exact path={"/pcp/analyze-lead"} component={AnalyzeLead} />
              <Route exact path={"/terms_and_conditions"} component={TermsPdf} />
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;

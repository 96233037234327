import { useState } from "react";
import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useDataIngestion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const saveDataIngestion = async (
    visitor_parameters,
    data,
    message_type,
    page_name,
    query_string,
    visitor_data
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/data-ingestion-pipeline", {
      visitor_parameters,
      data,
      message_type,
      page_name,
      query_string,
      visitor_data,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'Data Ingestion : '+message_type,
        errorReason:error.message,
        errorCode:error.code,
        uuid:visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setIsLoading(false);
    return response;
  };


  const formDataIngestion = (
    visitor_parameters,
    data,
    message_type,
    page_name,
    query_string,
    visitor_data
  ) => {
    setIsLoading(true);
    const response = Api.post("v1/data-ingestion-pipeline", {
      visitor_parameters,
      data,
      message_type,
      page_name,
      query_string,
      visitor_data,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'Data Ingestion : '+message_type,
        errorReason:error.message,
        errorCode:error.code,
        uuid:visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setIsLoading(false);
    return response;
  };
  return { saveDataIngestion, formDataIngestion, isLoading };
};

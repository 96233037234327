import React, { useState } from "react";
import RadioButton2 from "../../../UI/RadioButton2";
import Modal from "react-bootstrap/Modal";
import LogoBasedOnDomain from "../../Common/LogoBasedOnDomain";

const CarFinance = ({ slideChange, className, setLoader1, validation }) => {
  const [modalShow, setModalShow] = useState(false);
  const optionSelected = async (e) => {
    window.scrollTo(0, 0);
    if (e.target.value === "1") {
      setLoader1("show");
      const timer = setTimeout(() => {
        setLoader1("hide");
        slideChange(e);
      }, 1000);
    } else if (e.target.value === "2") {
      setModalShow(true);
    }
  };
  const closeModal = () => {
    setModalShow(false);
  };
  return (
    <>
      <div className={`step ${className}`} id="step_1">
        <h1 className="margin-5 d-block d-md-none text-center head_txt">
          Ever taken out a Car through Finance?
        </h1>
        <label className="h5 margin-15 text-color-blue d-block d-md-none sub_txt text-center">
          You could be owed
          <span>
            <strong>£1000's!</strong>
          </span>
        </label>
        <div className="cars pt-0 progressed_hide d-flex d-md-none justify-content-center">
          <img src="/assets/TGG_V1/img/keyshape.png" className="banner_img" />
        </div>
        <div
          className="form-right bounce-y top-up mb-0 d-block d-md-none mb-4"
          id="hideOnSlide2"
        >
          <div className="badge">
            <div className="content">
              <h6 className="margin-5 hideOnSlidebadge">
                Your PCP claim could be worth
                <span className="mb-0 ms-1 fs-3 text-claim-value">
                  <span id="i" className="">
                    £5,318
                  </span>
                  <span className="text-size-caveat">*</span>
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div className="form-left">
          <h1 className="margin-5 d-none d-md-block">
            Ever taken out a Car through Finance?
          </h1>
          <label className="h5 margin-15 text-color-blue d-none d-md-block">
            You could be owed{" "}
            <span>
              <strong>£1000's!</strong>
            </span>
          </label>
          <h3 className="margin-5 d-none d-md-block ">
            Your PCP claim could be worth
            <span className="mb-0 ms-1 fs-3 text-claim-value">
              <span id="i" className="">
                £5,318
              </span>
              <span className="text-size-caveat">*</span>
            </span>
          </h3>
          <div className="field padded">
            <label className="h5 margin-5">
              Have you had a car on finance before 2021?
            </label>
            <ul className="options two">
              <li>
                <RadioButton2
                  value="1"
                  name="carFinance"
                  id="had_car_finance_1"
                  className="custom-checkbox"
                  labelName="Yes"
                  labelClassName={`animation-green-flash-infinite`}
                  onClick={optionSelected}
                  validation={validation()}
                />
              </li>
              <li>
                <RadioButton2
                  value="2"
                  name="carFinance"
                  id="had_car_finance_2"
                  className="custom-checkbox"
                  labelName="No"
                  labelClassName=""
                  onClick={optionSelected}
                  validation={validation()}
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="cars progressed_hide d-none d-md-block">
          <div className="banner text-weight-600">
            <img src="/assets/TGG_V1/img/warning.png" /> £13 billion estimated
            to be paid out
          </div>
          <img src="/assets/TGG_V1/img/keyshape.png" className="banner_img" />
        </div>
      </div>

      <Modal
        className="modal-chk_answr"
        show={modalShow}
        onHide={closeModal}
       
      >
        <div className="modal-chk_answr1">
        <div> 
        <LogoBasedOnDomain style={{ width: 152, textAlign: 'center' }} />
        </div >
          <h2 className="mt-4">Please check your answers</h2>
          <p>
            Unfortunately, this means we are unable to pursue a claim against
            your lenders.
          </p>
          <p>If you would like to change your answer, please click back.</p>
          <button onClick={closeModal}>Back</button>
        </div>
      </Modal>
    </>
  );
};

export default CarFinance;

import { useState } from "react";
import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useRedirectProbability = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const probablityPage = async (uuid) => {
    const response = await Api.post("v1/probablity-check", {
      uuid
    }).catch(function (error) {
      const erroMessage = {
        uuid: uuid
      };
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { probablityPage };
};

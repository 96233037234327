import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import { getParameterByName } from "../../Utility/Helper";
import ReactPixel from "react-facebook-pixel";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import RedirectModule from "../../Utility/RedirectModule";

require("dotenv").config();
const FbPixel = () => {
  console.log("in fb ");
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  let current_domain = visitorParameters.data.existingdomain;
  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("split_name");
  const nextPage = query.get("next_page");
  const probabilityCheck = query.get("pcp");

  if (queryString != null) {
    if (queryString.indexOf("&" + field + "=") !== -1) {
      const pid = getParameterByName(field, queryString);
      console.log("fb pixel iffff");
      console.log("vistors parameters");
      console.log(visitorParameters);
      const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init(pid, advancedMatching, options);
      ReactPixel.pageView();
      ReactPixel.track("Lead");

      const redirect = RedirectModule({
        splitName: splitName,
        uuid: visitorParameters.visitor_parameters.uuid,
        pageAProbability: probabilityCheck,
      });
      console.log("redirect in fb", redirect);
      history.push(redirect);
    } else {
      console.log("else case");
    }
  }
  return <></>;
};

export default FbPixel;